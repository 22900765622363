
export enum ArabicEnum {
    newToSACO = 'هل أنت جديد في ساكو',
    createAccount = 'إنشاء حساب ',
    fullName = 'الاسم الكامل',
    fullNameRequired = 'الإسم الكامل ضروري. يجب ألا يتجاوز الاسم 50 حرفًا.',
    mobileNumber = 'رقم الهاتف المحمول مطلوب  ',
    invaliedEmail = 'عنوان البريد الإلكتروني غير صالح.',
    emailAddress = 'عنوان البريد الإلكتروني',
    requiredEmail = 'البريد الإلكتروني مطلوب',
    passwordLength = 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل.',
    setPassword = 'تعيين كلمة المرور',
    requiredPAssword = 'كلمة المرور مطلوبة',
    otpSent = 'للتحقق من رقمك، سنرسل لك رسالة نصية تحتوي على رمز مؤقت. قد تنطبق عليها رسوم الرسائل والبيانات.',
    verifyMobile = 'التحقق من رقم الهاتف المحمول',
    continuePOlicy = 'من خلال المتابعة ، أوافق على شروط الاستخدام و سياسة الخصوصية',
    authenticationFailed = 'فشل تسجيل الدخول. يرجى التحقق من بيانات الاعتماد الخاصة بك وحاول مرة أخرى.',

    continuePolicyStr1 = 'من خلال إنشاء حساب، فإنك توافق على  ',
    continuePolicyStr2 = 'شروط استخدام ساكو',
    continuePolicyStr3 = 'و',
    continuePolicyStr4 = 'إشعار الخصوصية',
    loginGeneralError = 'كان هناك مشكلة',
    loginInvalidEmailOrMobile = 'عنوان البريد الإلكتروني أو رقم الهاتف المحمول غير صالح',

    showPassword = 'إظهار كلمة المرور',
    confirmPasswordRequire = 'الرجاء تأكيد كلمة المرور',
    passwordRegexError = 'يجب أن تحتوي كلمة المرور على حرف خاص وحرف كبير على الأقل.',
    passwordMatch = 'كلمة المرور وتأكيد كلمة المرور غير متطابقين',
    already_have_account = "هل لديك حساب بالفعل؟",
    signIn = 'تسجيل الدخول',
    nameRegexError = "يجب أن يحتوي الاسم فقط على الأحرف الأبجدية.",
    phoneRegexError = "يجب أن يكون رقم الهاتف صالحًا.",
    phoneEmailRegexError = "يجب أن يكون رقم الهاتف المحمول أو البريد الإلكتروني صالحًا",
    emailRegexError = 'أدخل عنوان بريد إلكتروني صحيح.',
    validEmailPhone = "الرجاء إدخال رقم هاتفك المحمول أو عنوان بريدك الإلكتروني.",
    emailMobileRequired = "البريد الإلكتروني أو رقم الهاتف المحمول مطلوب",
    continue = "متابعة",
    sacoPolicy = "من خلال تسجيل الدخول إلى الحساب، فإنك توافق على شروط استخدام ساكو وإشعار الخصوصية.",

    sacoPolicyStr1 = " بتسجيل الدخول إلى الحساب، فإنك توافق على ساكو",
    sacoPolicyStr2 = "شروط الاستخدام",
    sacoPolicyStr3 = "و",
    sacoPolicyStr4 = "إشعار الخصوصية.",

    or = "أو",
    needHelp = "هل تحتاج إلى مساعدة؟",
    phoneEmailPlaceholder = 'البريد الإلكتروني أو الهاتف المحمول (5xxxx)',
    createSacoAccount = "أنشئ حسابك في ساكو",
    reEnterPassword = "أعد إدخال كلمة المرور",
    change = 'تغيير',
    forgotPassword = 'هل نسيت كلمة المرور؟',
    passwordPlaceholder = 'كلمة المرور',
    keepSignedIn = 'البقاء في وضع تسجيل الدخول',
    details = 'التفاصيل',
    signInWithCode = 'تسجيل الدخول باستخدام الكود',
    authenticationRequired = 'المصادقة مطلوبة',
    edit = 'تعديل',
    otpSend = "لقد قمنا بإرسال رمز التحقق مرة واحدة إلى رقم الجوال أعلاه. الرجاء إدخاله لإكمال التحقق",
    otpRequired = 'رمز التحقق مطلوب',
    resendOTP = 'إعادة إرسال رمز التحقق',
    signInWithPassword = 'تسجيل الدخول باستخدام كلمة المرور',
    enterOTP = ' أدخل رمزالتحقق لمرة واحدة ',
    passwordAssistance = 'مساعدة في كلمة المرور',
    emailOrPhoneNumberDetail = 'الرجاء إدخال عنوان البريد الإلكتروني أو رقم الهاتف المحمول المرتبط بحسابك في ساكو.',
    emailPhoneChanged = 'هل تغير عنوان بريدك الإلكتروني أو رقم هاتفك المحمول؟',
    noLongerUser = 'إذا لم تعد تستخدم عنوان البريد الإلكتروني المرتبط بحسابك في ساكو، يمكنك الاتصال',
    customerService = 'خدمة العملاء',
    accountAccessHelp = 'للحصول على مساعدة في استعادة الوصول إلى حسابك.',
    verificationRequired = 'التحقق مطلوب',
    continueVerification = "للمتابعة، أكمل خطوة التحقق هذه. لقد أرسلنا رمز التحقق الى",
    enterBelowVerification = 'الرجاء إدخاله أدناه لإكمال التحقق',
    invalidOTP = ' رمز التحقق غير صالح. الرجاء التحقق من الرمز والمحاولة مرة أخرى',
    needMoreHelp = 'أحتاج لمزيد من المساعدة',
    askPassword = "سنطلب هذه كلمة المرور كلما قمت بتسجيل الدخول.",
    securePassword = 'نصائح لكلمة المرور الآمنة:',
    atLeastCharacters = 'استخدم على الأقل 6 أحرف، مزيج من الأرقام والحروف هو الأفضل.',
    previousPassword = 'لا تستخدم نفس كلمة المرور التي استخدمتها معنا سابقًا.',
    dictionaryWords = 'لا تستخدم كلمات القاموس أو اسمك أو عنوان بريدك الإلكتروني أو رقم هاتفك المحمول أو أي معلومات شخصية أخرى يمكن الحصول عليها بسهولة.',
    samePassword = 'لا تستخدم نفس كلمة المرور لعدة حسابات عبر الإنترنت.',
    enterNewPassword = 'أدخل كلمة المرور الجديدة',
    reEnterNewPassword = 'أعد إدخال كلمة المرور الجديدة',
    conditionsOfUse = 'شروط الاستخدام',
    privacyPolicy = 'سياسة الخصوصية',
    help = 'المساعدة',
    home = 'الرئيسية',

    // navigation
    helloTxt = 'مرحبًا',
    hiText = 'أهلاً!',
    shopByCategory = 'التسوق حسب الفئة',
    helpSettings = 'المساعدة والإعدادات',
    yourAccount = 'حسابك',
    signOut = 'تسجيل الخروج',
    signOutLbl = 'خروج',

    //PLP page
    ofOver = 'أكثر من',
    resultsFor = 'نتائج لـ',
    resultsLabel = 'النتائج',
    for = 'لـ',
    sortBy = 'ترتيب حسب',
    seeAllResults = 'رؤية كل النتائج',
    availableSlot = 'فترة متاحة',
    compare = 'مقارنة',
    clearAllItems = 'مسح كل العناصر',
    Compareitems = 'مقارنة العناصر',
    close = 'إغلاق',
    wishlistUpdatedSuccessfully = 'تم تحديث قائمة الرغبات بنجاح',
    previous = 'سابق',
    next = 'التالي',

    //CLP Page
    featuredCategories = 'الفئات المميزة',
    topBrands = 'أفضل العلامات التجارية',


    // PDP page
    boughtPastmonth = 'كمية تم شراؤها في شهر',
    earn = 'اكسب',
    loyaltyPoints = 'نقاط الولاء',
    notifyMe = 'أخبرني عند انخفاض السعر',
    dismissAlert = 'تجاهل التنبيه',
    payMinimum = 'ادفع الحد الأدنى',
    noLateFees = 'الآن، والباقي على مدى الوقت - بدون رسوم تأخير، بدون فوائد.',
    months = 'شهر (24 شهرًا)',
    with = 'مع',
    emi = 'تقسيط',
    onYour = 'على حسابك',
    visa = 'فيزا',
    cards = 'بطاقات',
    allEmiPlans = 'جميع خطط التقسيط',
    payOnDelivery = 'الدفع عند التسليم',
    easyReplacement = 'استبدال سهل',
    assuredDelivery = 'توصيل مضمون',
    topHighlights = 'أبرز النقاط',
    freeDeliveryBy = 'توصيل مجاني بواسطة',
    deliveringTo = 'توصيل إلى',
    standardDelivery = 'التوصيل العادي',
    expressDelivery = 'توصيل سريع',
    pickupFromStore = 'اضغط واستلم',
    limitedStock =  'كمية قليلة متبقية',
    addtoCart = 'أضف إلى السلة',
    buyNow = 'اشتر الآن',
    addtoWishlist = 'أضف إلى قائمة الرغبات',
    removeFromWishlist = 'إزالة من قائمة الرغبات',
    movetoWishlist = 'الانتقال إلى قائمة الأماني',
    productDetails = 'تفاصيل المنتج',
    technicalDetails = 'تفاصيل تقنية:',
    additionalInformation = 'معلومات إضافية:',
    addedToCart = 'تمت الإضافة إلى عربة التسوق',
    images = 'الصور',
    videos = 'مقاطع الفيديو',
    customerReviews = 'آراء العملاء',
    notifyMePopUp = 'أعلمني',
    notifyTxt = 'تنبيه',
    sms = 'رسالة نصية',
    emailLabel = 'البريد الإلكتروني',
    donnotMissOut = "لا تفوت الفرصة!",
    limitedTimeOffer = "متاح الآن بسعر أقل. أسرع، عرض لفترة محدودة!",


    //Header
    deliverTo = 'التوصيل إلى',
    headerSearchPlaceholder = 'ابحث في ساكو',
    all = 'جميع الفئات',
    cart = 'عربة التسوق',    
    cartIconText ='السلة',
    hello = 'مرحبًا،',
    startHere = 'أبدأ هنا.',
    newCustomer = 'عميل جديد؟',
    choose_your_location = 'اختر موقع التسليم الخاص بك',
    add_your_location = 'أضف موقعك',
    delivery_text_info = 'قم بتسجيل الدخول لرؤية عنوانك',
    signIn_to_see_address = 'اختر موقع التسليم الخاص بك من الخيارات أدناه لتخصيص رحلة التسوق الخاصة بك',
    select_your_city = 'اختر مدينتك',
    back_to_top = 'العودة إلى الأعلى',
    main_menu = 'القائمة الرئيسية',
    arabic = 'عربي',
    english = 'إنجليزي',
    langDisplayText = 'English',
    learn_more = 'تعرف أكثر',
    you_are_shopping_on = 'أنت تتسوق على موقع Saco.sa',
    change_country = 'تغيير البلد/المنطقة',
    logout = 'تسجيل خروج',
    locationAccessBlockedErrorMessage = 'السماح بالوصول إلى الموقع لإضافة عنوان جديد. يمكنك إدارة هذا الوصول في أي وقت من خلال إعدادات أذونات جهازك.',
    locationUpdatedSuccess = 'تم تحديث الموقع بنجاح.',
    locationUpdatedError = 'غير قادر على تحديث الموقع يرجى المحاولة لاحقا.',
    unknownLocation = "ساكو لم يصل بعد إلى المدينة الحالية. يرجى اختيار موقع آخر لاستكشاف تجربة التسوق لدينا",
    unknownLocationMapDrag = "لم نتمكن من العثور على المطابقة لتلك الموقع. جرب تحديد عنوان محدد أو نقطة معلم قريبة.",

    // Cart Page
    deselectAllItems = 'إلغاء تحديد جميع العناصر',
    update = 'تحديث',
    remove = 'إزالة',
    removeAll = 'إزالة الكل',
    share = 'مشاركة',
    price = 'السعر',
    saveCurrentCart = 'حفظ عربة التسوق الحالية',
    proceedToBuy = 'المتابعة للشراء',
    pairWithYourCart = 'ربط مع عربة التسوق الخاصة بك',
    priceDetails = 'تفاصيل السعر',
    items = 'العناصر',
    yourItems = 'عناصرك',
    yourOrderQualifiesFor = 'تأهل طلبك لـ',
    freeShipping = 'الشحن مجاني',
    subTotal = 'المجموع الفرعي',
    savedForLater = 'حفظ لاحقاً',
    saveForLater = 'احفظ لاستخدامه لاحقًا',
    buyItAgain = 'شراء مرة أخرى',
    saveCartNameRequired = 'اسم عربة التسوق المحفوظة مطلوب',
    qty = 'الكمية',
    total = 'المجموع',
    proceedToCart = 'المتابعة الى عربة التسوق',
    specification = 'المواصفات',
    colors = 'الألوان',
    removeFromCart = 'إزالة من عربة التسوق',
    removeCartParagraph = ' هل أنت متأكد أنك تريد إزالة جميع العناصر من عربة التسوق',
    saveChanges = 'حفظ التغييرات',
    saveCartParagraph = 'سيتم نقل عربة التسوق الخاصة بك إلى قائمة العربات المحفوظة',
    name = 'الاسم',
    saveCartDescription = 'حفظ عربة التسوق',
    saveCart = 'حفظ السلة',
    cancel = 'إلغاء',
    save = 'حفظ',
    removeCartLineItemParagraph = 'هل أنت متأكد أنك تريد إزالة هذه العناصر من عربة التسوق',

    //SRP
    results = 'نتائج',
    search_info_text = 'تحقق من كل صفحة منتج لمعرفة خيارات الشراء الأخرى. قد يختلف السعر والتفاصيل الأخرى حسب حجم المنتج ولونه.',
    best_selling = 'أفضل مبيعات',
    related_searches = 'عمليات البحث ذات الصلة',
    no_results = 'لم يتم العثور على نتائج',
    try_checking_results = 'حاول التدقيق الإملائي أو استخدم مصطلحات أكثر عمومية',
    show_more = 'اظهر الكل',
    show_less = 'اظهر أقل',

    //SACO Club
    joinNow = 'انضم الآن',
    readContent = 'لقد قرأت ووافقت على',
    and = 'و',
    termsOfUse = 'شروط الاستخدام',
    howItWorks = 'كيف يعمل',
    membershipLevels = 'مستويات العضوية',
    sacoClub = 'عضوية ساكو',
    joinSacoClub = 'انضم لنادي ساكو',
    optIn = 'تم قبول اختيار المستخدم',

    //National Address
    fetchFromMap = 'استرجاع موقعك من الخريطة',
    selectFromMap = 'اختر من الخريطة',
    getAddress = 'الحصول على العنوان',
    enterShortAddress = 'أدخل العنوان القصير (حسب العنوان الوطني)',
    invalidShortAddress = 'الرجاء إدخال عنوان قصير صالح',
    enterManually = 'أدخل العنوان يدويًا',
    confirmLocation = 'تأكيد الموقع',
    addThisAddress = 'إضافة هذا العنوان',
    join = 'انضم',
    redeem = 'استبدال',
    loyaltyCardRegistration = 'تسجيل بطاقة الولاء',
    locationMapError1 = 'عذرًا، لكننا غير قادرين على تقديم الخدمة في موقعك في الوقت الحالي!',
    locationMapError2 = 'لم نتمكن من العثور على تطابق لهذا الموقع. حاول الإشارة إلى عنوان محدد أو معلم قريب',

    // account order
    orders = 'الطلبات',
    buyAgain = 'اشترِ مرة أخرى',
    subscribeSave = 'اشترك ووفّر',
    notYetshipped = 'لم يتم الشحن بعد',
    cancelledOrders = 'الطلبات الملغاة',
    ordersReturns = 'الطلبات والمرتجعات',
    wishlist = 'المفضلة',
    loginSecurity = 'تسجيل الدخول والأمان',
    addressess = 'العناوين',
    supportTickets = 'تذاكر الدعم',
    loyaltyCard = 'بطاقة الولاء',
    digitalWallet = 'المحفظة الرقمية',
    sacoClubDashboard = 'لوحة تحكم عضوية ساكو',
    shipTo = 'الشحن إلى',
    order = 'الطلب #',
    color = 'اللون:',
    firstName = 'الاسم الأول',
    lastName = 'الاسم الأخير',
    changeYourName = 'غيّر اسمك',
    firstNameRequired = 'الاسم الأول مطلوب. يجب ألا يتجاوز الاسم 50 حرفًا.',
    lastNameRequired = 'الاسم الأخير مطلوب. يجب ألا يتجاوز الاسم 50 حرفًا.',
    dateOfBirth = 'تاريخ الميلاد',
    dateOfBirthFormat = 'yyyy-mm-dd',
    msgDateOfBirth = 'التاريخ الميلادي مطلوب',
    dateOfBirthRegexError = 'الرجاء إدخال تاريخ صحيح بالتنسيق YYYY-MM-DD',
    notificationName = 'تم تحديث الاسم بنجاح!',
    notificationDob = 'تم تحديث تاريخ الميلاد بنجاح!',
    notificationPhone = 'تم تحديث رقم الهاتف بنجاح!',
    notificationEmail = 'تم تحديث عنوان البريد الإلكتروني الجديد بنجاح!',
    notificationPassword = 'تم تحديث كلمة المرور بنجاح!',
    addDateOfBirth = 'إضافة تاريخ الميلاد',
    add = 'إضافة',
    howWouldyouRate = 'كيف تقيمه؟',
    writeReview = 'كتابة مراجعة',
    addAddress = 'إضافة عنوان',
    default = 'افتراضي:',
    addDeliveryinstr = 'إضافة تعليمات التسليم',
    setAsDefault = 'تعيين كافتراضي',
    yourSavedAddressess = 'عناوينك المحفوظة',
    backSavedAddressess = 'العودة إلى العناوين المحفوظة',
    addNewAddress = 'إضافة عنوان جديد',
    mobileMatch = 'الهاتف المحمول وتأكيد الهاتف المحمول غير متطابقين',
    editAddress = 'تحرير العنوان',
    saveAddress = 'حفظ العنوان',
    yourName = 'اسمك',
    addMobileNumber = 'رقم الهاتف المحمول*',
    confirmMobileNumber = 'تأكيد رقم الهاتف المحمول*',
    address = 'العنوان',
    defaultAddress = 'اجعل هذا عنواني الافتراضي',
    myAccount = 'حسابي',
    country = 'الدولة',
    email = 'البريد الإلكتروني',
    primaryPhonenumber = 'رقم الهاتف الرئيسي',
    password = 'كلمة المرور',
    changeMobilenumber = 'تغيير رقم الهاتف المحمول',
    oldMobilenumber = 'رقم الهاتف المحمول القديم:',
    isMobileNumber = 'رقم الهاتف المحمول',
    verifyMobilenumber = 'التحقق من رقم الهاتف المحمول',
    completeVerification = 'لإكمال هذا التحقق، قم بإكمال هذه الخطوة. لقد قمنا بإرسال رمزالتحقق الى رقم الهاتف المحمول',
    belowCompleteVerification = '. يرجى إدخاله أدناه لإكمال التحقق.',
    currentMobilenumber = 'رقم الهاتف المحمول الحالي:',
    changeEmailAddress = 'تغيير عنوان البريد الإلكتروني',
    oldEmailAddress = 'البريد الإلكتروني القديم:',
    verifyEmailAddress = 'التحقق من عنوان البريد الإلكتروني',
    toContinue = 'للمتابعة، أكمل هذه الخطوة للتحقق. لقد أرسلنا رمز التحقق الى',
    pleaseEnter = '. يرجى إدخاله أدناه لإكمال التحقق.',
    useTheFormTxt = 'استخدم النموذج أدناه لتغيير عنوان البريد الإلكتروني لحسابك في ساكو. استخدم البريد الإلكتروني الجديد في المرة القادمة التي تقوم فيها بتسجيل الدخول أو تقديم طلب.',
    alreadyRegisterd = 'المستخدم موجود بالفعل',
    changePassword = 'تغيير كلمة المرور',
    currentPassword = 'كلمة المرور الحالية',
    completeEmailVerification = 'للمتابعة، أكمل هذه الخطوة للتحقق. لقد أرسلنا رمز التحقق الى',
    completeEmailVerificationLast = '. الرجاء إدخاله أدناه لإكمال عملية التحقق.',
    currentEmailAddress = 'عنوان البريد الإلكتروني الحالي',
    removedAddress = 'تمت إزالة العنوان بنجاح.',

    //Saved Carts
    savedCarts = 'عربة التسوق المحفوظة',
    id = 'المعرف',
    dateSaved = 'تاريخ الحفظ',
    description = 'الوصف',
    restore = 'استعادة',
    action = 'الإجراء',
    inStock = 'متوفر',
    outofStock = 'غير متوفر',

    //Wishlist
    yourWishlist = 'قائمة أمانيك',
    moveToCart = 'الانتقال إلى عربة التسوق',

    //locationPopups
    businessHours = 'ساعات العمل',
    fiewItems = 'عدد قليل فقط من العناصر في المخزون',
    searchStores = 'متاجر البحث',
    pickYourDeliveryText = 'اختر موقع التسليم الخاص بك من الخيارات أدناه لتخصيص رحلة التسوق الخاصة بك',
    moreOptions = 'المزيد من الخيارات لإضافة عنوان',
    confirm = 'تاكيد',
    reviewOrder = 'مراجعة الطلب',

    //Digital Wallet
    availableBalance = 'الرصيد المتاح',
    clicktoEnlargeImage = 'انقر لتكبير الصورة',
    nextRewards = 'نقطة حتى مكافأتك القادمة',
    points = 'النقاط',
    pointsWillExpire = 'النقاط ستنتهي صلاحيتها خلال الـ 30 يوم القادمة.',
    status = 'الحالة',
    pointsBalance = 'رصيد النقاط',
    cashbackBalance = 'رصيد الكاش باك',
    PointsOnHold = 'النقاط المعلقة',
    youStillNeed = 'ما تزال بحاجة إليه',
    purchaseToReach = 'المشتريات للوصول إلى',
    tier = 'المستوى',
    benefits = 'الفوائد',
    activity = 'النشاط',
    date = 'التاريخ',
    OrderId = 'رقم الطلب',
    expiry = 'انتهاء الصلاحية',
    noDataAvailable = 'لا تتوفر بيانات نشاط',

    continueAsGuest = 'المتابعة كضيف',
    confirmEmail = 'تأكيد البريد الإلكتروني',
    otherIssues = 'قضايا أخرى مع تسجيل الدخول',
    cartItemUpdatedSuccessfully = 'تم تحديث عنصر عربة التسوق بنجاح',
    allOrders = 'جميع الطلبيات',
    removeAllCartItems = "إزالة جميع العناصر من عربة التسوق ",
    youWillEarn = 'سوف تكسب',
    vatText = 'جميع الأسعار شاملة لضريبة القيمة المضافة',
    noSavedData = 'لا توجد بيانات محفوظة متاحة',

    requestSupport = 'طلب الدعم',
    allTickets = 'جميع التذاكر',
    openTicket = 'مفتوح',
    closeTicket = 'تم توفير الحل / مغلق',
    addNewTicket = 'إضافة تذكرة دعم جديدة',
    subject = 'الموضوع',
    writeYourMessages = 'اكتب رسالتك هنا',
    selectFile = 'اختر ملف',
    selectCategory = 'اختر الفئة',
    submitBtn = 'إرسال',
    chooseFile = 'اختر ملف',
    fileFormat = 'أنواع الملفات المدعومة: ',
    assignTo = 'تعيين لـ',
    subjectErrorTitle = 'يرجى إدخال الموضوع',
    messageErrorMsg = 'يرجى إدخال رسالة صالحة.',
    fileFormatErrorMsg = 'يرجى تحديد تنسيق ملف صالح',
    categoryErrorMsg = 'يرجى تحديد فئة',
    backToTicket = 'العودة إلى تذكرة الدعم',
    messages = 'رسائل',
    addNewMessage = 'إضافة رسالة جديدة',
    addMessage = 'إضافة رسالة',
    giftCardBalance = 'رصيد البطاقة الهدية',
    enterGiftcardnumber = 'أدخل رقم البطاقة الهدية',
    enterPin = 'أدخل الرمز السري',
    checkBalance = 'تحقق من الرصيد',
    giftcardnumberMsg = 'مطلوب رقم البطاقة الهدية',
    cardPinMsg = 'مطلوب رمز البطاقة',
    balanceOf = 'هناك رصيد بقيمة',
    remainingSar = 'SAR المتبقية على هذه البطاقة الهدية',
    dismissBtn = 'رفض',
    statusErrorMsg = 'يرجى تحديد الحالة',
    charactersRemaining = 'الأحرف المتبقية',

    assured = 'ضمان',
    quality = 'جودة',
    handpicked = 'مختار بعناية',
    easy = 'سهل',
    return = 'إرجاع',
    you = 'أنت:',
    seeMore = 'عرض المزيد',
    seeLess = 'رؤية أقل',
    tooltipContentLeft = 'أنت مستحق لـ',
    tooltipContentRight = 'نقطة لأخر عملية شراء وسيتم تحويلها إلى حسابك بعد ١٤ يوم.',
    homeDelivery = 'توصيل للمنزل',
    storePickup = 'استلام من المتجر',
    career = 'المهنة',
    applyForJob = 'تقديم طلب لوظيفة',
    fullname = 'الاسم الكامل*',
    mobilenumber = 'رقم الهاتف المحمول*',
    nationality = 'الجنسية*',
    homeNumber = 'رقم المنزل*',
    countryResidence = 'بلد الإقامة*',
    Email = 'البريد الإلكتروني*',
    city = 'المدينة*',
    uploadCV = 'تحميل السيرة الذاتية*',
    errorFullName = 'يرجى تقديم الاسم الكامل الخاص بك',
    errorMobileNumber = 'يرجى تقديم رقم هاتفك المحمول',
    errorNationality = 'يرجى تقديم جنسيتك',
    errorCountryResidence = 'يرجى تقديم بلد الإقامة الخاص بك',
    errorEmail = 'يرجى تقديم بريدك الإلكتروني',
    errorCity = 'يرجى تقديم مدينتك',
    errorUploadCV = 'يرجى تقديم السيرة الذاتية الخاصة بك',
    removeProductMessage = 'تمت إزالة المنتج من قائمة الرغبات',
    lowStock = 'المخزون منخفض',
    estimatedDeliveryDate = 'التوصيل المتوقع',
    deliveryDate = 'تاريخ التسليم',
    buyAllTogether = 'اشتر الكل معًا مقابل',
    qtyLbl = 'الكمية',
    getDirection = 'احصل على الاتجاه',
    primaryContactNumber = 'الرقم الرئيسي للاتصال',
    appartment = 'رقم الشقة',
    line1 = 'اسم الشارع',
    line2 = 'رقم الشارع',
    postalCode = 'الرمز البريدي',
    districtId = 'المنطقة',
    nameRequired = 'الرجاء إدخال الاسم الأول',
    appartmentRequired = 'الرجاء إدخال رقم الشقة',
    line1Required = 'الرجاء إدخال اسم الشارع',
    line2Required = 'الرجاء إدخال رقم الشارع',
    townIdRequired = 'الرجاء اختيار المدينة',
    districtIdRequired = 'الرجاء إدخال المنطقة',
    postalCodeRequired = 'الرجاء إدخال الرمز البريدي',
    postalCodeInvalid = 'الرجاء إدخال رمز بريدي صالح',
    postalCodeTooLong = 'الرمز البريدي طويل جدًا',
    saveAndProceed = 'حفظ ومتابعة',
    saveTheChanges = 'حفظ التغييرات',

    cityDistError = 'عذرًا، ولكن يبدو أن خدمتنا غير متوفرة حاليًا في موقعك',
    addressNotAvailable = 'لعنوان غير موجود في سجلات العناوين الوطنية. الرجاء إدخال عنوان صالح',
    enterSurfaceArea = 'أدخل المساحة / المنطقة',
    quantity = ' الكمية ',
    errorMessage = 'غير قابل للتسليم إلى العنوان المحدد، يرجى تغيير العنوان أو إزالة العناصر لإتمام الطلب',
    deliveryOption = 'خيار التوصيل',
    noAddressContent = 'لا تتوفر عناوين محفوظة لحسابك',
    addNewAddressLabel = 'إضافة عنوان جديد',
    useThisAddress = 'استخدام هذا العنوان',
    chooseDeliveryAddress = 'اختر عنوان التسليم',
    chooseBillingAddress = 'اختر عنوان الفاتورة',
    paymentMethod = 'اختر طريقة الدفع',
    morePaymentMethods = 'اختر واحدة أو أكثر من طرق الدفع',
    selectRedeemPoints = 'اختر نقاط الاسترداد',
    couponCodeLabel = 'إضافة كود الكوبون أو كود الترويج',
    couponCodeIsrequired = 'كود الكوبون مطلوب',
    apply = 'تطبيق',
    youHaveAdded = 'لقد أضفت',
    SARCurrency = 'ر.س',
    fromYourCouponContaining = 'من الكوبون الخاص بك، الذي يحتوي على',
    storeCreditsLabel = 'استخدم رصيد المتجر لهذا الطلب',
    noLoyaltyPoints = 'لن تحصل على نقاط الولاء للمبلغ المدفوع باستخدام هذه الطريقة',
    fromYourStoreCredit = 'من رصيد متجرك',
    loyaltyPointsOrder = 'استخدم نقاط الولاء لهذا الطلب',
    availableLoyaltyPoints = 'نقاط الولاء المتاحة',
    worth = 'بقيمة',
    containing = 'التي تحتوي على',
    loyaltyAmountIsRequired = 'المبلغ المطلوب من نقاط الولاء',
    placeHolderRedeem = 'يرجى إدخال نقاط الائتمان التي سيتم استردادها',
    YouHaveApplied = 'لقد قمت بالتطبيق',
    loyaltyPointsEquivalentTo = 'نقاط الولاء معادلة ل',
    UseGiftcardforThisOrder = 'استخدم بطاقة الهدايا لهذا الطلب',
    giftCardPinIsRequired = 'رقم بطاقة الهدية مطلوب',
    redeemGiftcardNumber = 'لقد استردت رقم بطاقة الهدايا الخاصة بك',
    PayWithQitaf = 'ادفع باستخدام قطاف',
    PayWithMeritcard = 'ادفع باستخدام بطاقة ميريت',
    MeritCardNumberIsRequired = 'رقم بطاقة ميريت مطلوب',
    willbeDeductedFromYourcard = 'سيتم خصمها من بطاقتك',
    AddAnotherMeritcard = 'أضف بطاقة ميريت أخرى',
    CreditDebitCard = 'بطاقة الائتمان / الخصم',
    PayWithTamara = 'ادفع باستخدام تمارا',
    PayWithTabby = 'ادفع باستخدام تابي',
    tabbyFees = 'ادفع في 4. بدون فوائد، بدون رسوم.',
    SelectedPaymentMethod = 'طريقة الدفع المختارة',
    ReviewItemsAndShipping = 'مراجعة العناصر والشحن',
    UpdateAndProceed = 'تحديث ومتابعة',
    UseThisPaymentMethod = 'استخدم هذه الطريقة للدفع',
    PlaceOrder = 'إتمام الطلب',
    Pay = 'دفع',
    OrderSummary = 'ملخص الطلب',
    Coupons = 'الكوبونات',
    Discount = 'التخفيض',
    MeritCardDiscount = 'تخفيض بطاقة ميريت',
    StoreCredit = 'رصيد المتجر',
    LoyaltyDiscount = 'تخفيض الولاء',
    GiftCardDiscount = 'تخفيض بطاقة الهدية',
    QTAFDiscount = 'تخفيض قطاف،',
    EstimatedTax = 'الضريبة المقدرة',
    TotalAmount = 'المبلغ الإجمالي',
    RemainingAmount = 'المبلغ المتبقي',
    ToPay = 'للدفع',
    DeliveryAddressUpdatedsuccessfully = 'تم تحديث عنوان التسليم بنجاح',
    DeliveryCharges = 'رسوم التوصيل',
    ApplyCouponhere = 'قم بتطبيق الكوبون هنا',
    PaymentOptions = 'خيارات الدفع',
    GiftCardPin = 'رقم سري للبطاقة الهدية',
    fromyourQitafBalance = 'من رصيد قطاف الخاص بك',
    PhoneNumberIsRequired = 'رقم الهاتف المحمول مطلوب',
    PhoneNumberIsNotValid = 'رقم الهاتف غير صالح',
    PhoneNumbershouldbe9digits = 'يجب أن يتكون رقم الهاتف من 9 أرقام',
    OneTimePassword = 'كلمة مرور مرة واحدة',
    Entertheamountyouneedtoredeem = 'أدخل المبلغ الذي ترغب في استرداده',
    AmountshouldbeNumber = 'يجب أن يكون المبلغ رقمًا',
    Youhaveredeemed = 'لقد استرددت',
    termsText = 'من خلال تقديم الطلب، أؤكد أنني قرأت وأوافق على',
    confirmPolicy = 'الرجاء تأكيد بيان السياسة أولاً لتقديم الطلب.',
    termsConditions = 'الشروط والأحكام',
    phoneNumber = 'رقم الهاتف',
    placedIn = 'موضوع في',
    lastThreeMonths = "يبدو أنك لم تقم بتقديم طلب خلال الثلاثة أشهر الماضية",
    continueShopping = 'مواصلة التسوق',
    orderPlaced = 'تم تقديم الطلب',
    ordersHaveShipped = 'هل تبحث عن طلب؟ تم شحن جميع طلباتك.',
    viewAllOrders = 'عرض جميع الطلبات',
    completeOverview = 'تظهر طلباتك خلال الستة أشهر الماضية لم يتم إلغاء أي منها. قم بالوصول إلى سجل الطلبات للحصول على نظرة عامة كاملة.',
    backToOrders = 'العودة إلى الطلبات',
    rateTheProduct = 'كيف تقيم المنتج؟',
    youDispatched = 'يمكنك إلغاء العنصر قبل إرساله',
    shippingAddress = 'عنوان الشحن',
    orderSummary = 'ملخص الطلب',
    viewBreakup = 'عرض الانفصال',
    addReview = 'أضف مراجعة',
    howWouldYouRate = 'كيف تقيمه؟',
    sharePhoto = 'شارك صورة',
    shoppersFindImages = 'يعتبر المتسوقون أن الصور أكثر فائدة من النص وحده.',
    chooseCorrectFileFormat = 'اختر تنسيق الملف الصحيح',
    titleYourReview = 'عنون مراجعتك',
    mostImportantToKnow = "ما الأكثر أهمية لمعرفته؟",
    writeYourReview = 'اكتب مراجعتك',
    importantToKnow = "ما الأكثر أهمية لمعرفته؟",
    dislike = 'ما الذي أعجبك أو لم يعجبك؟ ما الذي استخدمت هذا المنتج له؟',
    reviewAdded = 'تمت إضافة المراجعة',
    problem = 'مشكلة',
    dateCreated = 'تاريخ الإنشاء',
    dateUpdated = 'تاريخ التحديث',
    requiredFields = 'الحقول المطلوبة',
    cardNumber = 'رقم البطاقة *',
    emailId = 'البريد الإلكتروني *',
    TotalTax = 'الضريبة الإجمالية',
    EarnedCoupon = 'الكوبون المكتسب',
    yourOTPwillExpire = 'سينتهي رمز التحقق الخاص بك في ',
    completeYourAuthentication = 'ثوانٍ. يرجى استخدامه بسرعة لإكمال المصادقة الخاصة بك.',
    unableToAutheticate = 'غير قادر على مصادقة. يرجى المحاولة لاحقا.',
    applicationError = 'حدث خطأ، يرجى المحاولة لاحقاً',
    subCategory = 'الفئة الفرعية',
    ShowmoreBrands = 'عرض المزيد من العلامات التجارية',
    ShowlessBrands = 'عرض أقل من العلامات التجارية',
    ShowmoreCategories = 'عرض المزيد من الفئات',
    ShowlessCategories = 'عرض أقل من الفئات',
    ProductAddedtoWishlist = 'تمت إضافة المنتج إلى قائمة الرغبات',
    ProductRemovedfromWishlist = 'تمت إزالة المنتج من قائمة الرغبات',
    cardNumberRequired = 'رقم البطاقة مطلوب',
    cardNumberInvalid = 'رقم البطاقة غير صالح',
    nameOnCard = 'الاسم على البطاقة',
    nameIsRequired = 'الاسم مطلوب',
    expirationDate = 'تاريخ الانتهاء',
    expiryMonthRequired = 'شهر الانتهاء مطلوب',
    CVVisRequired = 'رمز CVV مطلوب',
    submitCard = 'إرسال البطاقة',
    splitYourBill = 'قسّم فاتورتك بدون دفعة أولى وادفع',
    over = 'أكثر من',
    payments = 'دفعات',
    changeCard = 'تغيير البطاقة',
    yourCardWillCharged = 'سيتم خصم مبلغ من بطاقتك',
    moreTitle = 'المنتجات ذات الصلة بهذا العنصر',
    writeDeliveryReview = 'كتابة تقييم التوصيل',
    writeProductReview = 'كتابة تقييم المنتج',
    writeDeliveryReviewText = 'هل ترغب في تقييم تجربة التوصيل؟',
    ScoreReviewText = 'تجربة التوصيل',
    writeProductReviewText = 'هل ترغب في تقييم المنتج؟',
    Relevance = 'الصلة',
    accountCreation = 'تم إنشاء الحساب بنجاح!',
    updateProfile = 'تحديث الملف الشخصي',
    gender = 'الجنس',
    notAdded = 'لم يتم إضافته',
    profileInformation = 'معلومات الملف الشخصي',
    fieldsLeft = 'الحقول المتبقية',
    completeYourProfile = 'أكمل ملفك الشخصي لتحصل على 500 نقطة ولاء',
    earned = 'حصل عليها!',
    completeYourProfileMsg = 'أكمل ملفك الشخصي',
    dateofBirth = 'تاريخ الميلاد',
    national = 'الجنسية',
    cityOfResidence = 'مدينة الإقامة',
    maritalStatus = 'الحالة الاجتماعية',
    anniversaryDate = 'تاريخ الذكرى',
    occasionsYouCelebrate = 'المناسبات التي تحتفل بها',
    incomeRange = 'نطاق الدخل',
    doItLater = 'اعمل ذلك لاحقًا',
    saveDetails = 'حفظ التفاصيل',
    congratulationsCompleting = 'تهانينا على إكمال ملفك الشخصي!',
    youEarned = "لقد كسبت",
    readyToRedeem = 'جاهز للاسترداد في الشراء القادم!',
    enjoyShopping = 'استمتع بالتسوق',
    preferredChannel = ' القناة المفضلة للاتصال',
    loyaltyPointsError = " يمكنك فقط استبدال نقاط الولاء المتاحة",
    shareyourSiteExperince = "شكرا لتسوقكم معنا",
    DeliveryBy = "التوصيل بواسطة",
    Order = 'طلب',
    personalInformation = 'معلومات شخصية',
    shareDeliveryExperience = 'مسرورين أنكم إستلمتم طلبكم',
    yourThoughts = 'رأيكم يهمنا، الرجاء تقييم تجربة الشراء!',
    yourDeliveryThoughts = 'رأيكم يهمنا، الرجاء تقييم خدمة تسليم الطلب!',
    whatsWrong = 'يرجى إخبارنا بما حدث من خطأ؟',
    Gold = 'الذهبية',
    Silver = 'الفضية',
    Bronze = 'البرونزية',
    newArrival = 'وصلت حديثًا',
    topRated = 'الأعلى تقييمًا',
    displayRecentPurchaseCount = 'عدد المشتريات الأخيرة',
    openNow = 'مفتوح الآن',
    numberOfStores = 'عدد المتاجر',
    updated = 'مُحدّث',
    getOTP = 'احصل على رمز التحقق',
    enterAmount = 'أدخل المبلغ',
    burn = 'مستردة',
    unlock = 'فتح',
    simplyUpdating = 'من خلال تحديث ملفك الشخصي',
    clubMember = 'أنت الآن عضو في ساكو',
    addCards = 'إضافة بطاقة الائتمان أو الخصم',
    newCardHeader = 'تقبل ساكو جميع بطاقات الائتمان والخصم',
    newCardPolicy = 'يرجى التأكد من أن بطاقتك يمكن استخدامها للمعاملات عبر الإنترنت',
    knowMore = 'معرفة المزيد',
    saveCardInfo = 'حفظ تفاصيل البطاقة للدفع السريع',
    recipientFullname = "اسم المستلم",
    recipientEmail = "البريد الإلكتروني للمستلم",
    emailRequired = 'البريد الإلكتروني مطلوب',
    validEmailAddress = 'يرجى إدخال عنوان بريد إلكتروني صحيح',
    numberRequired = 'رقم الهاتف مطلوب',
    numberNotValid = 'رقم الهاتف غير صحيح',
    numberMustDigits = 'يجب أن يتكون رقم الهاتف من 9 أرقام على الأقل',
    payWithSTC = 'ادفع باستخدام STC',
    paymentFailed = 'عملية الدفع فشلت!',
    paymentFailedSub1 = 'لا تقلق، لن نتركك',
    paymentFailedSub2 = 'تذهب ويديك فارغة',
    retryPayment = 'محاولة دفع',
    stockUnavailable = 'المخزون غير متوفر',
    chooseTheAmount = 'يرجى اختيار المبلغ الذي ترغب في تقديمه',
    whenYouComplete = 'احصل على 500 نقطة ولاء عند اكمال ملفك الشخصي',
    duplicateMobile = 'رقم الجوال موجود بالفعل. يرجى المحاولة باستخدام رقم آخر.',
    duplicateEmail = 'عنوان البريد الإلكتروني موجود بالفعل. يرجى المحاولة باستخدام عنوان بريد إلكتروني آخر.',
    free = "مجانًا",
    gotoHomePage = 'الانتقال إلى الصفحة الرئيسية',
    pageNotFound = 'الصفحة التي تبحث عنها غير موجودة',
    pageNotFoundReasonHead = 'أسباب محتملة',
    pageNotFoundReason1 = 'قد تم إدخال العنوان بشكل غير صحيح',
    pageNotFoundReason2 = 'قد يكون الرابط معطل أو قديمًا',
    onlyforclickandCollect = 'متاح فقط للضغط والاستلام',
    IDtext = 'رقم المنتج',
    modelNo = 'الطراز',
    amazing = 'رائع',
    veryGood = 'جيد جدًا',
    ok = 'مقبول',
    poor = 'سيء',
    veryPoor = 'سيء جدًا',
    siteExperienceButtonText = 'شارك تجربتك في التسوق',
    siteExperienceText = 'شكراً لتسوقك معنا. أخبرنا عن تجربتك في التسوق وساعدنا في التحسين!',
    shippingTo = 'الشحن إلى',
    billingAddressUpdatedsuccessfully = 'تم تحديث عنوان الفواتير بنجاح',
    createNewAccount = "من خلال إنشاء حساب ، فإنك توافق على شروط استخدام ساكو وإشعار الخصوصية.",
    facebook = 'فيسبوك',
    twitter = 'تويتر',
    linkedIn = 'لينكد إن',
    whatsApp = 'واتساب',
    KeywordSuggestion = 'اقتراح الكلمات المفتاحية',
    TrendingSearches = 'البحث الشائع',
    PopularProducts = 'المنتجات الرائجة',
    changeLanguage = 'تغيير اللغة',
    allText = 'الكل',
    ShoptheBrand = 'تسوق العلامة التجارية',
    //Last updated 21st - below need to be updated in excel 
    FromtheManufacturer = 'من الشركة المصنعة',
    reviewSuccess = 'تم إرسال تقييمك بنجاح',
    easyReturn = 'إرجاع سهل',
    easyCancellation = 'إلغاء سهل',
    whyReturning = 'لماذا تقوم بالإرجاع؟',
    selectQuantity = 'اختر الكمية',
    howMakeRight = 'كيف يمكننا تصحيح الوضع؟',
    refundDigitalWallet = 'استرداد إلى المحفظة الرقمية',
    //initiatedImmediately = 'سيتم بدء عملية الاسترداد فورًا بمجرد استلام المنتج.',
    refundInitiatedImmediately = 'سيتم بدء عملية الاسترداد فورًا',
    //chooseDigitalWallet = 'لماذا تختار المحفظة الرقمية؟',
    //agreeToReturn = 'أوافق على إعادة جميع العناصر في حالتها الأصلية، مع وجود بطاقات الأسعار أو الملصقات سليمة، ودليل المستخدم، وبطاقات الضمان والإكسسوارات الأصلية في تغليف الشركة المصنعة.',
    refundOriginalMode = 'استرداد إلى طريقة الدفع الأصلية',
    confirmYourReturn = 'أكد إرجاعك',
    confirmYourCancellation = 'أكد إلغاءك',
    courierService = 'سيتم استلام الطرد الخاص بك بواسطة خدمة البريد السريع. يرجى إعادة العنصر والتغليف في حالته الأصلية لتجنب إلغاء الاستلام من قبل خدمة البريد السريع.',
    pickupAddress = 'عنوان الاستلام',
    refundSummary = 'ملخص الاسترداد',
    deliveryFee = 'رسوم التوصيل',
    returnFee = 'رسوم الإرجاع',
    defaultSelectedAddress = 'الرياض',
    soldBy = 'تم البيع بواسطة',
    SearchHere = 'ابحث هنا',
    ClearAll = 'امسح الكل',
    returnExchangePolicy = 'سياسة الاسترجاع والاستبدال',
    returningAgree = "بإعادة هذه العناصر، أنت توافق على شروط ساكو",
    convertYourExistingPoint = 'هل ترغب في تحويل بطاقة الولاء الحالية إلى عضوية نادي ساكو؟',
    existingLoyaltyCardClick = 'إذا كانت لديك بطاقة ولاء حالية وتريد تحويلها إلى عضوية نادي ساكو، يرجى النقر أدناه.',
    notValidLoyaltyCard = 'رقم بطاقة الولاء غير صالح',
    reviews = 'مراجعات',
    fieldLevelMessage1 = 'لقد قمنا بتعديل الكمية المطلوبة لأن لدينا فقط ' ,
    fieldLevelMessage2 = ' هذا المنتج متاح.',
    globalLevelMessage = 'نعتذر. لقد طلبت المزيد من بعض المنتجات أكثر من المتوفر لدينا، وقمنا بتعديل الكميات لك. انظر أدناه التغييرات.' ,
    storeaddress = 'عنوان المتجر',
    DeleteAccount = 'حذف الحساب',
    deleteAccountText = 'هل تريد حذف حسابك؟',
    Fast = 'سريع',
    Delivery = 'توصيل',
    married = 'متزوج',
    searchbyBrand = 'البحث حسب العلامة التجارية',
    back = 'رجوع',
    PriceHighestText = 'السعر (أعلى أولاً)',
    instagramLabel = 'إنستغرام',
    snapchatLabel = 'سناب شات',
    tiktokLabel = 'تيك توك',
    ProductListingPage = 'صفحة قائمة المنتجات',
    SearchResultsPage= 'صفحة نتائج البحث',
    noBrandsAvailable = 'لا توجد علامات تجارية متاحة',
    showMoreAddress = "عرض المزيد من العناوين",
    hideMoreAddress = "إخفاء المزيد من العناوين",
    selectStore = 'اختر المتجر',
    spend = 'أنفق',
    tabbyFailureOrRejected = 'نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.',
    tabbyCancelledOrExpried = 'لقد ألغيت الدفعة. فضلاً حاول مجددًا أو اختر طريقة دفع أخرى.',
    allowLocationText = 'تمكين الموقع لاختيار متجر',
    loyaltyPointsText = 'يمكن استخدام نقاط الولاء فقط عندما تكون مساوية أو أكثر من 100.',
    loyaltyPointsText1 = 'تسوق أكثر لتجميع نقاط الولاء.',
    AssuredQuality = 'جودة مضمونة', 
    FastDelivery = 'توصيل سريع',
    EasyReturns = 'سهل إرجاع',
    closed = 'مغلق',
    ViewonMap = 'عرض على الخريطة',
    cookieConsent = 'موافقة على ملفات تعريف الارتباط',
    accept = 'قبول',
    cookiePolicy = 'يستخدم هذا الموقع ملفات تعريف الارتباط أو تقنيات مشابهة لتحسين تجربة التصفح الخاصة بك وتقديم توصيات مخصصة. بمواصلة استخدام موقعنا، فإنك توافق على سياسة ملفات تعريف الارتباط الخاصة بنا.',
    sacoIsBetter = 'ساكو أفضل على التطبيق',
    purchaseYourItem = 'تصفح، واشترِ منتجاتك',
    officialMobileApp = 'من خلال التطبيق الرسمي لساكو!',
    openTxt = 'فتح',
    installApp = 'تثبيت التطبيق',
    goText = 'اذهب',
    digitalWalletText = 'استخدم محفظتي الرقمية لهذا الطلب',
    Size = 'الحجم',
    YourSearchResultsFor = 'نتائج بحثك عن',
    proceed= 'متابعة',
    couponWarningMessage = 'نحن نعالج طلبك. يرجى الانتظار لحظة',
    couponSuccessMessage = 'تم استرداد القسيمة بنجاح. يرجى الانتظار لحظة لتحديث البيانات',
    GiftCard = 'شراء بطاقة هدية',
}
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionCookieService } from '../services/session-cookie.service';
import { LocalStorageService } from '../services/local-storage.service';
import { HeaderService } from '../services/header.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  selectedAddressInfo: any;

  constructor(
    private sessionCookieService: SessionCookieService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private headerService: HeaderService

  ) { }

  getAuthToken(): any {
    return this.localStorageService.getValue('isLoggedIn') ?
      this.sessionCookieService.getCookieValue('current-session-token')
      : this.sessionCookieService.getCookieValue('general-session-token');
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if(this.isTokenRequiredApi(request)){
      const myHeaders =  new HttpHeaders()
      const newRequest = request.clone({
         headers: myHeaders
      });
      return next.handle(newRequest);
    }

    this.headerService?.addressHeaders?.subscribe((res: any) => {
      if (res?.SELECTEDCITY || res?.LATLONG) {
        this.selectedAddressInfo = res;
      }
    });

    const token = this.getAuthToken();
    if (token && this.selectedAddressInfo) {
      // console.log('Selected Address Info : ', this.selectedAddressInfo);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'SELECTEDCITY': this.selectedAddressInfo?.SELECTEDCITY,
          // 'SELECTEDDISTRICT': this.selectedAddressInfo?.SELECTEDDISTRICT
          // ...additionalHeaders,
        }
      });

    } else if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Unauthorized error, redirect to login page
          this.sessionCookieService.clearSessionDataAndLogout(); // Clear any user data
          //this.router.navigate(['/']);
          window.location.href = '/';
        }

        // Rethrow the error to be caught by the calling code
        return throwError(error);
      })
    );
  }

  isTokenRequiredApi(request:any){
    if (request.url.includes('/sacoStore/cms/') ||
    request.url.includes('/basesites?')||
    request.url.includes('/searchTile?')||
    request.url.includes('category/?categoryCode')
     ) {
      return true
    }
    return false
  }
}

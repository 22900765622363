import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild, Renderer2, ElementRef, OnChanges, SimpleChanges, AfterContentChecked } from '@angular/core';
import { CategoryNavigationComponent, CmsComponentData, NavigationService } from '@spartacus/storefront';
import { CmsNavigationComponent } from '@spartacus/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { SessionCookieService } from 'src/app/core/services/session-cookie.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global.service';
import { BroadcastChannelService } from 'src/app/shared/broadcast-channel.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';

@Component({
  selector: 'app-custom-navigation',
  templateUrl: './custom-navigation.component.html',
  styleUrls: ['./custom-navigation.component.scss']
})
export class CustomNavigationComponent extends CategoryNavigationComponent implements OnInit, OnChanges, AfterContentChecked {
  showWaitCursor = new BehaviorSubject<boolean>(true);
  AllProducts: any = [];
  serviceSubscribe: Subscription = new Subscription;
  @ViewChild('closebutton') closebutton: any;
  showCatagory: any = [];
  userInfo: any;
  isLoading: boolean = false;
  langConfig: any;
  isProfileUpdated: boolean = true;
  guestUserLogin: boolean = false;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    public cd: ChangeDetectorRef,
    service: NavigationService,
    componentData: CmsComponentData<CmsNavigationComponent>,
    private userAccountService: UserAccountService,
    private sessionCookieService: SessionCookieService,
    private globalService: GlobalService,
    private router: Router,
    private broadcastChannelService: BroadcastChannelService,
    private localStorageService: LocalStorageService
  ) {
    super(componentData, service);
    let lang = localStorage.getItem('spartacus⚿⚿language') ? localStorage.getItem('spartacus⚿⚿language') : 'en'
    this.langConfig = (lang == '"ar"') ? ArabicEnum : EnglishEnum;
  }
  @HostListener('click', ['$event.target']) onClick(e: any) {
    let popupRemoved = this.el?.nativeElement?.querySelector('.show');
    if (e?.classList && (e?.classList?.contains('close-btn') || e?.classList?.contains('fade'))) {
      this.renderer?.removeClass(popupRemoved, "show");
      this.cd.markForCheck();
    }
  }

  ngOnInit(): void {
    this.userInfo = null;
    this.showWaitCursor.next(true);
    this.isLoading = false;
    this.guestUserLogin = (localStorage.getItem('guestUserLogin') == 'true');
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterContentChecked(): void {
    this.navMenuList();
    if (this.localStorageService.getValue('isLoggedIn')) {
      if (this.broadcastChannelService.getSharedDataObservable('userDetailsInfo') && !this.userInfo) {
        this.broadcastChannelService.getSharedDataObservable('userDetailsInfo')?.subscribe(
          (userData: any) => {
            if (userData) {
              this.userInfo = userData;
              this.cd.detectChanges();
              this.cd.markForCheck();
            } else if (this.isProfileUpdated) {
              this.userAccountService.updateUserAccountDetails(this.isProfileUpdated);
              this.isProfileUpdated = false;
            }
          }
        );
      } else if (!this.userInfo && !this.isLoading && this.isProfileUpdated) {
        this.userAccountService.updateUserAccountDetails(this.isProfileUpdated);
        this.isProfileUpdated = false;
      }
    }
  }

  signOut(): void {
    this.globalService.getGeneralToken().subscribe(
      (tokenData: any) => {
        this.sessionCookieService.clearSessionDataAndLogout();
        this.isLoading = false;
        this.broadcastChannelService.createChannel('userDetailsInfo', null);
        this.globalService.setToken(tokenData?.access_token);
        location.href = '/';
      });

  }

  showMoreCatagory(ind: any): void {
    this.showCatagory[ind] = !this.showCatagory[ind];
  }

  navMenuList(): void {
    this.serviceSubscribe = this.node$.subscribe((data: any) => {
      this.showWaitCursor.next(true);
      this.AllProducts = data?.children[0]?.children;
      this.showWaitCursor.next(false);
      this.cd.markForCheck();
    })
  }
}

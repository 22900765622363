<div class="loading" *ngIf="showWaitCursor | async ">Loading&#8230;</div> 
<div class="chip-container" *ngIf="selectedFacetsNames.length > 0 ">
    <div *ngFor="let facet of selectedFacetsNames" class="chip">
        <span>{{ facet.name }}</span>
        <span class="close-icon" (click)="removeFacet(facet)">&times;</span>
    </div>
</div>
  
<div class="products-facet" (window:resize)="onResize($event)">
    <div class=" d-flex justify-content-between main-view">
        <div class="filter-head">   </div>
        <a href="javascript:void(0)" *ngIf="selectedFacetsNames && selectedFacetsNames.length > 0" (click)="clearAllClick()" class="clearAll"> {{productListLangConfig?.ClearAll}} </a>
    </div>
    <div class="accordion">
        <div class="cardview" *ngFor="let facetData of facetsData; let ind = index">
            <div class="card-header">
                <h3 class="btn btn-header-link mb-0 pl-0" data-toggle="collapse" [ngClass]="isMobile ? 'collapsed': ''" [attr.data-target]="'#facetList' + ind" aria-expanded="false" [attr.aria-controls]="'facetList' + ind">
                    {{facetData.name}} </h3>
            </div>
            <div [attr.id]="'facetList' + ind" class="collapse" [ngClass]="isMobile ? '': 'show'">
                <div class="card-body">
                    <div class="input-group mb-3" *ngIf="facetData?.code == 'brand'">
                        <input class="form-control" #searchTerm  (keyup)="filterBrands(searchTerm.value, ind)" [placeholder]="productListLangConfig.SearchHere">
                        <div class="input-group-append">
                          <span class="input-group-text bg-white border" >
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </div>
                      </div>
                    <ul>
                        <ng-container *ngFor="let data of facetData?.values | sort:'asc':'name'; let i = index" >                        
                            <li *ngIf="i < 5 " [ngClass]="{ 
                                'd-inline-flex': facetData?.code == 'v_variantColour', 
                                'd-flex flex-row form-check': facetData?.code != 'v_variantColour',
                                'slider': facetData?.code == 'price'
                              }">
                                <ng-container *ngIf="facetData?.code=='price'">
                                <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="{
                                    floor: data.code.split('-')[0],
                                    ceil: data.code.split('-')[1],
                                  }"></ngx-slider>
                                <button type="button" class="btn btn-outline-primary p-2 mx-1" (click)="filterPrice()"> {{productListLangConfig.goText }} </button>
                            </ng-container>                      
                                <label  class="form-check-label">
                                    <input type="checkbox"  *ngIf="facetData?.code != 'price'" [checked]="checkedStatus(data.name)" name="dropdown-group" class="rounded-0"
                                    (dblclick)="disableDoubleClick($event)" (change)="checkValue($event , data, ind, i  )"
                                        [value]="data.query?.query?.value" [disabled]="disabled" [hidden]="facetData?.code=='v_variantColour'" />
                                    <ng-container *ngIf="facetData?.code!='productAvgRating' && facetData?.code!='v_variantColour' && facetData?.code != 'price'; else reviewData">
                                        {{data?.name}}
                                    </ng-container>
                                    <ng-template #reviewData>
                                        <ng-container *ngIf="facetData?.code=='v_variantColour'">
                                            <span class="color-box" [ngStyle]="{'background-color':data?.code}"></span>
                                        </ng-container>
                                        <ng-container *ngIf="facetData?.code=='productAvgRating'">
                                            <app-ratings [ratings]="data?.code"></app-ratings>
                                        </ng-container>
                                    </ng-template>
                                </label>
                            </li>
                        </ng-container>
                        <div *ngIf="facetData?.values.length > 4" [attr.id]="'show' + ind" class="collapse">                           
                            <ng-container *ngFor="let data of facetData?.values| sort:'asc':'name';  let i = index">
                                <li *ngIf="i > 4" [ngClass]="facetData?.code=='v_variantColour' ? 'd-inline-flex' : 'd-flex flex-row form-check'">
                                    <label  class="form-check-label">
                                        <input  *ngIf="facetData?.code !='price'" type="checkbox" [checked]="checkedStatus(data.name)" name="dropdown-group" class="rounded-0"
                                            (dblclick)="disableDoubleClick($event)" (change)="checkValue($event , data, ind, i  )"
                                            [value]="data.query?.query?.value" [disabled]="disabled" [hidden]="facetData?.code=='v_variantColour'" />
                                        <ng-container *ngIf="facetData?.code!='productAvgRating' && facetData?.code!='v_variantColour'; else reviewData">
                                            {{data?.name}}
                                        </ng-container>
                                        <ng-template #reviewData>
                                            <ng-container *ngIf="facetData?.code== 'v_variantColour'">
                                                <span class="color-box" [ngStyle]="{'background-color':data?.code}"></span>
                                            </ng-container>
                                            <ng-container *ngIf="facetData?.code == 'productAvgRating'">
                                                <app-ratings [ratings]="data?.code"></app-ratings>
                                            </ng-container>
                                        </ng-template>
                                    </label>
                                </li>
                            </ng-container>                           
                        </div>
                        <div class="my-3">
                            <a href="javascript:void(0)" class="showMoreBtn" [attr.id]="ind" *ngIf="facetData?.values.length > 5" (click)="showMore(ind)" data-toggle="collapse" [attr.data-target]="'#show' + ind" aria-expanded="false" [attr.aria-controls]="'show' + ind" [ngClass]="ind < 3 ? '' : 'collapsed' ">
                                {{showDetails[ind] == true ? productListLangConfig.show_less : productListLangConfig.show_more}}
                            </a>
                        </div>
                    </ul>
                </div>
            </div>
            <hr>
        </div>
        
    </div>
</div>
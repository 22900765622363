import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './product-list.component';
import { RatingsModule } from '../ratings/ratings.module';
import { WishlistModule } from "../wishlist/wishlist.module";
import { IconModule, MediaModule, PageSlotModule } from '@spartacus/storefront';
import { NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
    declarations: [
        ProductListComponent
    ],
    exports: [
        ProductListComponent
    ],
    imports: [
        CommonModule,
        PageSlotModule,
        MediaModule,
        RatingsModule,
        NgbPaginationModule,
        NgbDropdownModule,
        IconModule,
        WishlistModule
    ]
})
export class ProductListModule { }

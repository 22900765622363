import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsGTMService {
  constructor() { }

  //Page load Event included in the header component so it triggers on every page
  onPageLoadGTM(user_id:any, user_email: string, user_city: string, user_country: string, user_postalCode: string, user_state:string, user_type:string) {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({ ecommerce: null });
    (<any>window).dataLayer.push({
      'user_id': user_id,
      'user_email': user_email,
      'user_city': user_city,
      'user_country': user_country,
      'user_postalCode': user_postalCode,
      'user_state': user_state,
      'user_type': user_type
      });
  }

  //This event triggers on PLP2, PLP1 and SRP pages
  onViewItemsListGTM(items:any[]){
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({ ecommerce: null });
    (<any>window).dataLayer.push({
        event: 'view_item_list',
        ecommerce: {                          
          items:items 
        }
    });
  }

  //Triggered on PDP
  onProductClickGTM(product:any){
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push({ ecommerce: null });
    (<any>window).dataLayer.push({
    event: 'select_item',
    ecommerce: {                          
        items: [{
        item_name: product?.name,
        item_id: product?.code,
        price: product?.price?.value,
        // item_brand: 'brand A',
        // item_category: 'Apparel',
        // item_category2: 'T-shirt',
        // item_variant: 'Blue',
        item_list_name: 'Search results',
        item_list_id: 'search_results',
        index: 1,
        quantity: 1
        }]
        }
    });
    }

    //Triggered on PDP
    onViewItemPDPGTM(item:any){
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({ ecommerce: null });
        (<any>window).dataLayer.push({
            event: 'view_item',           
            ecommerce: {                          
              items: item
            }
          
        });
        }
    
    onAddtoCartEventGTM(item:any){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
          event: 'add_to_cart',           
          ecommerce: {                          
            items: item
          }
        
      });
    }
    onRemoveFromCartEventGTM(item:any){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
          event: 'remove_from_cart',           
          ecommerce: {                          
            items: item
          }
        
      });
    }
    triggerGTMBeginCheckoutEvent(item:any){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
          event: 'begin_checkout',           
          ecommerce: {                          
            items: item
          }
        
      });
    }

    triggerGTMAddShippingInfotEvent(item:any, shipping_info:any){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
          event: 'add_shipping_info',           
          ecommerce: {          
            shipping_tier: shipping_info,                  
            items: item
          }
        
      });
    }

    triggerGTMAddPaymentInfoEvent(item:any, payment_info:any){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
          event: 'add_payment_info',
          ecommerce: {            
            payment_type: payment_info,              
            items: item
          }
        
      });
    }

    triggerGTMPurchaseEvent(item:any, order:any){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
        event: 'purchase',
        ecommerce: {						
          currency: order?.totalPriceWithTax?.currencyIso,						
          value:order?.totalPriceWithTax?.value,
          tax: order?.totalTax?.value,
          shipping: order?.deliveryCost?.value,
          affiliation: order?.store,
          transaction_id: order?.code,
          // coupon: 'ENDOFSUMMER',          						                         
            items: item
          }
        
      });
    }

    onViewCartEventGTM(item:any){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
          event: 'view_cart',
          ecommerce: {                          
            items: item
          }
        
      });
    }

    onCheckoutErrorEventGTM(errorType?:string, errorMsg?:any, cartData?:any){
      if(!errorType){
        errorType = 'checkout_error'
      }
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
        event: errorType,
        // error_code: errorCode,
        error_message: errorMsg,
        user_id: cartData?.user?.id,
        cart_value: cartData?.totalPriceWithTax?.value,
        currency: cartData?.totalPriceWithTax?.currencyIso,
        // payment_method: paymentMethod,
      });
      console.log('dataLayer for Error..')
    }

    onCartErrorEventGTM(items:any, error:any, cartData:any){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
        event: 'cart_error',
        error_type: 'cart_page',
        error_code: (error?.subject && error?.reason && error?.reason != undefined && error?.subject != undefined ) ? error?.subject + ' ' + error?.reason : '',
        error_message: error?.message,
        user_id: cartData?.user.uid,
        currency: cartData?.totalPriceWithTax?.currencyIso,
        'items': items
      });
    }

    onNotificationErrorEventGTM(url:string){
      //trigger only if it's checkout page -for checkout page we have payment/location specific handling
      if(!url.includes('checkout')){
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({ ecommerce: null });
        (<any>window).dataLayer.push({
          event: 'notification_error',
          url: url
        });
      }
      
    }
    paymentCardErrors(cardType:string, error?:any){
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({ ecommerce: null });
        (<any>window).dataLayer.push({
          event: cardType,
          error_type: 'checkout_page',
          error_message: (error?.error?.responseMessage) ? error?.error?.responseMessage : '',
          url: window.location.href
        });
    }
    googleMapsClickEvent(location:string){
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({ ecommerce: null });
      (<any>window).dataLayer.push({
        event: 'google_maps_click',
        link_url: 'https://www.google.com/maps',
        link_text: location
      });
    }
}

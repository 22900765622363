<div class="loading" *ngIf="showWaitCursor | async ">Loading&#8230;</div>

<div class="modal-container pickup-modal">
  <div class="modal-header">
      <span class="modal-title" id="modal-basic-title">{{langConfig?.choose_your_location}}</span>
      <i aria-label="Close" class="fa-solid fa-xmark" aria-label="Close" (click)="closeModal()"></i>
  </div>
  <div class="modal-body">
      <div class="modal-content">
         <span class="heading-info">{{langConfig?.pickYourDeliveryText}}</span>

         <div class="address-container mt-3">
          <div class="address" *ngFor="let item of userAddress; let  i = index" (click)="selectAddress(i)" [ngClass]="{'selected-address': selectedAddress==i}">
            <span>{{item?.formattedAddress}}</span>
          </div>
         </div>

         <div class="bordered-container d-flex">
          <div class="bordered"></div>
          <div class="bordered-text d-flex">{{langConfig.or}}</div>
          <div class="bordered"></div>
      </div>

        <div class="more-options" (click)="moreOptions()">
        {{langConfig?.moreOptions}}
        </div>

         
      </div>
  </div>
</div>

<app-notifications [showToast]="showToast" [errorMessage]="errorMessage" [toastMessage]="toastMessage"></app-notifications>

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from './local-storage.service';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class SessionCookieService {

  constructor(
    private cookieService: CookieService,
    private localStorageService: LocalStorageService
  ) { }

  // Function to set a timeless cookie
  setTimelessCookie(cookieName: string, cookieValue: string) {
    // Set the cookie with a very distant future expiration date
    const expirationDate = new Date();
    expirationDate?.setFullYear(expirationDate.getFullYear() + 100);

    this.cookieService.set(cookieName, cookieValue, expirationDate, '/');
  }

  // Function to get the value of a cookie
  getCookieValue(cookieName: string): string {
    return this.cookieService?.get(cookieName);
  }

  // Function to check if a cookie exists
  cookieExists(cookieName: string): boolean {
    return this.cookieService?.check(cookieName);
  }

  clearSessionDataAndLogout(): void {
    // Split the cookies string into individual cookies
    const socialLogin = this.localStorageService.getValue('socialLogin');
    if (socialLogin) {
      //this.socialAuthService.signOut();
      if (socialLogin == 'google') {
        google?.accounts?.id?.disableAutoSelect();
      }
      this.localStorageService.removeValue('socialLogin');
    }

    // console.log("Calling the Logout API here");

    this.cookieService.delete('current-session-token');
    this.cookieService.delete('general-session-token');
    this.localStorageService.removeValue('isLoggedIn');
    this.localStorageService.setValue('hasToken', false);
    this.localStorageService.setValue('isCartAvailable', false);

    // Clear local storage
    sessionStorage.clear();
    // Clear local storage
    
    const anonymous_cart_guid = localStorage.getItem('anonymous_cart_guid') || '';
    const lang = localStorage.getItem('spartacus⚿⚿language') || 'ar'
    localStorage.clear();
    localStorage.setItem('anonymous_cart_guid', anonymous_cart_guid);
    localStorage.setItem('spartacus⚿⚿language', lang);
  }
}

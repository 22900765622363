import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { CHECKOUT_GET_CART_LIST, PICKUP_POST_FETCH_STORES, PICKUP_POST_SEARCH_STORES, PICKUP_POST_ADD_QTY, GET_ADDITIONAL_INFO_PDP, UPDATE_PAYMENT_DETAILS, GET_QTAF_OTP, SUBMIT_QTAF_OTP, PLACE_ORDER_URL, SET_DELIVERY_MODE, GET_TAMARA_DETAILS, GET_TAMARA_CHECKOUT_DETAILS, GET_TAMARA_ORDER_STATUS, MANAGE_BUY_NOW_CART, LOYALTY_POINTS_REDEEM, MERIT_PAYMENT_REDEEM, GIFT_CARD_REDEEM, STORE_CREDITS_REDEEM, TABBY_VISIBILTY_STATUS, TABBY_PAYMENT, PAYFORT_CARD_INFO, GET_TABBY_STATUS, REMOVE_CREDITS_REDEEM, COUPON_PAYMENT_REDEEM, CART_DELIVERY_MODE, TABBY_RECEIPT, UPDATE_MANUAL_ADDRESS, GET_SELECTED_PAYMENT_INFO, STC_PAY, TAMARA_VISIBILTY_STATUS } from './endpointURL';

import { GlobalService } from './global.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(
    private http: HttpClient,
    public globalService: GlobalService,
    private localStorageService: LocalStorageService
  ) { }

  public getCheckoutCartList(cartId?: any): Observable<any> {
    return this.http.get(CHECKOUT_GET_CART_LIST(cartId).url)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  getStoresForPickup(productId?: any, lat?: any, lng?: any): Observable<any> {
    //const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globalService.getToken()}`).set('Cache-Control', 'no-cache');
    let selectedCity: any;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // selectedCity = sessionStorage.getItem('SELECTEDCITY') || 3;
    selectedCity = this.localStorageService.getValue('SELECTEDCITY') || 3;
    return this.http.post(PICKUP_POST_FETCH_STORES(productId, lat, lng).url, {}, {
       headers: {
        'Content-Type': 'application/json',
        'SELECTEDCITY': selectedCity
      }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  searchStoresForPickup(productId?: any, query?: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globalService.getToken()}`).set('Cache-Control', 'no-cache');
    return this.http.post(PICKUP_POST_SEARCH_STORES(productId, query).url, { headers })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  addPickupQty(productId: any, cartID: any, storeID: string, quantity: any, cartType: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globalService.getToken()}`).set('Cache-Control', 'no-cache');
    return this.http.post(PICKUP_POST_ADD_QTY(productId, cartID, storeID , quantity, cartType).url, { headers })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  additionalInfoPDP(productId: any, cartId:any) {
    let selectedCity: any;
    selectedCity = this.localStorageService.getValue('SELECTEDCITY') || 3;
    let headers = new HttpHeaders().set('Authorization', `Bearer ${this.globalService.getToken()}`).set('Cache-Control', 'no-cache', );
    const apiUrl = cartId && this.localStorageService.getValue('isLoggedIn') ? GET_ADDITIONAL_INFO_PDP(productId).url +'&cartId=': GET_ADDITIONAL_INFO_PDP(productId).url;
    // +cartId
    if (selectedCity) {
      headers = headers.set('SELECTEDCITY', selectedCity);
    }
    
    return this.http.get(apiUrl, { headers: {'SELECTEDCITY': selectedCity} })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );

  }

  // update Paymments api
  public updatePaymentDetails(cartId: any, paymentDetails: any): Observable<any> {
    const apiUrl = UPDATE_PAYMENT_DETAILS(cartId)?.url;
    return this.http.post<any>(apiUrl, paymentDetails, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public jsonToFormData(json: any): URLSearchParams {
    const formData = new URLSearchParams();
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        formData.append(key, json[key]);
      }
    }
    return formData;
  }

  // update submit payment api
  public submitPayment(url: any, paymentDetails: any): Observable<any> {
    const apiUrl = url;
    const formData = this.jsonToFormData(paymentDetails);
    return this.http.post<any>(apiUrl, formData, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // QTAF Get OTP
  public qtafGetOTP(cartId: any, phoneNumber: any): Observable<any> {
    const apiUrl = GET_QTAF_OTP(cartId)?.url + '/otp';
    return this.http.post<any>(apiUrl, phoneNumber, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Remove Qitaf Payment
  public removeQTAF(cartId: any): Observable<any> {
    const apiUrl = GET_QTAF_OTP(cartId)?.url;
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // QTAF Submit  OTP
  public qtafSubmitOTP(cartId: any, qtafObject: any): Observable<any> {
    const apiUrl = SUBMIT_QTAF_OTP(cartId)?.url;
    return this.http.post<any>(apiUrl, qtafObject, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // QTAF Place Order
  public placeOrder(cartId: any): Observable<any> {
    const apiUrl = PLACE_ORDER_URL(cartId)?.url;
    return this.http.post<any>(apiUrl, {}, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Set Delivery Mode 
  public setDeliveryMode(cartId: any, deliveryMode: any): Observable<any> {
    const apiUrl = SET_DELIVERY_MODE(cartId)?.url + deliveryMode;
    return this.http.put<any>(apiUrl, {}, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
     // Set Delivery Mode in page load 
  public CartDeliveryModeAPI(cartId: any): Observable<any> {
    const apiUrl = CART_DELIVERY_MODE(cartId)?.url;
    return this.http.put<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Get Tamara Details 
  public getTamaraDetails(cartId: any): Observable<any> {
    const apiUrl = GET_TAMARA_DETAILS(cartId)?.url;
    return this.http.post<any>(apiUrl, {}, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Get Tamara Checkout Details 
  public getTamaraCheckoutDetails(cartId: any): Observable<any> {
    const apiUrl = GET_TAMARA_CHECKOUT_DETAILS(cartId)?.url + '?invokedFrom=spa';
    return this.http.post<any>(apiUrl, {}, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  checkTamaraStatus(cartId: any) {
    const apiUrl = GET_TAMARA_ORDER_STATUS(cartId)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  checkTabbyStatus(cartId: any) {
    const apiUrl = GET_TABBY_STATUS(cartId)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  tabbyRecipt(reciptObject: any) {
    const apiUrl = TABBY_RECEIPT(reciptObject.users, reciptObject.cartCode, reciptObject.paymentId)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  manageBuyNow() {
    const apiUrl = MANAGE_BUY_NOW_CART?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public loyaltyPointsRedeem(cartId: any, amount: any): Observable<any> {
    const apiUrl = LOYALTY_POINTS_REDEEM(cartId)?.url + 'redeem';
    return this.http.post<any>(apiUrl, amount, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public loyaltyPointsRemove(cartId: any): Observable<any> {
    const apiUrl = LOYALTY_POINTS_REDEEM(cartId)?.url + 'remove';
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public meritCardRedeem(cartId: any, voucher: any, isDelete: boolean): Observable<any> {
    const apiUrl = MERIT_PAYMENT_REDEEM(cartId)?.url + (isDelete ? 'remove' : 'add');
    return this.http.post<any>(apiUrl, voucher, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public couponRedeem(cartId: any, couponID: any): Observable<any> {
    const apiUrl = COUPON_PAYMENT_REDEEM(cartId)?.url + couponID;
    return this.http.post<any>(apiUrl, {}, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public removeCoupon(cartId: any, couponID: any): Observable<any> {
    const apiUrl = COUPON_PAYMENT_REDEEM(cartId)?.url + couponID;
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public storeCreditsRedeem(cartId: any): Observable<any> {
    const apiUrl = STORE_CREDITS_REDEEM(cartId)?.url;
    return this.http.post<any>(apiUrl, {}, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public removeStoreCredits(cartId: any): Observable<any> {
    const apiUrl = REMOVE_CREDITS_REDEEM(cartId)?.url;
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public removeAllMeritCard(cartId: any): Observable<any> {
    const apiUrl = MERIT_PAYMENT_REDEEM(cartId)?.url + 'removeAll';
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public giftCardRedeem(cartId: any, voucher: any): Observable<any> {
    const apiUrl = GIFT_CARD_REDEEM(cartId)?.url + 'redeem';
    return this.http.post<any>(apiUrl, voucher, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public removeGiftCard(cartId: any): Observable<any> {
    const apiUrl = GIFT_CARD_REDEEM(cartId)?.url + 'remove';
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public getTabbyVisibiltyStatus(cartId: any): Observable<any> {
    const apiUrl = TABBY_VISIBILTY_STATUS(cartId)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public getTamaraVisibiltyStatus(cartId: any): Observable<any> {
    const apiUrl = TAMARA_VISIBILTY_STATUS(cartId)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public tabbyPayment(cartId: any): Observable<any> {
    const apiUrl = TABBY_PAYMENT(cartId)?.url;
    return this.http.post<any>(apiUrl, {}, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Get tokenized payment card info
  public paymentCardInfo(): Observable<any> {
    const apiUrl = PAYFORT_CARD_INFO?.url;
    return this.http.post<any>(apiUrl, {}, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  
  // Get tokenized payment card info
  public updateManualAddress(cartId:any, payload:any): Observable<any> {
    const apiUrl = UPDATE_MANUAL_ADDRESS(cartId)?.url;
    return this.http.post<any>(apiUrl, payload, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Get Selected Payment Info
  getSelectedPaymentDetailsInfo(cartID: any): Observable<any> {
    const apiUrl = GET_SELECTED_PAYMENT_INFO(cartID)?.url ;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Save new card details 
  public saveNewCardDetails(payload: any): Observable<any> | void{
  }

  // STC Pay Get OTP
  public stcPayGetOTP(cartId: any, phoneNumber: any): Observable<any> {
    const apiUrl = GET_QTAF_OTP(cartId)?.url + '/otp';
    return this.http.post<any>(apiUrl, phoneNumber, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }


  // STC Pay 
  public stcPay(payload: any): Observable<any> {
    const cartGuid = this.localStorageService.getValue('guid');
    const apiUrl = STC_PAY(cartGuid)?.url;
    return this.http.post<any>(apiUrl, payload, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
} 

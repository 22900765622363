import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomLocationComponent } from './custom-location.component';
import { PageSlotModule } from '@spartacus/storefront';
import { NgbModule, NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { LocationSelectionPopupModule } from './location-selection-popup/location-selection-popup.module';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { NotificationsModule } from 'src/app/shared/components/notifications/notifications.module';



@NgModule({
  declarations: [
    CustomLocationComponent
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    LocationSelectionPopupModule,
    NgbModule,
    NgbToast,
    NotificationsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomAddressComponent: {
          // component: CustomLocationComponent
        }
      }
    } as CmsConfig)
  ],
  exports:[
    CustomLocationComponent
  ],
  providers: [ActiveCartService]
})
export class CustomLocationModule {
}

import { Component, OnInit } from '@angular/core';
import { GlobalService } from './core/services/global.service';
import { HrefLangAndCanonicalService } from './core/services/href-lang-and-canonical.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading = true;

  title = 'SACO';
  showHeader: boolean = true;
  constructor(
    private globalService: GlobalService,
    private hrefLangAndCanonicalService: HrefLangAndCanonicalService,
  ) {
    this.globalService.setToken();
    if (location.href?.includes('/login')) {
      this.showHeader = false;
    }
  }
  ngOnInit(): void {
    this.hrefLangAndCanonicalService.initSeoTags();

    setTimeout(() => {
      this.isLoading = false;
    }, 3000); // Adjust this timeout to reflect actual loading time
  }
}

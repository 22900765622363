import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCarouselModule } from './custom-carousel/custom-carousel.module';
import { CustomCardModule } from './custom-card/custom-card.module';
import { MediaModule, PageSlotModule } from '@spartacus/storefront';
import { CustomProductFacetsModule } from './custom-product-facets/custom-product-facets.module';
import { CustomBreadcrumModule } from './custom-breadcrum/custom-breadcrum.module';
import { ProductListModule } from './product-list/product-list.module';
import { CarouselModule } from 'primeng/carousel';
import { ProductCarouselComponent } from './product-carousel/product-carousel.component';
import { PdpCarouselComponent } from './pdp-carousel/pdp-carousel.component';
import { WishlistModule } from './wishlist/wishlist.module';
import { RatingsModule } from './ratings/ratings.module';
import { AddtocartModule } from './addtocart/addtocart.module';
import { PickUpFromStore } from './pick-up-store/pick-up-store.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryLocation } from './delivery-location/delivery-location.component';
import { NotificationsModule } from './notifications/notifications.module';
import { ReviewPopupComponent } from './review-popup/review-popup.component';
import { MoreLikeThisComponent } from './more-like-this/more-like-this.component';
import { SmaillAppliancesCarousalComponent } from './smaill-appliances-carousal/smaill-appliances-carousal.component';
import { ZoomDirective } from '../directives/zoom.directive';
import { LoyaltyCartRegistrationModule } from './loyalty-cart-registration/loyalty-cart-registration.module';
import { FormatDecimalSuperScriptPipe } from '../pipes/format-decimal-super-script.pipe';
import { CustomRotatingCarouselComponent } from './custom-rotating-carousel/custom-rotating-carousel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NumericOnlyDirective } from 'src/app/core/utils/numeric-only.directive';
 
@NgModule({
  declarations: [
    ZoomDirective,
    ProductCarouselComponent,
    PdpCarouselComponent,
    PickUpFromStore,
    DeliveryLocation,
    ReviewPopupComponent,
    MoreLikeThisComponent,
    SmaillAppliancesCarousalComponent,
    FormatDecimalSuperScriptPipe,
    CustomRotatingCarouselComponent,
    NumericOnlyDirective
  ],
  imports: [
    CommonModule,
    CustomCarouselModule,
    CustomCardModule,
    MediaModule,
    FormsModule,
    CustomProductFacetsModule,
    CustomBreadcrumModule,
    ProductListModule,
    CarouselModule,
    WishlistModule,
    RatingsModule,
    AddtocartModule,
    ReactiveFormsModule,
    NgbAccordionModule,
    NotificationsModule,
    LoyaltyCartRegistrationModule,
    SlickCarouselModule,
    PageSlotModule,
  ],
  exports: [
    ZoomDirective,
    FormatDecimalSuperScriptPipe,
    CustomCarouselModule,
    CustomCardModule,
    CustomProductFacetsModule,
    CustomBreadcrumModule,
    ProductListModule,
    ProductCarouselComponent,
    PdpCarouselComponent,
    WishlistModule,
    RatingsModule,
    AddtocartModule,
    PickUpFromStore,
    DeliveryLocation,
    NotificationsModule,
    MoreLikeThisComponent,
    SmaillAppliancesCarousalComponent,
    LoyaltyCartRegistrationModule,
    CustomRotatingCarouselComponent,
    NumericOnlyDirective
  ]
})
export class SharedComponentsModule { }

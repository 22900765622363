import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CURRENT_ADDRESS, UPDATE_ADDRESS, UPDATE_ADDRESS_MOBILE_NAME, UPDATE_DELIVERY_ADDRESS, UPDATE_PAYMENT_DETAILS, GET_STORE_LOCATOR } from './endpointURL';

@Injectable({
  providedIn: 'root'
})
export class MyaccountService {
//  isLocationSelected = new BehaviorSubject<any>({});
  constructor(
    private http: HttpClient,
  ) { }

  // current Address api
  public currentAddress(userType: string = 'current'): Observable<any> {
    const apiUrl = CURRENT_ADDRESS(userType)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // edit Address api
  public editAddress(id?: any): Observable<any> {
    const apiUrl = UPDATE_ADDRESS(id)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // delete Address api
  public deleteAddress(id: any): Observable<any> {
    const apiUrl = UPDATE_ADDRESS(id)?.url;
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // update Address mobile and phone api
  public updateAddressMobileAndName(addressId?: any, name?: any, phoneNo?: any, defaultAdd?: any): Observable<any> {
    const apiUrl = UPDATE_ADDRESS_MOBILE_NAME(addressId, name, phoneNo, defaultAdd)?.url;
    return this.http.put<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // make default Address api
  public makeDefaultAddress(id: any, payload: any): Observable<any> {
    const apiUrl = UPDATE_ADDRESS(id)?.url;
    return this.http.patch<any>(apiUrl, payload, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // update Address api
  public updateAddress(cartId: any, addressId: any): Observable<any> {
    const apiUrl = UPDATE_DELIVERY_ADDRESS(cartId)?.url + addressId;
    return this.http.put<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  storeLocator(page:number, searchQuery:string, userType:any): Observable<any> {
    const apiUrl = GET_STORE_LOCATOR(page, userType)?.url+ searchQuery;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
}

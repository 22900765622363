import { Injectable } from '@angular/core';
import { GlobalMessageConfig, GlobalMessageService } from "@spartacus/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedEventsService {

  constructor(
    private globalMessageService: GlobalMessageService,
    private globalConfig: GlobalMessageConfig
  ) { }

  
  public sharedData: any = new BehaviorSubject(false);

  setSharedData(status: boolean) {
    this.sharedData.next(status);
  }
}

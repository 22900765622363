import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WishlistComponent } from './wishlist.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [WishlistComponent],
  imports: [
    CommonModule,
    NgbToastModule
  ],
  exports: [WishlistComponent]
})
export class WishlistModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import { LocationSelectionPopupComponent } from './location-selection-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgSelectModule } from '@ng-select/ng-select';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsModule } from 'src/app/shared/components/notifications/notifications.module';



@NgModule({
  declarations: [
    LocationSelectionPopupComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    PageSlotModule,
    FormsModule,
    ReactiveFormsModule,
    NgbToast,
    NgSelectModule,
    NotificationsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomAddressComponent: {
          // component: LocationSelectionPopupComponent
        }
      }
    } as CmsConfig)
  ],
  exports:[
    LocationSelectionPopupComponent
  ],
  providers: [ActiveCartService]
})
export class LocationSelectionPopupModule {
}

import { Component, OnInit } from '@angular/core';
import { SessionCookieService } from '../../services/session-cookie.service';
import { LanguageService } from '@spartacus/core';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
@Component({
  selector: 'app-cookie-popup',
  templateUrl: './cookie-popup.component.html',
  styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent implements OnInit {
  cookieLangConfig: any = EnglishEnum;
  public cookieAccepted: boolean = false;

  constructor(
    private sessionCookieService: SessionCookieService,
    private languageService: LanguageService,
  ) {

  }

  ngOnInit(): void {
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar')
        this.cookieLangConfig = ArabicEnum;
    });
    this.cookieAccepted = this.sessionCookieService.getCookieValue('cookieAccepted') == 'true' ? true : false;
  }

  acceptCookies(status: boolean): void {
    if (status) {
      this.sessionCookieService.setTimelessCookie('cookieAccepted', 'true');
      this.cookieAccepted = true;
    }
  }

}
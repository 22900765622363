import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'src/app/environments/environment';
import { REGIONS, LANGUAGES, MAIN_CATEGORY_LIST, GET_ADDRESS_LAT_LAN, ADDRESS_SELECTOR, ADDRESS_SELECTOR_ANONYMOUS, GET_SHORT_ADDRESS, ADDRESS_GET_CITY_LIST, ADDRESS_GET_DISTRICT_BY_CITY_CODE, ADDRESS_GET_DISTRICT_BY_CITY_NAME, ADDRESS_GET_DELIVERY_ADDRESS, ADDRESS_GET_DELIVERY_ADDRESS_BY_GUID, ADDRESS_MANUAL_SELECTOR_ANONYMOUS, MANUAL_SELECTOR_ANONYMOUS } from './endpointURL';
import { catchError } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { BroadcastChannelService } from 'src/app/shared/broadcast-channel.service';
import { LocalStorageService } from './local-storage.service';
import { LanguageService } from '@spartacus/core';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  // lang = localStorage.getItem('spartacus⚿⚿languageb') ? localStorage.getItem('spartacus⚿⚿languageb') : 'en';
  regionSelected: any = EnglishEnum?.defaultSelectedAddress;
  isLocationSelected = new BehaviorSubject<any>({});
  detectLocation = new BehaviorSubject<boolean>(true);
  defaultAddress = new BehaviorSubject<boolean>(false);
  isManualAddress = new BehaviorSubject<any>(false);

  
  isNewAddressSelected = new BehaviorSubject<any>(false);

  addressHeaders = new BehaviorSubject<any>({
    SELECTEDCITY: null,
    SELECTEDDISTRICT: null,
    LATLONG: null,
    LONGIT: null
  });

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private broadcastChannelService: BroadcastChannelService,
    private localStorageService: LocalStorageService,
    private languageService: LanguageService
  ) {
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.regionSelected = this.localStorageService.getValue('currentCity') || ArabicEnum?.defaultSelectedAddress;
      } else {
        this.regionSelected = this.localStorageService.getValue('currentCity') || EnglishEnum?.defaultSelectedAddress;
      }
    });
  }


  public getRegionsList(): Observable<any> {
    return this.http.get<any>(REGIONS.url, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public getLanguages(): Observable<any> {
    return this.http.get<any>(LANGUAGES.url, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public getSearchCategoryOptions(): Observable<any> {
    return this.http.get<any>(MAIN_CATEGORY_LIST.url, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public getAddressLatLan(latLang: any): Observable<any> {
    const apiUrl = GET_ADDRESS_LAT_LAN(latLang)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public jsonToFormData(json: any): URLSearchParams {
    const formData = new URLSearchParams();
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        formData.append(key, json[key]);
      }
    }
    return formData;
  }


  // Select the address
  public selectAddress(payLoad?: any, guid?: any): Observable<any> | undefined {
    const apiUrl = guid && !this.localStorageService.getValue('isLoggedIn') ? ADDRESS_SELECTOR_ANONYMOUS(guid)?.url : ADDRESS_SELECTOR.url;
    const formData = this.jsonToFormData(payLoad);
    // console.log('Auth Token Select Address : ', authToken);
    return this.http.post<any>(apiUrl, formData.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // Select the address
  public selectManualAddress(payLoad?: any, guid?: any): Observable<any> | undefined {
    const apiUrl = guid && !this.localStorageService.getValue('isLoggedIn') ? ADDRESS_MANUAL_SELECTOR_ANONYMOUS(guid)?.url : MANUAL_SELECTOR_ANONYMOUS.url;
    //const formData = this.jsonToFormData(payLoad);
    // console.log('Auth Token Select Address : ', authToken);
    return this.http.post<any>(apiUrl, payLoad, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // Select the address from Address Book
  public selectAddressAddressBook(): Observable<any> | undefined {
    const apiUrl = ADDRESS_SELECTOR.url;
    if (this.localStorageService.getValue('isLoggedIn')) {
      return this.http.get<any>(apiUrl, {
        headers: { 'Content-Type': 'application/json' }
      })
        .pipe(
          catchError(errorRes => {
            return throwError(errorRes);
          })
        );
    }
    return;
  }

  // Select the address by short code
  public selectAddressByShortCode(shortCode?: any): Observable<any> | undefined {
    const apiUrl = GET_SHORT_ADDRESS(shortCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Select the city
  public getCityList(): Observable<any> {
    return this.http.get<any>(ADDRESS_GET_CITY_LIST?.url, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Get Districts by City Code
  public getDistrictList(isCityCode: boolean, cityCodeOrName: any): Observable<any> | undefined {
    const apiUrl = isCityCode ? ADDRESS_GET_DISTRICT_BY_CITY_CODE.url + cityCodeOrName : ADDRESS_GET_DISTRICT_BY_CITY_NAME.url + cityCodeOrName;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Get Delivery Address on the base of cartID

  public getDeliveryAddress(cartID: string): Observable<any> | undefined {
    const apiUrl = ADDRESS_GET_DELIVERY_ADDRESS(cartID)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }


  // Get Delivery Address on the base of cart guID

  public getDeliveryAddressByguid(cartGUID: string): Observable<any> | undefined {
    const apiUrl = ADDRESS_GET_DELIVERY_ADDRESS_BY_GUID(cartGUID)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }


}

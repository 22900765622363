<div class="loading" *ngIf="showWaitCursor | async ">Loading&#8230;</div>
<ng-container *ngIf="AllProducts.length">
<nav class="navbar navbar-light py-0 pl-0">
    <button class="navbar-toggler mobile-nav border-0 p-0 mt-1 mr-2" type="button" data-toggle="modal" data-target="#CatagoryModal">
      <img src="../../../assets//image//hamburger-icon.svg" alt="Menu" class="mr-2"> 
      <ng-container *ngFor="let catagory of AllProducts;">
            <ng-container class="nav-item active" *ngIf="catagory?.title == langConfig?.allText">
                <span  data-toggle="modal" data-target="#CatagoryModal">{{catagory?.title}}</span>
            </ng-container>
        </ng-container>
    </button>
    <ul class="navbar-nav mr-auto mt-lg-0 d-sm-none d-xs-none d-lg-block d-xl-block">
        <ng-container *ngFor="let catagory of AllProducts;">
            
            <li class="nav-item active" *ngIf="catagory?.title != langConfig?.allText">
                <a [href]="catagory?.url" class="nav-link">
                    {{catagory?.title}}
                </a>
            </li>
        </ng-container>
    </ul>
    <div class="modal-popup modal left fade catagoryModal" id="CatagoryModal" role="dialog" aria-labelledby="CatagoryLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header p-0 border-0">
                    <a [href]="userInfo?.name ? '/' : '/login'" class="user-login-btn">
                        <div class="user-profile d-flex flex-row">
                            <div class="avatar-icon mr-2">
                            <img src="../../../assets/image/avatar-icon-white.svg" alt="User">
                            </div>
                            <div class="profile-name">
                                {{langConfig?.helloTxt}} 
                                <ng-container *ngIf="userInfo?.name; else notSignIn">
                                    {{userInfo?.name}}
                                </ng-container>
                                <ng-template #notSignIn>
                                    {{langConfig?.signIn}}
                                </ng-template>
                            </div>
                        </div>
                    </a>
                    <span class="close-btn" #closebutton data-dismiss="modal" title="Close" aria-label="Close">&times;</span>
                </div>
                <div class="modal-body">
                    <div class="category-hd"> {{langConfig?.shopByCategory}}</div>
                    <ng-container *ngFor="let catagory of AllProducts;">
                        <ul class="parent-catagory" *ngIf="catagory?.title==langConfig?.allText">
                            <li class="parent-catagory-item" *ngFor="let parentCatagory of catagory?.children; let i=index">
                                <a href="javascript:void(0)" class="parent-catagory-nav d-flex justify-content-between align-items-center" (click)="showMoreCatagory(i)">
                                    <span>{{parentCatagory?.title}}</span>
                                    <i class="fa-solid fa-chevron-right"></i>
                                </a>
                                <ng-container *ngIf="parentCatagory?.children?.length">
                                        <div class="showCatagory" [ngClass]="showCatagory[i] ? 'show-popup':''">
                                            <ul class="parent-catagory">
                                                <li class="parent-catagory-item back-main-menu mt-2" (click)="showMoreCatagory(i)">
                                                    <p class="parent-catagory-nav m-0">
                                                        <i class="fa-solid fa-arrow-left mr-2"></i>
                                                        {{langConfig.main_menu}}
                                                    </p>
                                                </li>
                                                <li class="parent-catagory-item" *ngFor="let subcatagory of parentCatagory?.children;">
                                                    <a [href]="subcatagory?.url" class="parent-catagory-nav">
                                                        {{subcatagory?.title}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                </ng-container>
                            </li>
                        </ul>
                    </ng-container>
                    <div class="category-hd mt-2">{{langConfig?.helpSettings}}</div>
                    <ul class="parent-catagory">
                        <li class="parent-catagory-item d-flex justify-content-between align-items-center" *ngIf="!guestUserLogin">
                            <a href="/account?pagename=update-profile" class="parent-catagory-nav">{{langConfig?.yourAccount}}</a>
                        </li>
                        <!-- <li class="parent-catagory-item d-flex justify-content-between">
                            <a href="javascript:void(0)" class="parent-catagory-nav">
                                <img src="../../../assets/image/language-icon.svg" alt="language" class="nav-icon"> 
                                {{userInfo?.language?.name? userInfo?.language?.name : 'English'}}</a>
                        </li>
                        <li class="parent-catagory-item d-flex justify-content-between">
                            <a href="javascript:void(0)" class="parent-catagory-nav">
                                <img src="../../../assets/image/country-icon.png" alt="country" class="nav-icon mr-1">Saudi Arabia</a>
                        </li> -->
                        <li class="parent-catagory-item d-flex justify-content-between">
                            <a href="/contact-us" class="parent-catagory-nav">{{langConfig?.help}}</a>
                        </li>
                        <li class="parent-catagory-item d-flex justify-content-between">
                            <ng-container *ngIf="userInfo?.name; else noSignin">
                                <a class="parent-catagory-nav" (click)="signOut()">
                                    <span>{{langConfig?.signOut}}</span>
                                </a>
                            </ng-container>
                            <ng-template #noSignin>
                                <a href="/login" class="parent-catagory-nav">
                                    <span>{{langConfig?.signIn}}</span>
                                </a>
                            </ng-template>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

  </nav>
</ng-container>

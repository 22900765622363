
export enum EnglishEnum {
    newToSACO = 'New to SACO?',
    createAccount = 'Create Account',
    fullName = 'Full Name',
    fullNameRequired = 'Full name is required. Name should not exceed 50 characters.',
    mobileNumber = 'Mobile number is required',
    invaliedEmail = 'Invalid email address.',
    emailAddress = 'Email Address',
    requiredEmail = 'Email is required',
    passwordLength = 'Password must be at least 6 characters.',
    setPassword = 'Set Password',
    requiredPAssword = 'Password is required',
    otpSent = 'To verify your number, we will send you a text message with a temporary code. Message and data rates may apply.',
    verifyMobile = 'Verify mobile number',
    continuePOlicy = 'By continuing, I agree to Conditions of Use and Privacy Policy',
    authenticationFailed = 'Login failed. Please check your credentials and try again.',

    continuePolicyStr1 = 'By creating an account, you agree to ',
    continuePolicyStr2 = 'SACO’s Conditions of Use',
    continuePolicyStr3 = 'and',
    continuePolicyStr4 = 'Privacy Notice',
    loginGeneralError = 'There was a problem',
    loginInvalidEmailOrMobile = 'Your email Id or mobile number is not registered with our website',

    showPassword = 'Show Password',
    confirmPasswordRequire = 'Confirm Password is Required',
    passwordRegexError = 'Password should contain at least one special character and one capital letter.',
    passwordMatch = 'Password and Confirm Password does not Match',
    already_have_account = 'Already have an account?',
    signIn = 'Sign In',
    nameRegexError = "Name should contain only alphabets.",
    phoneRegexError = "Mobile number should be valid.",
    phoneEmailRegexError = 'Mobile number  or Email should be valid.',
    emailRegexError = 'Enter a valid email address',
    validEmailPhone = "Please enter a valid email Id or mobile number",
    emailMobileRequired = "Email or Mobile number is required",
    continue = "Continue",
    sacoPolicy = "By signing in account, you agree to SACO's Conditions of Use and Privacy Notice.",

    sacoPolicyStr1 = "By signing in account, you agree to SACO's",
    sacoPolicyStr2 = "Conditions of Use",
    sacoPolicyStr3 = "and",
    sacoPolicyStr4 = "Privacy Notice.",

    or = 'or',
    needHelp = 'Need help?',
    phoneEmailPlaceholder = 'Email or mobile(5xxxx)',
    createSacoAccount = 'Create your SACO account',
    reEnterPassword = 'Re-enter password',
    change = 'Change',
    forgotPassword = 'Forgot your password?',
    passwordPlaceholder = 'Password',
    keepSignedIn = 'Keep me signed in',
    details = 'Details',
    signInWithCode = 'Sign in with a code',
    authenticationRequired = 'Authentication Required',
    edit = 'Edit',
    otpSend = "We've sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification",
    otpRequired = 'OTP is required',
    resendOTP = 'Resend OTP',
    signInWithPassword = 'Sign in with Password',
    enterOTP = 'Enter OTP',
    passwordAssistance = 'Password assistance',
    emailOrPhoneNumberDetail = 'Enter the email address or mobile phone number associated with your SACO account.',
    emailPhoneChanged = 'Has your email address or mobile phone number changed?',
    noLongerUser = 'If you no longer use the e-mail address associated with your SACO account, you may contact',
    customerService = 'Customer Service',
    accountAccessHelp = 'for help restoring access to your account',
    verificationRequired = 'Verification required',
    continueVerification = "To continue, complete this verification step. We've sent an OTP to",
    enterBelowVerification = 'Please enter it below to complete verification',
    invalidOTP = 'Invalid OTP. Please check your code and try again',
    needMoreHelp = 'I need more help',
    askPassword = "We'll ask for this password whenever you sign in.",
    securePassword = 'Secure password tips:',
    atLeastCharacters = 'Use at least 6 characters, a combination of numbers and letters is best.',
    previousPassword = 'Do not use the same password you have used with us previously.',
    dictionaryWords = 'Do not use dictionary words, your name, e-mail address, mobile phone number or other personal information that can be easily obtained.',
    samePassword = 'Do not use the same password for multiple online accounts.',
    enterNewPassword = 'Enter new password',
    reEnterNewPassword = 'Re-enter new password',
    conditonsOfUse = 'Conditions of Use',
    privacyPolicy = 'Privacy policy',
    help = 'Help',
    home = 'Home',

    // navigation
    helloTxt = 'Hello,',
    hiText = 'Hi there!',
    shopByCategory = 'Shop By Category',
    helpSettings = 'Help & Settings',
    yourAccount = 'Your Account',
    signOut = 'Sign Out',
    signOutLbl = 'Sign out',

    //PLP page
    ofOver = 'of over',
    resultsFor = 'results for',
    resultsLabel = 'results',
    for = 'for',
    sortBy = 'Sort By',
    seeAllResults = 'See all results',
    availableSlot = 'Available slot',
    compare = 'Compare',
    clearAllItems = 'Clear all items',
    Compareitems = 'Compare items',
    close = 'Close',
    wishlistUpdatedSuccessfully = 'Wishlist Updated Successfully',
    previous = 'Previous',
    next = 'Next',

    //CLP Page 
    featuredCategories = 'Featured Categories',
    topBrands = 'Top Brands',


    // PDP page
    boughtPastmonth = 'bought in a month',
    earn = 'Earn',
    loyaltyPoints = 'loyalty points',
    notifyMe = 'Notify me for price drop',
    dismissAlert = 'Dismiss Alert',
    payMinimum = 'Pay a minimum of',
    noLateFees = 'now, and the rest over time - no late fees, no interest.',
    months = 'month (24 months)',
    with = 'with',
    emi = 'EMI',
    onYour = 'on your',
    visa = 'VISA',
    cards = 'Cards',
    allEmiPlans = 'All EMI Plans',
    payOnDelivery = 'Pay on Delivery',
    easyReplacement = 'Easy Replacement',
    assuredDelivery = 'Assured Delivery',
    topHighlights = 'Top highlights',
    freeDeliveryBy = 'Free delivery by',
    deliveringTo = 'Delivering to',
    standardDelivery = 'Standard Delivery',
    expressDelivery = 'Express Delivery',
    pickupFromStore = 'Click and Pick',
    limitedStock = 'Few items left',
    addtoCart = 'Add to Cart',
    buyNow = 'Buy Now',
    addtoWishlist = 'Add to Wishlist',
    removetoWishlist = 'Remove From Wishlist',
    movetoWishlist = 'Move to Wishlist',
    productDetails = 'Product Details',
    technicalDetails = 'Technical Details:',
    additionalInformation = 'Additional Information:',
    addedToCart = 'Added to cart',
    images = 'IMAGES',
    videos = 'VIDEOS',
    customerReviews = 'Customer Reviews',
    notifyMePopUp = 'Notify Me',
    notifyTxt = 'Notify',
    sms = 'SMS',
    emailLabel = 'Email',
    donnotMissOut = "Don't miss out!",
    limitedTimeOffer = 'is now available at a lower price. Hurry, limited time offer!',


    //Header
    deliverTo = 'Deliver to',
    headerSearchPlaceholder = 'Search on Saco.sa',
    all = 'All Categories',
    cart = 'Cart',
    cartIconText = 'Cart',
    hello = 'Hello,',
    startHere = 'Start here.',
    newCustomer = 'New customer?',
    choose_your_location = 'Choose your delivery location',
    add_your_location = 'Add your location',
    signIn_to_see_address = 'Log in to see your address',
    delivery_text_info = 'Pick your delivery location from the options below to personalize your shopping journey',
    select_your_city = 'Select your city',
    back_to_top = 'Back to Top',
    main_menu = 'MAIN MENU',
    arabic = 'Arabic',
    english = 'English',
    langDisplayText = 'العربية',
    learn_more = 'Learn More',
    you_are_shopping_on = 'You are shopping on Saco.sa',
    change_country = 'Change country/region.',
    logout = 'Logout',
    locationAccessBlockedErrorMessage = 'Allow location access to add a new address. You can manage this access at any time in your device permission settings.',
    locationUpdatedSuccess = 'Location updated succesfully.',
    locationUpdatedError = 'Unable to update the location please try later.',
    unknownLocation = "SACO hasn't arrived in Current City yet. Please choose another location to explore our shopping experience.",
    unknownLocationMapDrag = "We couldn't find the match for that location. Try pointing for a specific address or landmark nearby.",

    // Cart Page
    deselectAllItems = 'Deselect all items',
    update = 'Update',
    remove = 'Remove',
    removeAll = 'Remove all',
    share = 'Share',
    price = 'Price',
    saveCurrentCart = 'Save current Cart',
    proceedToBuy = 'Proceed to Buy',
    pairWithYourCart = 'Pair with your cart',
    priceDetails = 'Price Details',
    items = 'Items',
    yourItems = 'Your items',
    yourOrderQualifiesFor = 'Your order qualifies for',
    freeShipping = 'FREE Shipping',
    subTotal = 'Subtotal',
    savedForLater = 'Saved for later',
    saveForLater = 'Save for later',
    buyItAgain = 'Buy it again',
    saveCartNameRequired = 'Saved Cart Name is required',
    qty = 'Qty',
    total = 'Total',
    proceedToCart = 'Proceed to Cart',
    specification = 'Specification',
    colors = 'Colors',
    removeFromCart = 'Remove from cart',
    removeCartParagraph = 'Are you sure you want to remove all items from cart?',
    saveChanges = 'Save changes',
    saveCartParagraph = 'Your cart will be moved to Saved Cart list',
    name = 'Name',
    saveCartDescription = 'Write your description here ...',
    saveCart = 'Save Cart',
    cancel = 'Cancel',
    save = 'Save',
    removeCartLineItemParagraph = 'Are you sure you want to remove this items from cart?',

    //SRP 
    results = 'Results',
    search_info_text = 'Check each product page for other buying options. Price and other details may vary based on product size and colour.',
    best_selling = 'Best Selling',
    related_searches = 'Related Searches',
    no_results = 'No results found',
    try_checking_results = 'Try checking your spelling or use more general terms',
    show_more = 'See all',
    show_less = 'See less',

    //SACO Club
    joinNow = 'Join Now',
    readContent = 'I have read and accept the',
    and = 'and',
    termsOfUse = 'terms of use',
    howItWorks = 'How it works',
    membershipLevels = 'Membership levels',
    sacoClub = 'SACO Club',
    joinSacoClub = 'Join SACO Club',
    optIn = 'User Optin Successfull',

    //National Address
    fetchFromMap = 'Fetch your location from map',
    selectFromMap = 'Select from map',
    getAddress = 'Get Address',
    enterShortAddress = 'Enter short Address (as per National Address)',
    invalidShortAddress = 'Please enter valid Short Address',
    enterManually = 'Enter address manually',
    confirmLocation = 'Confirm Location',
    addThisAddress = 'Add this Address',
    join = 'Join',
    redeem = 'Redeem',
    loyaltyCardRegistration = 'Loyalty card registration',
    locationMapError1 = 'Sorry, but we are unable to provide service at your location at this time!',
    locationMapError2 = 'We couldn\'t find a match for that location. Try pointing for a specific address or landmark nearby',

    // account order
    orders = 'Orders',
    buyAgain = 'Buy again',
    subscribeSave = 'Subscribe & save',
    notYetshipped = 'Not yet shipped',
    cancelledOrders = 'Cancelled orders',
    ordersReturns = 'Orders & Returns',
    wishlist = 'Wishlist',
    loginSecurity = 'Login & security',
    addressess = 'Addressess',
    supportTickets = 'Support tickets',
    loyaltyCard = 'Loyalty Card',
    digitalWallet = 'Digital Wallet',
    sacoClubDashboard = 'SACO Club Dashboard',
    shipTo = 'Ship to',
    order = 'Order #',
    color = 'Color:',
    firstName = 'First Name',
    lastName = 'Last Name',
    changeYourName = 'Change your name',
    firstNameRequired = 'First name is required. Name should not exceed 50 characters.',
    lastNameRequired = 'Last name is required. Name should not exceed 50 characters.',
    dateOfBirth = 'Date of birth',
    dateOfBirthFormat = 'yyyy-mm-dd',
    msgDateOfBirth = 'Date of birth is required',
    dateOfBirthRegexError = 'Please enter a valid date in the format YYYY-MM-DD',
    notificationName = 'Name has been updated successfully!',
    notificationDob = 'Date of birth has been updated successfully!',
    notificationPhone = 'Mobile no has been updated successfully!',
    notificationEmail = 'New email address has been updated successfully!',
    notificationPassword = 'Password has been updated successfully!',
    addDateOfBirth = 'Add date of birth',
    add = 'Add',
    howWouldyouRate = 'How would you rate it?',
    writeReview = 'Write review',
    addAddress = 'Add address',
    default = 'Default:',
    addDeliveryinstr = 'Add delivery instructions',
    setAsDefault = 'Set as default',
    yourSavedAddressess = 'Your saved addressess',
    backSavedAddressess = 'Back to saved addressess',
    addNewAddress = 'Add a new address',
    mobileMatch = 'Mobile and Confirm Mobile does not Match',
    editAddress = 'Edit address',
    saveAddress = 'Save address',
    yourName = 'Your name',
    addMobileNumber = ' Mobile Number*',
    confirmMobileNumber = 'Confirm mobile number*',
    address = 'Address',
    defaultAddress = 'Make this my default address',
    myAccount = 'My Account',
    country = 'Country',
    email = 'email',
    primaryPhonenumber = 'Primary mobile number',
    password = 'Password',
    changeMobilenumber = 'Change mobile number',
    oldMobilenumber = 'Old mobile number:',
    isMobileNumber = 'Mobile Number',
    verifyMobilenumber = 'Verify mobile number',
    completeVerification = 'complete this verificationTo continue, complete this verification step. We’ve sent an OTP to the mobile number',
    belowCompleteVerification = '. Please enter it below to complete verification.',
    currentMobilenumber = 'Current mobile number:',
    changeEmailAddress = 'Change email address',
    oldEmailAddress = 'Old email address :',
    verifyEmailAddress = 'Verify email address',
    toContinue = 'To continue, complete this verification step. We’ve sent an OTP to the',
    pleaseEnter = '. Please enter it below to complete verification.',
    useTheFormTxt = 'Use the form below to change the e-mail address for your SACO account. Use the new email address next time you log in or place an order.',
    alreadyRegisterd = 'User already exists',
    changePassword = 'Change password',
    currentPassword = 'Current password',
    completeEmailVerification = 'To continue, complete this verification step. We’ve sent an OTP to the',
    completeEmailVerificationLast = '. Please enter it below to complete verification.',
    currentEmailAddress = 'Current email address',
    removedAddress = 'Address removed successfully.',

    //Saved Carts
    savedCarts = 'Saved Carts',
    id = 'Id',
    dateSaved = 'Date Saved',
    description = 'Description',
    restore = 'restore',
    action = 'Action',
    inStock = 'In Stock',
    outofStock = 'Out Of Stock',

    //Wishlist
    yourWishlist = 'Your wishlist',
    moveToCart = 'Move to Cart',

    //locationPopups
    businessHours = 'Business Hours',
    fiewItems = 'Only few items in stock',
    searchStores = 'Search stores',
    pickYourDeliveryText = 'Pick your delivery location from the options below to personalize your shopping journey',
    moreOptions = 'More options to add address',
    confirm = 'Confirm',
    reviewOrder = 'Review Order',

    //Digital Wallet
    availableBalance = 'Available Balance',
    clicktoEnlargeImage = 'Click to Enlarge Image',
    nextRewards = 'points till your next reward',
    points = 'Points',
    pointsWillExpire = 'points will expire in the next 30 days.',
    status = 'Status',
    pointsBalance = 'Points Balance',
    cashbackBalance = 'Cashback Balance',
    PointsOnHold = 'Points on hold',
    youStillNeed = 'You still need',
    purchaseToReach = 'purchase to reach',
    tier = 'Tier',
    benefits = 'Benefits',
    activity = 'Activity',
    date = 'Date',
    OrderId = 'Order Id',
    expiry = 'Expiry',
    noDataAvailable = 'No Activity Data Available',

    continueAsGuest = 'Continue as Guest',
    confirmEmail = 'Confirm Email',
    otherIssues = 'Other issues with Sign-In',
    cartItemUpdatedSuccessfully = 'Cart item updated successfully',
    allOrders = 'All Orders',
    removeAllCartItems = "Remove All Items from Cart",
    youWillEarn = 'You will earn',
    vatText = 'All prices inclusive of VAT',
    noSavedData = 'No Saved cart Available',

    requestSupport = 'Request Support',
    allTickets = 'All Tickets',
    openTicket = 'Open',
    closeTicket = 'Resolution provided / Closed',
    addNewTicket = 'Add new support ticket',
    subject = 'subject',
    writeYourMessages = 'Write your messages here',
    selectFile = 'Select a file',
    selectCategory = 'Select Category',
    submitBtn = 'Submit',
    chooseFile = 'Choose File',
    fileFormat = 'Supported file Types: ',
    assignTo = 'Assigned to',
    subjectErrorTitle = 'Please enter subject',
    messageErrorMsg = 'Please enter a valid message.',
    fileFormatErrorMsg = 'Please select valid file format',
    categoryErrorMsg = 'Please select a category',
    backToTicket = 'Back to support ticket',
    messages = 'Messages',
    addNewMessage = 'Add new message',
    addMessage = 'Add message',
    giftCardBalance = 'Gift Card Balance',
    enterGiftcardnumber = 'Enter gift card number',
    enterPin = 'Enter PIN',
    checkBalance = 'Check Balance',
    giftcardnumberMsg = 'Gift card number required',
    cardPinMsg = ' Card Pin required',
    balanceOf = 'There is a balance of',
    remainingSar = 'SAR remaining on this gift card',
    dismissBtn = 'Dismiss',
    statusErrorMsg = 'Please select a status',
    charactersRemaining = 'characters remaining',

    assured = 'Assured',
    quality = 'Quality',
    handpicked = 'Handpicked',
    easy = 'Easy',
    return = 'Return',
    you = 'You:',
    seeMore = 'See more',
    seeLess = 'See less',
    tooltipContentLeft = 'You are entitled to',
    tooltipContentRight = 'points for your last purchase that will be transferred to your account after 14 days.',
    homeDelivery = 'Home Delivery',
    storePickup = 'Store Pickup',
    career = 'Career',
    applyForJob = 'Apply for a job',
    fullname = 'Full Name*',
    mobilenumber = 'Mobile Number*',
    nationality = 'Nationality*',
    homeNumber = 'Home Number*',
    countryResidence = 'Country of Residence*',
    Email = 'Email*',
    city = 'City*',
    uploadCV = 'Upload CV*',
    errorFullName = 'Please provide your Full Name',
    errorMobileNumber = 'Please provide your Mobile Number',
    errorNationality = 'Please provide your Nationality',
    errorCountryResidence = 'Please provide your Country of Residence',
    errorEmail = 'Please provide your Email',
    errorCity = 'Please provide your City',
    errorUploadCV = 'Please provide your CV',
    removeProductMessage = 'Product removed from Wishlist',
    lowStock = 'Low Stock',
    estimatedDeliveryDate = 'Estimated Delivery',
    deliveryDate = 'Delivery Date',
    buyAllTogether = 'Buy all together for',
    qtyLbl = 'Qty',
    getDirection = 'Get Direction',
    primaryContactNumber = 'Primary contact number',
    appartment = 'Flat number',
    line1 = 'Street name',
    line2 = 'Street number',
    postalCode = 'Zip code',
    districtId = 'district',
    nameRequired = 'Please enter first name',
    appartmentRequired = 'Please enter Flat no',
    line1Required = 'Please enter Street Name',
    line2Required = 'Please enter Street No',
    townIdRequired = 'Please select City',
    districtIdRequired = 'Please enter district',
    postalCodeRequired = 'Please enter Postal Code',
    postalCodeInvalid = 'Please enter valid Postal Code',
    postalCodeTooLong = 'Postal Code is too long',
    saveAndProceed = 'Save and proceed',
    saveTheChanges = 'Save the changes',

    cityDistError = "We're sorry, but it seems that our service is currently unavailable in your location",
    addressNotAvailable = 'The address is not in the National Address records. Please enter valid address',
    enterSurfaceArea = 'Enter surface / Area',
    quantity = ' Quantity ',
    errorMessage = 'is not deliverable to the selected address, kindly change the address or remove items to place the order',
    deliveryOption = 'Delivery option',
    noAddressContent = 'No Saved Address Available for your Account',
    addNewAddressLabel = 'Add new Address',
    useThisAddress = 'Use this Address',
    chooseDeliveryAddress = 'Choose Delivery Address',
    chooseBillingAddress = 'Choose Billing Address',
    paymentMethod = 'Select a payment method',
    morePaymentMethods = 'Select one or more payment methods',
    selectRedeemPoints = 'Select redeem points',
    couponCodeLabel = 'Add a Coupon code or Promotion code',
    couponCodeIsrequired = 'Coupon Code is required',
    apply = 'Apply',
    youHaveAdded = 'You have added',
    SARCurrency = 'SAR',
    fromYourCouponContaining = 'from your Coupon, containing',
    storeCreditsLabel = 'Use my store credits for this order',
    noLoyaltyPoints = 'You wont earn loyalty points for amount paid using this payment method',
    fromYourStoreCredit = 'from your Store credit',
    loyaltyPointsOrder = 'Use my loyalty points for this order',
    availableLoyaltyPoints = 'Available loyalty points',
    worth = 'worth',
    containing = 'containing',
    loyaltyAmountIsRequired = 'Loyalty Amount is required',
    placeHolderRedeem = 'Please enter the credit points to be redeemed',
    YouHaveApplied = 'You have applied',
    loyaltyPointsEquivalentTo = 'loyalty points equivalent to',
    UseGiftcardforThisOrder = 'Use gift card for this order',
    giftCardPinIsRequired = 'Gift Card Pin is required',
    redeemGiftcardNumber = 'You have redeemd your Gift Card number',
    PayWithQitaf = 'Pay with Qitaf',
    PayWithMeritcard = 'Pay with Merit card',
    MeritCardNumberIsRequired = 'Merit Card Number is required',
    willbeDeductedFromYourcard = 'will be deducted from your card',
    AddAnotherMeritcard = 'Add another Merit Card',
    CreditDebitCard = 'Credit / Debit card',
    PayWithTamara = 'Pay with Tamara',
    PayWithTabby = 'Pay with Tabby',
    tabbyFees = 'Pay in 4. No interest, no fees.',
    SelectedPaymentMethod = 'Selected Payment Method',
    ReviewItemsAndShipping = 'Review items and shipping',
    UpdateAndProceed = 'Update and Proceed',
    UseThisPaymentMethod = 'Use this Payment Method',
    PlaceOrder = 'Place Order',
    Pay = 'Pay',
    OrderSummary = 'Order Summary',
    Coupons = 'Coupons',
    Discount = 'Discount',
    MeritCardDiscount = 'Merit Card Discount',
    StoreCredit = 'Store Credit',
    LoyaltyDiscount = 'Loyalty Discount',
    GiftCardDiscount = 'Gift Card Discount',
    QTAFDiscount = 'Qitaf Discount',
    EstimatedTax = 'Estimated Tax',
    TotalAmount = 'Total Amount',
    RemainingAmount = 'Remaining Amount',
    ToPay = 'To Pay',
    DeliveryAddressUpdatedsuccessfully = 'Delivery address updated successfully',
    DeliveryCharges = 'Delivery Charges',
    ApplyCouponhere = 'Apply Coupon here',
    PaymentOptions = 'Payment options',
    GiftCardPin = 'Gift Card Pin',
    fromyourQitafBalance = 'from your Qitaf Balance',
    phoneNumberIsRequired = 'Mobile Number is required',
    phoneNumberIsNotValid = 'Mobile Number is not valid',
    phoneNumbershouldbe9digits = 'Mobile Number should be 9 digits',
    OneTimePassword = 'One Time Password',
    Entertheamountyouneedtoredeem = 'Enter the amount you need to redeem',
    AmountshouldbeNumber = 'Amount should be Number',
    Youhaveredeemed = 'You have redeemed',
    termsText = 'By placing the order, I am confirming that I have read and agree with the',
    confirmPolicy = 'Please Confirm the Policy statement first to Place the Order.',
    termsConditions = 'Terms & Conditions',
    phoneNumber = 'Phone Number',
    placedIn = 'placed in',
    lastThreeMonths = "It looks like you haven't placed an order in the last three months",
    continueShopping = 'Continue shopping',
    orderPlaced = 'Order Placed',
    ordersHaveShipped = 'Looking for an order? All of your orders have shipped.',
    viewAllOrders = 'View all orders',
    completeOverview = 'Your past 6 months of orders show no cancellations. Access your order history for a complete overview.',
    backToOrders = 'Back to orders',
    rateTheProduct = 'How would you rate the product?',
    youDispatched = 'You can cancel the item before it is dispatched',
    shippingAddress = 'Shipping address',
    orderSummary = 'Order summary',
    viewBreakup = 'View breakup',
    addReview = 'Add review',
    howWouldYouRate = 'How would you rate it?',
    sharePhoto = 'Share a photo',
    shoppersFindImages = 'Shoppers find images more helpful than text alone.',
    chooseCorrectFileFormat = 'Choose correct file format',
    titleYourReview = 'Title your review',
    mostImportantToKnow = "What's most important to know?",
    writeYourReview = 'Write your review',
    importantToKnow = "What’s most important to know?",
    dislike = 'What did you like or dislike? What did you use this product for?',
    reviewAdded = 'Review added',
    problem = 'Problem',
    dateCreated = 'Date Created',
    dateUpdated = 'Date Updated',
    requiredFields = 'Required Fields',
    cardNumber = 'Card Number *',
    emailId = 'Email *',
    TotalTax = 'Total Tax',
    EarnedCoupon = 'Earned Coupon',
    yourOTPwillExpire = 'Your OTP will expire in',
    completeYourAuthentication = 'seconds. Please use it promptly to complete your authentication.',
    unableToAutheticate = 'Unable to authenticate. Please try later.',
    applicationError = 'Error occurred, please try later.',
    subCategory = 'Sub Category',
    ShowmoreBrands = 'Show more Brands',
    ShowlessBrands = 'Show less Brands',
    ShowmoreCategories = 'Show more Categories',
    ShowlessCategories = 'Show less Categories',
    ProductAddedtoWishlist = 'Product Added to Wishlist',
    ProductRemovedfromWishlist = 'Product Removed from Wishlist',
    cardNumberRequired = 'Card Number is Required',
    cardNumberInvalid = 'Card Number is Invalid',
    nameOnCard = 'Name on Card',
    nameIsRequired = 'Name is Required',
    expirationDate = 'Expiration date',
    expiryMonthRequired = 'Expiry Month is Required',
    CVVisRequired = 'CVV is Required',
    submitCard = 'Submit Card',
    splitYourBill = 'Split your bill with no down payment and pay',
    over = 'over',
    payments = 'payments',
    changeCard = 'CHANGE CARD',
    yourCardWillCharged = 'Your card will be charged an amount of',
    moreTitle = 'Products Related to this item',
    writeDeliveryReview = 'Write delivery review',
    writeProductReview = 'Write product review',
    writeDeliveryReviewText = 'Do you want to Review the delivery Experience?',
    ScoreReviewText = 'Delivery Experience',
    writeProductReviewText = 'Do you want to Review the Product?',
    Relevance = 'Relevance',
    accountCreation = 'Account creation successful!',
    updateProfile = 'Update profile',
    gender = 'Gender',
    notAdded = 'not added',
    profileInformation = 'Profile information',
    fieldsLeft = 'fields left',
    completeYourProfile = 'Complete your profile to get 500 loyalty points',
    earned = 'earned!',
    completeYourProfileMsg = 'Complete your profile',
    dateofBirth = 'Date of Birth',
    national = 'Nationality',
    cityOfResidence = 'City Of Residence',
    maritalStatus = 'Marital status',
    anniversaryDate = 'Anniversary date',
    occasionsYouCelebrate = 'Occasions you celebrate',
    incomeRange = 'Income range',
    doItLater = 'Do it Later',
    saveDetails = 'Save Details',
    congratulationsCompleting = 'Congratulations on completing your profile!',
    youEarned = "You've earned",
    readyToRedeem = 'ready to be redeem on your next purchase!',
    enjoyShopping = 'Enjoy Shopping',
    preferredChannel = 'Preferred Communication channel',
    loyaltyPointsError = "You can only redeem the available loyalty points",
    shareyourSiteExperince = "Thank you for shopping with us",
    DeliveryBy = "Delivery by",
    Order = 'Order',
    personalInformation = 'Personal information',
    shareDeliveryExperience = `We're happy you received your order`,
    yourThoughts = 'Your opinion matters, please rate your shopping experience!',
    yourDeliveryThoughts = 'Your opinion matters, please rate our delivery service!',
    whatsWrong = 'Please let us know what went wrong?',
    Gold = 'Gold',
    Silver = 'Silver',
    Bronze = 'Bronze',
    newArrival = 'New Arrivals',
    topRated = 'Top Rated',
    displayRecentPurchaseCount = 'Recent Purchase Count',
    openNow = 'Open Now',
    numberOfStores = 'Number of stores',
    updated = 'Updated',
    getOTP = 'Get OTP',
    enterAmount = 'Enter the Amount',
    burn = 'Burn',
    unlock = 'Unlock',
    simplyUpdating = 'by simply updating your profile.',
    clubMember = 'You are a SACO Club member now!',
    addCards = 'Add credit or debit card',
    newCardHeader = 'SACO accepts all credit and debit cards',
    newCardPolicy = 'Please ensure that your card could be used for online transanctions.',
    knowMore = 'Know more',
    saveCardInfo = 'Save card details for faster checkout',
    recipientFullname = "Recipient's Fullname",
    recipientEmail = "Recipient's Email",
    emailRequired = 'Email is Required',
    validEmailAddress = 'Please enter a valid email address',
    numberRequired = 'Phone Number is required',
    numberNotValid = 'Phone Number is not valid',
    numberMustDigits = 'Phone number must be at least 9 digits',
    payWithSTC = 'Pay with STC',
    paymentFailed = 'Payment Failed!',
    paymentFailedSub1 = "Don't worry! We won't let",
    paymentFailedSub2 = "you go empty handed",
    retryPayment = 'Retry Payment',
    stockUnavailable = 'Stock is unavailable',
    chooseTheAmount = 'Please choose the amount you would like to gift',
    whenYouComplete = 'Get 500 loyalty points when you complete your profile',
    duplicateMobile = 'Mobile number already Exists. Please try with another number.',
    duplicateEmail = 'Email Id already exists. Please try with another Email Id.',
    free = "FREE",
    gotoHomePage = 'Go to homepage',
    pageNotFound = 'The page you are looking for cannot be found!',
    pageNotFoundReasonHead = 'Possible Reasons',
    pageNotFoundReason1 = 'The address may have been typed incorrectly.',
    pageNotFoundReason2 = 'It may be broken or outdated link.',
    onlyforclickandCollect = 'Available only for Click and Pick',
    IDtext = 'ID',
    modelNo = 'Model',
    amazing = 'Amazing',
    veryGood = 'Very Good',
    ok = 'Ok',
    poor = 'Poor',
    veryPoor = 'Very Poor',
    siteExperienceButtonText = 'Share your shopping experience',
    siteExperienceText = 'Thank you for shopping with us. Tell us about your shopping experience and help us improve!',
    billingAddressUpdatedsuccessfully = 'Billing address updated successfully',
    shippingTo = 'Shipping to',
    createNewAccount = "By creating an account, you agree to SACO's Conditions of Use and Privacy Notice.",
    facebook = 'Facebook',
    twitter = 'Twitter',
    linkedIn = 'LinkedIn',
    whatsApp = 'WhatsApp',
    KeywordSuggestion = 'Keyword Suggestion',
    TrendingSearches = 'Trending Searches',
    PopularProducts = 'Popular Products',
    changeLanguage = 'Change Language',
    allText = 'All',
    ShoptheBrand = 'Shop the Brand',
    //Last updated 21st - below need to be updated in excel
    FromtheManufacturer = 'From the Manufacturer',
    reviewSuccess = 'Your review submitted successfully',
    easyReturn = 'Easy return',
    easyCancellation = 'Easy cancellation',
    whyReturning = 'Why are you returning this?',
    selectQuantity = 'Select quantity',
    howMakeRight = 'How can we make it right?',
    refundDigitalWallet = 'Refund to digital wallet',
    //initiatedImmediately = 'Refund will be initiated immediately once the product has been picked up.',
    refundInitiatedImmediately = 'Refund will be initiated immediately',
    //chooseDigitalWallet = 'Why choose digital wallet?',
    //agreeToReturn = 'I agree to return all items in original condition, with price tags or stickers intact, user manual, warranty cards and original accessories in manufacture’s packaging.',
    refundOriginalMode = 'Refund to original payment method',
    confirmYourReturn = 'Confirm your return',
    confirmYourCancellation = 'Confirm your cancellation',
    courierService = 'Your package will be picked up by a courier service. Please return the item and packaging in its original condition to avoid pickup cancellation by courier service.',
    pickupAddress = 'Pick up address',
    refundSummary = 'Refund Summary',
    deliveryFee = 'Delivery fee',
    returnFee = 'Return fee',
    defaultSelectedAddress = 'Riyadh',
    soldBy = 'Sold By',
    SearchHere = 'Search here',
    ClearAll = 'Clear all',
    returnExchangePolicy = 'Return and Exchange policy',
    returningAgree = "By returning this items you agree to SACO's",
    convertYourExistingPoint = 'Want to Convert your existing loyalty card to SACO Club?',	
    existingLoyaltyCardClick = 'If you have an existing loyalty card and want to convert it to SACO Club membership, please click on below.',
    notValidLoyaltyCard = 'Not a valid loyalty card ID',
    reviews = 'reviews',
    fieldLevelMessage1 = 'We adjusted requested quantity as we have only ',
    fieldLevelMessage2 = ' of this product available.',
    globalLevelMessage = 'We’re sorry. You’ve requested more of some products than we have available, and we adjusted the quantities for you. See below the changes.',
    storeaddress = 'Store address',
    DeleteAccount = 'Delete Account',
    deleteAccountText ='Do you want to delete your account?',
    Fast= 'Fast',
    Delivery = 'Delivery',
    married = 'Married',
    searchbyBrand = 'Search by Brand',
    back = 'Back',
    PriceHighestText = 'Price (highest first)',
    instagramLabel = 'Instagram',
    snapchatLabel = 'Snapchat',
    tiktokLabel = 'Tiktok',
    ProductListingPage = 'Product Listing Page ',
    SearchResultsPage = 'Search Results Page',
    noBrandsAvailable = 'No brands available',
    showMoreAddress = 'Show More Address',
    hideMoreAddress = 'Hide More Address',
    selectStore = 'Choose Store',
    spend = 'Spend',
    tabbyFailureOrRejected = 'Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order.',
    tabbyCancelledOrExpried = 'You aborted the payment. Please retry or choose another payment method.',
    allowLocationText = ' Enable location to choose a store',
    loyaltyPointsText = 'Loyalty points can only be used when equal to or greater than 100.',
    loyaltyPointsText1 = 'Shop more to accumulate loyalty points.',
    AssuredQuality = 'Assured Quality', 
    FastDelivery = 'Fast Delivery',
    EasyReturns = 'Easy Returns',
    closed = 'Closed',
    ViewonMap = 'View on Map',
    cookieConsent = 'Cookie Consent',
    accept = 'Accept',
    cookiePolicy = 'This website uses cookies or similar technologies, to enhance your browsing experience and provide personalized recommendations. By continuing to use our website, you agree to our Cookie Policy.',
    sacoIsBetter = 'SACO Is Better On The App',
    purchaseYourItem = 'Browse, Purchase Your Items',
    officialMobileApp = "Through SACO's Official Mobile App!",
    openTxt = 'Open',
    installApp = 'Install App',
    goText = 'Go',
    digitalWalletText ='Use my Digital Wallet for this order',
    size = 'Size',
    YourSearchResultsFor = 'Your Search Results For',
    proceed= 'Proceed',
    couponWarningMessage = 'We are processing your request. Please wait for a moment',
    couponSuccessMessage = 'Coupon redeemed successfully. Please wait a moment for the update',
    GiftCard = ' Buy a gift card ',
}
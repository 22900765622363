import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule, NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsComponent } from './notifications.component';



@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    CommonModule,
    NgbModule,
    NgbToast
  ],
  exports:[
    NotificationsComponent
  ]
})
export class NotificationsModule { }

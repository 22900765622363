import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { CmsConfig, ConfigModule, FeaturesConfig, I18nConfig, OccConfig, provideConfig, SiteContextConfig } from "@spartacus/core";
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { environment } from '../environments/environment';

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [provideConfig(layoutConfig), provideConfig(mediaConfig), ...defaultCmsContentProviders, provideConfig(<OccConfig>{
    backend: {
      occ: {
         baseUrl:environment.occBaseUrl,
        //baseUrl:'https://spartacus-demo.eastus.cloudapp.azure.com:8443/',
      }
    },
    componentsLoading: {
      pageSize: 375,
    },
  }), provideConfig(<SiteContextConfig>{
    context: {
      urlParameters: ['language'],
      currency: ['SAR'],
      language: ['ar', 'en'],
      baseSite: ['sacoStore'],
      },
  }), provideConfig(<I18nConfig>{
    i18n: {
      resources: translations,
      chunks: translationChunksConfig,
      fallbackLang: 'ar'
    },
  }), provideConfig(<FeaturesConfig>{
    features: {
      level: '2211.25.1'
    }
  }),
  provideConfig({
    componentsLoading: {
      pageSize: 375,
    },
  }),
]
})
export class SpartacusConfigurationModule { }

import { Injectable } from '@angular/core';
import { Product, ProductService } from '@spartacus/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { GET_PRODUCT_LIST_WITH_UID, ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST, GET_WISHLIST, PRODUCTS_SUGGESTION, HOME_PRODUCTS_SUGGESTION, GET_WISHLIST_PRODUCTS, SPECIAL_CATEGORY_PRODUCTS, New_Arrival, SPECIAL_CATEGORY, HOME_PAGE_TILES_EXCLUSIVE, HOME_PAGE_TILES_ALL, CATEGORY_CARD_LIST } from './endpointURL';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { SessionCookieService } from './session-cookie.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryService {

  productCodeSubject = new BehaviorSubject<string>('');
  constructor(
    private productService: ProductService,
    public globalService: GlobalService,
    private http: HttpClient,
    private sessionCookieService: SessionCookieService,
    private localStorageService: LocalStorageService
  ) { } 


  getProductDetailsByCode(productCode: string): Observable<any> {
    return this.productService.get(productCode);
  }

  public getProductListByUID(componentUID?: any): Observable<any> {
    return this.http.get<any>(GET_PRODUCT_LIST_WITH_UID.url + componentUID, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public addToWishList(productCode?: any): Observable<any> {
    return this.http.put(ADD_TO_WISHLIST.url + productCode, '')
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public removeFromWishList(productCode?: any): Observable<any> {
    return this.http.delete<any>(REMOVE_FROM_WISHLIST.url + productCode)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public getWishList(): Observable<any> {
    return this.http.get<any>(GET_WISHLIST.url)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public getProductsRecommendation(catCode: any, type?: any , specialCategory?: boolean, facetsValues?: any, currentPage?: any, isCarrousel?: boolean, sorting?: string): Observable<any> {
    let params = new HttpParams();
    let selectedCity: any;

    const unboxId = this.sessionCookieService.getCookieValue("unbxd.userId")
    let  url = specialCategory ? New_Arrival(catCode).url : HOME_PRODUCTS_SUGGESTION(catCode).url ;
    if (facetsValues != null && facetsValues != undefined && facetsValues != "") {
    url = SPECIAL_CATEGORY.url + facetsValues;
    }   
    url = url + ( currentPage ? '&currentPage=' + currentPage: '');
    url = url + ( type ? ('&sort=' + type ): '');
    url = url + '&fields=FULL';
    url = url + ( isCarrousel? '&isCarrousel=true': '')
    
    if (sorting != null || sorting != undefined) {
      params = params.set("sort", sorting)
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // selectedCity = sessionStorage.getItem('SELECTEDCITY') || 3;
    selectedCity = this.localStorageService.getValue('SELECTEDCITY') || 3;
    return this.http.get<any>(url  + '&uid=' + unboxId, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        'SELECTEDCITY': selectedCity
      }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  
  public getHomeTiles(catCode: any, type?: any , specialCategory?: boolean, facetsValues?: any, currentPage?: any, isCarrousel?: boolean): Observable<any> {
    let params = new HttpParams();
    let selectedCity: any;

    const unboxId = this.sessionCookieService.getCookieValue("unbxd.userId")
    let  url = specialCategory ? HOME_PAGE_TILES_EXCLUSIVE(catCode).url : HOME_PAGE_TILES_ALL(catCode).url ;
    if (facetsValues != null && facetsValues != undefined && facetsValues != "") {
    url = SPECIAL_CATEGORY.url + facetsValues;
    }   
    url = url + ( currentPage ? '&currentPage=' + currentPage: '');
    url = url + ( type ? ('&sort=' + type ): '');
    url = url + '&fields=FULL';
    url = url + ( isCarrousel? '&isCarrousel=true': '')
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    // selectedCity = sessionStorage.getItem('SELECTEDCITY') || 3;
    selectedCity = this.localStorageService.getValue('SELECTEDCITY') || 3;
    return this.http.get<any>(url  + '&uid=' + unboxId, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        'SELECTEDCITY': selectedCity
      }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public getHomeProductsRecommendation(catCode: any): Observable<any> {
    const unboxId = this.sessionCookieService.getCookieValue("unbxd.userId")
    const url = SPECIAL_CATEGORY_PRODUCTS(catCode).url;
    return this.http.get<any>(url  + '&uid=' + unboxId)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  
  public getHomeTilesRecommendation(catCode: any): Observable<any> {
    const unboxId = this.sessionCookieService.getCookieValue("unbxd.userId")
    const url = HOME_PAGE_TILES_EXCLUSIVE(catCode).url;
    return this.http.get<any>(url  + '&uid=' + unboxId)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public getWishListWidgetProducts(): Observable<any> {
    return this.http.get<any>(GET_WISHLIST_PRODUCTS.url)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  getCategoryTiles(catCode: any): Observable<any> {
    return this.http.get<any>(CATEGORY_CARD_LIST(catCode).url)
    .pipe(
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomBreadcrumComponent } from './custom-breadcrum.component';



@NgModule({
  declarations: [
    CustomBreadcrumComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[CustomBreadcrumComponent]
})
export class CustomBreadcrumModule { }

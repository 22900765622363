import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AppRoutingModule } from "@spartacus/storefront";
//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';
import { SharedComponentsModule } from "./shared/components/shared-components.module";
import { CustomFooterModule } from "./custom-components/custom-footer/custom-footer.module";
import { CustomLayoutRoutingModule } from "./core/config/custom-layout/custom-layout.module";
import { GlobalMessageComponentModule, MediaModule } from "@spartacus/storefront";

import { CustomHeaderModule } from "./custom-components/custom-header/custom-header.module";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { GlobalErrorHandlerService } from "./core/services/global-error-handler.service";
import { GlobalService } from "./core/services/global.service";
import { SharedEventsService } from "./core/services/shared-events.service";
import { CachingInterceptor } from "./core/interceptors/caching.interceptor";
import { BroadcastChannelService } from "./shared/broadcast-channel.service";
import { CarouselModule } from 'primeng/carousel';
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { NgSelectModule } from '@ng-select/ng-select';
import { CookiePopupComponent } from './core/utils/cookie-popup/cookie-popup.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DeliveryserviceModule } from "./pages/deliveryservice/deliveryservice.module";
import { MaintenancePolicyModule } from "./pages/maintenance-policy/maintenance-policy.module";
import { ReturnServiceModule } from "./pages/return-service/return-service.module";


import { SingleApiInterceptor } from "./core/interceptors/single-api.interceptor";
import { DelayedApiInterceptor } from "./core/interceptors/delayed-api.interceptor";
import { SacoFeatureModule } from "./shared/saco-feature.modules";
import { MiniCartModule } from "@spartacus/cart/base/components/mini-cart";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PageMetaResolver } from "@spartacus/core";
import { CustomPageMetaResolver } from "./core/services/custom-meta.resolver";


@NgModule({
  declarations: [
    AppComponent,
    CookiePopupComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'spartacus-app' }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    SharedComponentsModule,
    CustomFooterModule,
    CustomLayoutRoutingModule,
    MediaModule,
    CustomHeaderModule,
    GlobalMessageComponentModule,
    NgSelectModule,
    CustomLayoutRoutingModule,
    CarouselModule,
    MiniCartModule,
    SacoFeatureModule,
    // HomePageModule,
    //   CustomCreateAccountModule,
    // CustomForgotPasswordModule,
    // ShopTheStoreModule,
    // CategoryLevel1PageModule,
    // CategoryLevel2PageModule,
    // ProductListingPageModule,
    // ProductDetailPageModule,
    // SearchResultsPageModule,
    // ProductsComparisonModule,
    // CartPageModule,
    // SacoClubModule,
    // MyAccountModule,

    // OrderConfirmationModule,
    // CheckoutModule,
    // GiftCardModule,
    // PrivacyPolicyModule,
    // AboutUsModule,
    // LoyaltyProgramModule,
    // TermsConditionsModule,
    // ReturnsExchangeModule,
    // FaqModule,
    // StoreLocatorModule,
    // DeliveryInstallationsModule,
    // ContactUsModule,
    // CareersModule,
    // InvestorRelationsModule,
    // ReportsModule,
    // RegistrationSuccessfulModule,
    // UpdateProfileModule,
    // NewsModule,
    // SalesFinderModule,
    // PagenotfoundModule,
    // BrandsModule,
    // OnedayDeliveryModule,
    // AlrajhiTermsConditionModule,
    // PromoModule,
    // SurveyLinkModule,
    // BacktoschoolModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
  ],
  providers: [
    GlobalErrorHandlerService,
    { provide: HTTP_INTERCEPTORS, useClass: SingleApiInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: DelayedApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },

    // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },

    {
      provide: PageMetaResolver,
      useClass: CustomPageMetaResolver,
      multi: true 
    },

    NgbActiveModal,
    GlobalService,
    SharedEventsService,
    BroadcastChannelService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

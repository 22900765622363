
<div class="container header-mobile-container" *ngIf="isMobile && !isPopupShow" (window:resize)="onResize($event)">
    <div class="row d-flex justify-content-between align-items-center">
        <div class="col-3 pr-0 pl-2">
            <i class="fa-solid fa-xmark close-icon" aria-label="Close" (click)="closeNotification()"></i>
            <span class="mobile-header-logo">
                <img src="../../../favicon.ico" alt="SACO">
            </span>                
        </div>
        <div class="col-6 app-info-txt px-1">
           <p class="m-0">{{langConfig?.sacoIsBetter}}</p>
           <div>{{langConfig?.purchaseYourItem}}</div>
           <div>{{langConfig?.officialMobileApp}}</div>
        </div>
        <div class="col-3 pl-1 pr-2 text-right">
            <!-- <ng-container *ngIf="platform?.ANDROID; else iosCheck">
                <a class="px-1 py-2 cursor-pointer btn btn-primary-header" href="https://play.google.com/store/apps/details?id=sa.saco.mobile" [title]="langConfig?.installApp">{{langConfig?.installApp}}</a>
            </ng-container>                  
            <ng-template #iosCheck>
                <ng-container *ngIf="platform?.IOS; else finalElseBlock">
                    <a class="px-1 py-2 cursor-pointer btn btn-primary-header" href="https://apps.apple.com/us/app/id1532690353" [title]="langConfig?.installApp">{{langConfig?.installApp}}</a>
                </ng-container>
            </ng-template>                  
            <ng-template #finalElseBlock>
                <a class="px-1 py-2 cursor-pointer btn btn-primary-header" href="https://appgallery.huawei.com/app/C103556023" [title]="langConfig?.installApp">{{langConfig?.installApp}}</a>
            </ng-template> -->
            <ng-container *ngIf="platform?.ANDROID; else iosCheck">
                <a class="px-1 py-2 cursor-pointer btn btn-primary-header" (click)="navigateOnAndroid()" [title]="langConfig?.openTxt">{{langConfig?.openTxt}}</a>
            </ng-container>                  
            <ng-template #iosCheck>
                <ng-container *ngIf="platform?.IOS; else finalElseBlock">
                    <a class="px-1 py-2 cursor-pointer btn btn-primary-header" (click)="navigateOniOS()" [title]="langConfig?.installApp">{{langConfig?.installApp}}</a>
                </ng-container>
            </ng-template>                  
            <ng-template #finalElseBlock>
                <a class="px-1 py-2 cursor-pointer btn btn-primary-header" href="https://appgallery.huawei.com/app/C103556023" [title]="langConfig?.installApp">{{langConfig?.installApp}}</a>
            </ng-template>
        </div>
    </div>
</div>
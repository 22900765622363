import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GET_REFFERENCE_PRODUCTS, GET_PRODUCT_DETAILS, PRODUCTS_COMPARISON, GET_PRODUCT_BREADCRUMB, GET_CATEGORY_BREADCRUMB, PRODUCTS_PRICE_DETAILS, PRODUCTS_ADD_PRICE_DROP, PRODUCTS_DELETE_PRICE_DROP, ANONYMOUS_PRODUCTS_ADD_PRICE_DROP, ANONYMOUS_PRODUCTS_DELETE_PRICE_DROP, ANONYMOUS_PRODUCTS_PRICE_DETAILS, GET_BUNDLING_PRODUCT, GET_MIXANDMATCH_BUNDLE, PRODUCT_WIDGET, New_Arrival, SMALL_APPLIANCES, GET_XENO_OTO_PDP_DATA } from './endpointURL';
import { LocalStorageService } from './local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {


  currentWidgetData: any;
  selectedCity: any;
  private ttlStreamReady!: Promise<any>;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    this.loadTTLStreamScript();
   }

  // Check if any value is empty or undefined
  isNullOrEmpty(value: any): boolean {
    return value == null || value == undefined || value == "" || value == " ";
  }

  // product details api
  public productDetails(productCode?: any): Observable<any> {
    const cartId = (this.localStorageService.getValue('isLoggedIn') && !this.isNullOrEmpty(localStorage.getItem("spartacus⚿sacoStore⚿cart")))
      ? JSON.parse(localStorage.getItem("spartacus⚿sacoStore⚿cart") || "")?.active
      : localStorage.getItem('anonymous_cart_guid');
    const apiUrl = GET_PRODUCT_DETAILS(productCode)?.url + '&cartId=' ;
    // + cartId
    this.selectedCity = this.localStorageService.getValue('SELECTEDCITY') || 3;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (this.selectedCity) {
      headers = headers.set('SELECTEDCITY', this.selectedCity);
    }
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json',
        'SELECTEDCITY': this.selectedCity
       }
    });
  }
  // product product BreadCrumb api
  public productBreadCrumb(productCode?: any): Observable<any> {
    const apiUrl = GET_PRODUCT_BREADCRUMB(productCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  
  // Category BreadCrumb api
  public categoryBreadCrumb(productCode?: any): Observable<any> {
    const apiUrl = GET_CATEGORY_BREADCRUMB(productCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Product GET CROSSELLING, UPSELLING, SIMILAR PRODUCTS api
  public crossingProducts(productCode?: any, sellType?: any): Observable<any> {
    const apiUrl = GET_REFFERENCE_PRODUCTS(productCode, sellType)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // Product GET CROSSELLING, UPSELLING, SIMILAR PRODUCTS api
  public productsComparison(productCode?: any): Observable<any> {
    const apiUrl = PRODUCTS_COMPARISON(productCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // Product GET CROSSELLING, UPSELLING, SIMILAR PRODUCTS api
  public getBundlingProduct(productCode?: any): Observable<any> {
    const cartId = (this.localStorageService.getValue('isLoggedIn') && !this.isNullOrEmpty(localStorage.getItem("spartacus⚿sacoStore⚿cart")))
      ? JSON.parse(localStorage.getItem("spartacus⚿sacoStore⚿cart") || "")?.active
      : localStorage.getItem('anonymous_cart_guid');
    const apiUrl = GET_BUNDLING_PRODUCT(productCode)?.url + '&cartId=' ;
    // + cartId
    this.selectedCity = this.localStorageService.getValue('SELECTEDCITY') || 3;
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    if (this.selectedCity) {
      headers = headers.set('SELECTEDCITY', this.selectedCity);
    }
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json',
        'SELECTEDCITY': this.selectedCity
       }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // Product GET MIXANDMATCH_BUNDLE api
  public getMixandMatchBundle(cartId: any, promotionId: any, productCode: any): Observable<any> {
    const apiUrl = GET_MIXANDMATCH_BUNDLE(cartId, promotionId, productCode)?.url;
    return this.http.post<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // GET products Price Drop Details api
  public productsPriceDropDetails(productCode?: any): Observable<any> {
    const apiUrl = PRODUCTS_PRICE_DETAILS(productCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // GET add products Price Drop api
  public productsAddPriceDrop(productCode?: any, channelName?: any): Observable<any> {
    const apiUrl = PRODUCTS_ADD_PRICE_DROP(productCode, channelName)?.url;
    return this.http.post<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // GET add products Price Drop api
  public productsDeletePriceDrop(productCode?: any): Observable<any> {
    const apiUrl = PRODUCTS_DELETE_PRICE_DROP(productCode)?.url;
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // GET anonymous Products Price Drop Details api
  public anonymousProductsPriceDropDetails(guestUID?: any, email?: any, productCode?: any,): Observable<any> {
    const apiUrl = ANONYMOUS_PRODUCTS_PRICE_DETAILS(guestUID, email, productCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })

      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // GET Anonymous products Price Drop api
  public anonymousProductsAddPriceDrop(productCode: any, emailOrGuest?: any, mobile?: any): Observable<any> {
    const apiUrl = ANONYMOUS_PRODUCTS_ADD_PRICE_DROP(productCode, emailOrGuest, mobile)?.url;
    return this.http.post<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // GET add products Price Drop api
  public anonymousDeletePriceDrop(guestUID?: any, email?: any, productCode?: any): Observable<any> {
    const apiUrl = ANONYMOUS_PRODUCTS_DELETE_PRICE_DROP(guestUID, email, productCode)?.url;
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // GET Product Widget API
  public pdpWidgetAPI(data: any): Observable<any> {
    const cartType = this.localStorageService.getValue('isLoggedIn') ? 'current' : 'anonymous';
    const apiUrl = PRODUCT_WIDGET(cartType)?.url;
    return this.http.post<any>(apiUrl, data, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // GET Product Widget API
  public newArrival(catCode: any): Observable<any> {
    const apiUrl = New_Arrival(catCode)?.url + '&fields=FULL';
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  
  public smallAppliances(catCode: any): Observable<any> {
    const apiUrl = SMALL_APPLIANCES(catCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  setWidgetData(data: any): void {
    this.currentWidgetData = data;
  }

  getWidgetData(): any {
    return this.currentWidgetData;
  }

  getXenoAndOtoPDPData(productCode:any){
    const cartId = (this.localStorageService.getValue('isLoggedIn') && !this.isNullOrEmpty(localStorage.getItem("spartacus⚿sacoStore⚿cart")))
      ? JSON.parse(localStorage.getItem("spartacus⚿sacoStore⚿cart") || "")?.active
      : localStorage.getItem('anonymous_cart_guid');
    const apiUrl = GET_XENO_OTO_PDP_DATA(productCode, cartId)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 
        'Content-Type': 'application/json',
        'SELECTEDCITY':  this.localStorageService.getValue('SELECTEDCITY') || 3,
      }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  loadTTLStreamScript() {
    this.ttlStreamReady = new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://content.24ttl.stream/widget.js';
      script.async = true;
      script.onload = () => {
        const ttlStream = new (window as any).TTLStream({});
        resolve(ttlStream);
      };
      document.body.appendChild(script);
    });
  }
   // Expose TTLStreamReady Promise
   loadTTLStream(): Promise<any> {
    return this.ttlStreamReady;
  }
}

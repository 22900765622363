import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomNavigationModule } from './custom-navigation/custom-navigation.module';
import { CustomLanguageSelectionModule } from './custom-language-selection/custom-language-selection.module';
import { CustomLocationModule } from './custom-location/custom-location.module';
import { CustomMiniCartModule } from './custom-minicart/custom-minicart.module';
import { CustomSearcModule } from './custom-search/custom-search.module';
import { CustomAccountModule } from './custom-account/custom-account.module';
import { LocationSelectionPopupModule } from './custom-location/location-selection-popup/location-selection-popup.module';
import { HeaderService } from 'src/app/core/services/header.service';
import { CustomHeaderComponent } from './custom-header.component';
import { CmsConfig, provideDefaultConfig } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { HeaderAppNotificationComponent } from './header-app-notification/header-app-notification.component';


@NgModule({
  declarations: [
    CustomHeaderComponent,
    HeaderAppNotificationComponent,
  ],
  imports: [
    CommonModule,
    CustomNavigationModule,
    CustomLanguageSelectionModule,
    CustomLocationModule,
    CustomMiniCartModule,
    PageSlotModule,
    CustomSearcModule,
    CustomAccountModule,
    LocationSelectionPopupModule,
    NgbToast
  ],
  exports: [
    CustomHeaderComponent
  ],
  providers: [
    HeaderService,
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CustomHeaderComponent: {
          component: CustomHeaderComponent,
        },
      },
    })
  ]
})
export class CustomHeaderModule { }

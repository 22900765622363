import { AfterContentChecked, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LanguageService } from '@spartacus/core';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { CartService } from 'src/app/core/services/cart.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from 'src/app/core/services/global.service';


@Component({
  selector: 'app-custom-minicart',
  templateUrl: './custom-minicart.component.html',
  styleUrls: ['./custom-minicart.component.scss']
})
export class CustomMiniCartComponent implements OnInit, OnChanges, AfterContentChecked {
  cartListLangConfig: any = EnglishEnum;
  miniCartData: any = [];
  miniCartTotalItems: any;
  miniCartTotalPrice: any;
  cartId: any;
  showDropdown = false;
  showWaitCursor = new BehaviorSubject<boolean>(false);
  currencyResponse: any;
  constructor(
    private languageService: LanguageService,
    private cd: ChangeDetectorRef,
    private cartservice: CartService,
    private localStorageService: LocalStorageService,
    public checkoutService: CheckoutService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.cartListLangConfig = ArabicEnum;
      }
    })
    this.manageBuyNow();
    this.cartservice.cartSubject.subscribe((isLoad: boolean) => {
      if (isLoad)
        this.getMiniCartList();
    })
    this.globalService.getCurrency().subscribe(data => {
      this.currencyResponse = data;
    })
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  ngAfterContentChecked(): void {
    this.cd.detectChanges();
    this.cd.markForCheck();
  }
  manageBuyNow() {
    if (this.localStorageService.getValue('isLoggedIn') && !this.isNullOrEmpty(this.localStorageService.getValue('buyNowCartId'))) {
      this.checkoutService.manageBuyNow().subscribe(() => {
        this.localStorageService.removeValue('buyNowCartId')
      }, () => {
        console.warn('Error in managing buy')
      });
    }
  }

  getProductImage(data: any) {
    let imageUrl;
    imageUrl = data?.images?.filter((img: any) => img.format == "thumbnail");
    return imageUrl;
  }

  getMiniCartList() {
    this.cartId = (this.localStorageService.getValue('isLoggedIn') && !this.isNullOrEmpty(localStorage.getItem("spartacus⚿sacoStore⚿cart")))
      ? JSON.parse(localStorage.getItem("spartacus⚿sacoStore⚿cart") || "")?.active : '';
    this.cartId =  this.cartId? this.cartId: this.isNullOrEmpty (this.localStorageService.getValue('isLoggedIn'))
    ? localStorage.getItem('anonymous_cart_guid') : '';
    if (!this.isNullOrEmpty(this.cartId)) {
      this.getCartData(this.cartId);
    } else if (this.localStorageService.getValue('isLoggedIn')) {
      this.cartservice.createCartId().subscribe((response) => {
        if (response && response?.carts && response?.carts.length > 0) {
          // this.cartId = response?.carts[0]?.code;

          const cart = response?.carts.find((obj: any) => !obj.hasOwnProperty('saveTime'));
          const newCartId = { active: cart?.code };
          this.cartId = cart.code;
          localStorage.setItem("spartacus⚿sacoStore⚿cart", JSON.stringify(newCartId));
          //console.log(cart)
          localStorage.setItem("new_cart_guid", cart.guid);
          this.getCartData(this.cartId);
        }
      })
    }
  }
  getCartData(cartId: any) {
    const cartType: any = this.localStorageService.getValue('isLoggedIn') ? 'current' : 'anonymous';
    this.cartservice.getCartList(cartType, cartId).subscribe((data: any) => {
      if (data && data !== undefined && data !== null && data.entries) {
        this.miniCartData = data?.entries;
        this.miniCartTotalItems = data?.totalUnitCount;
        this.miniCartTotalPrice = data?.totalPrice?.value;
        this.cd.detectChanges();
        this.cd.markForCheck();
        const oldCartId = localStorage.getItem('anonymous_cart_guid')
        const toMergeCartGuid = data.guid || localStorage.getItem("new_cart_guid");
        if (this.localStorageService.getValue('isLoggedIn') && !this.isNullOrEmpty(oldCartId) && !this.isNullOrEmpty(toMergeCartGuid)) {
          this.cartservice.mergeCart(toMergeCartGuid, oldCartId).subscribe((data) => {
            // console.log(data);
            localStorage.removeItem('anonymous_cart_guid');
            if (data && data.entries && data.entries.length > 0) {
              this.miniCartData = data?.entries;
              this.miniCartTotalItems = data?.totalUnitCount;
              this.miniCartTotalPrice = data?.totalPrice?.value;
              this.cd.detectChanges();
              this.cd.markForCheck();
            }
          })
        }
      }
    }, (error) => {
      this.showWaitCursor.next(false);
      console.log(error)
    })
  }
  // Check if any value is empty or undefined
  isNullOrEmpty(value: any): boolean {
    return value == null || value == undefined || value == "" || value == " ";
  }

  onHover(event: any): void {
    this.showDropdown = !this.showDropdown;
  }
  formatDecimalSuperscript(n: number) {
    let p;
    if (n % 1 > 0) {
      let g = n.toString();
      let k = g.split('.');
      if (k && k.length > 1) {
        let decimalDigit = (k[1].length == 1) ? k[1] + '0' : k[1];
        p = '<sup>' + decimalDigit + '</sup>'
        return k[0] + p;
      }
      return n;
    }
    return n;
  }
}

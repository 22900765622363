import { NgModule } from '@angular/core';
import { BaseStorefrontModule, MediaConfig } from "@spartacus/storefront";
import { SpartacusConfigurationModule } from './spartacus-configuration.module';
import { SpartacusFeaturesModule } from './spartacus-features.module';
import { provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [
    BaseStorefrontModule,
    SpartacusFeaturesModule,
    SpartacusConfigurationModule
  ],
  providers: [
    provideConfig(<MediaConfig>{
      useLegacyMediaComponent: true,
    })
  ],
  exports: [BaseStorefrontModule]
})
export class SpartacusModule { }

<div class="modal-container location" *ngIf="!showMap">
    <div class="modal-header d-block location-popup-header">
        <div class="p-3 text-center col-12">
            <!-- <img class="site-logo" [src]="siteLogo" alt="SACO" title="SACO" /> -->
            <cx-page-slot position="SiteLogo" class="mx-auto"></cx-page-slot>
        </div>
        <div class="col-12">
            <p class="lead p-0 m-0 text-center"
                id="modal-basic-title">{{langConfig.choose_your_location}}</p>
            <i class="fa-solid fa-xmark close-icon"
                (click)="closeModal()" aria-label="Close"></i>
                <!-- <i *ngIf="isUserLoggedIn" class="fa-solid fa-xmark close-icon"
                (click)="closeModal()" aria-label="Close"></i> -->
        </div>
    </div>
    <div class="modal-body">
        <div class="modal-content">
            <p class="pb-3 border-bottom">{{langConfig.delivery_text_info}}</p>

            <!-- <div class="p-3 unknown-location d-flex mb-4 align-items-center" *ngIf="unknownLocation" >
                <div class="d-flex align-items-top p-0 mr-3">
                    <img src="../../../../../assets/image/info-icon.svg" class="info-icon" alt="Unknown Location" title="Unknown Location" />
                </div>
                <div [innerHTML]="langConfig.unknownLocation"></div>
            </div> -->

            <!--<div class="sign-in-btn">
                    <button class="btn btn-block btn-primary custom-primary-btn">{{langConfig.signIn_to_see_address}}</button>
                </div>

                <div class="bordered-container d-flex">
                    <div class="bordered"></div>
                    <div class="bordered-text d-flex">{{langConfig.or}}</div>
                    <div class="bordered"></div>
                </div>

                <select [(ngModel)]="selectedLocation" class="form-select location-select" aria-label="Select location" (change)="regionSelection()">
                    <option selected>{{langConfig.select_your_city}}</option>
                    <option *ngFor="let item of regions?.regions" [ngValue]="item">{{item?.name}}</option>
                </select> -->

            <div class="ml-1">
                <!-- Commented the below one for further usage once the manual address section is available -->
                <div class="form-check form-group radio-btn ml-0 pl-3 pr-3"
                    [ngClass]="{'pt-3 pb-3 border blue-border': address_manual == true}">
                    <input class="form-check-input radio-input" type="radio"
                        [checked]="address_manual == true"
                        (change)="onAddressChange('address_manual')" />
                    <label class="form-check-label ml-4 pl-2 pb-2">
                        {{ langConfig?.enterManually }}
                    </label>

                    <div class="manualAddress" *ngIf="address_manual">
                        <ng-container
                            *ngTemplateOutlet="AddressInfo"></ng-container>
                        <button [disabled]="!hasCityAndDistrictSelected()"
                            class="btn btn-block btn-primary custom-primary-btn mt-3"
                            (click)="confirmLocation()">{{
                            langConfig?.confirmLocation
                            }}</button>
                    </div>
                </div>
                <!-- Hiding the select from Map option -->
                <!-- <div class="form-check form-group radio-btn ml-0 pl-3 pr-3"
                    [ngClass]="{'pt-3 pb-3 border blue-border': address_map == true}">
                    <input class="form-check-input radio-input" type="radio"
                        [checked]="address_map == true"
                        (change)="onAddressChange('address_map')" />
                    <label class="form-check-label ml-4 pl-2 pb-2">
                        {{ langConfig?.fetchFromMap }}
                    </label>
                    <div
                        *ngIf="address_map && !isLocationAccessBlocked; else locationAccessBlocked">
                        <button (click)="showMapBox()"
                            class="btn btn-block btn-primary my-3">{{
                            langConfig?.selectFromMap }}</button>
                    </div>
                    <ng-template #locationAccessBlocked>
                        <div
                            class="text-warning p-3 location-access-blocked m-3"
                            *ngIf="address_map">
                            {{ langConfig?.locationAccessBlockedErrorMessage }}
                        </div>
                    </ng-template>
                    <div class="manualAddress" *ngIf="hasLocation">
                        <ng-container
                            *ngTemplateOutlet="AddressInfo"></ng-container>
                        <button
                            class="btn btn-block btn-primary custom-primary-btn mt-3"
                            (click)="confirmLocation()">{{
                            langConfig?.confirmLocation
                            }}</button>
                    </div>

                </div> -->
                <div class="form-check form-group radio-btn ml-0 pl-3 pr-3"
                    [ngClass]="{'pt-3 pb-3 border blue-border': address_short_address == true}">
                    <input class="form-check-input radio-input" type="radio"
                        [checked]="address_short_address == true"
                        (change)="onAddressChange('address_short_address')" />
                    <label class="form-check-label ml-4 pl-2 pb-2">
                        {{ langConfig?.enterShortAddress }}
                    </label>

                    <div class="fields" *ngIf="address_short_address">
                        <input class="mr-2 pt-2 pb-2 pl-3 pr-3 col-7"
                            type="text" [(ngModel)]="shortAddress"
                            [placeholder]="langConfig.enterShortAddress"
                            [ngClass]="{ 'invalid': shortAddressError }"
                            (change)="shortAddressError = false;"
                            (click)="shortAddressError = false;" required>
                        <button [disabled]="hasError || !shortAddress"
                            class="btn btn-block btn-primary my-3"
                            (click)="getShortAddress()">{{
                            langConfig?.getAddress }}</button>
                    </div>
                    <div class="text-danger ml-1"
                        *ngIf="shortAddressError">{{ langConfig?.invalidShortAddress }}</div>
                    <div class="manualAddress" *ngIf="hasShortAddress">
                        <ng-container
                            *ngTemplateOutlet="AddressInfo"></ng-container>
                        <button [disabled]="hasError"
                            class="btn btn-block btn-primary custom-primary-btn mt-3"
                            (click)="confirmLocation()">{{
                            langConfig?.confirmLocation
                            }}</button>
                    </div>
                </div>
                <div class="text-danger text-center mt-2"
                    *ngIf="hasCityDistError">{{ langConfig?.cityDistError
                    }}</div>
            </div>
        </div>
    </div>
</div>

<div class="map-box" *ngIf="showMap">
    <!-- <div class="map-errors p-2 d-flex justify-content-center" *ngIf="hasError">
        <div class="alert  custom-alert-message text-left col-8 m-2"
            role="alert">
            <div class="icon-label mr-2">
                <img src="../../../../../assets/image/error-icon.svg">
            </div>
            <div class="text-label ml-2">
                <h4 class="custom-alert-message-header">{{
                    langConfig?.locationMapError1 }}</h4>
                <ul class="custom-alert-message-list">
                    <li>{{ langConfig?.locationMapError2 }}</li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- Hiding the google maps for time being -->
    <!-- <google-map #googleMaps [height]="600" [width]="950"
        [center]="initialCoordinates" (mapClick)="addMarker($event)"
        (mapDrag)="onMapDrag($event)" (mapDragend)="onMapDrag($event, true)">
        <map-marker *ngIf="clickedPosition"
            [position]="clickedPosition"></map-marker>
    </google-map> -->
    <div class="set-location m-2" *ngIf="mapLocation">
        <img src="./assets/image/location-icon.png" alt="Location" *ngIf="!errorMessage">
        <span [ngClass]="{'text-danger' : errorMessage}" [innerHTML]="mapLocation"></span>        
    </div>
    <div class="map-buttons d-flex">
        <button
            class="btn btn-block btn-primary ml-2 my-3"
            (click)="showMapBox()">{{
            langConfig?.cancel
            }}</button>
        <button class="btn btn-block btn-primary custom-primary-btn ml-2  my-3"
            [disabled]="hasError || !mapLocation" (click)="addAddress()">{{
            langConfig?.addThisAddress
            }}</button>
    </div>

</div>

<ng-template #AddressInfo>
    <input class="mr-2" (change)="hasError = false;" *ngIf="!address_manual"
        [(ngModel)]="flatNo" type="text" [placeholder]="langConfig.appartment" />
    <input class="mr-2" (change)="hasError = false;" *ngIf="!address_manual"
        [(ngModel)]="streetNo" type="text" [placeholder]="langConfig.line2"
        [disabled]="!address_manual" />
    <input class="mr-2" (change)="hasError = false;" *ngIf="!address_manual"
        [(ngModel)]="streetName" type="text" [placeholder]="langConfig.line1"
        [disabled]="!address_manual" />

    <ng-container *ngIf="address_manual; else autoAddress">
        <ng-select [(ngModel)]="selectedCity" class="mb-2" [placeholder]="langConfig.city"
            (change)="updateDistricts()">
            <ng-option *ngFor="let city of cities"
                [value]="city?.code">{{city.name}}</ng-option>
        </ng-select>

        <ng-select [(ngModel)]="selectedDistrict" (change)="hasError = false;"
            class="mb-2" [placeholder]="langConfig.districtId">
            <ng-option *ngFor="let district of districts"
                [value]="district?.code">{{district.name}}</ng-option>
        </ng-select>
    </ng-container>
    <ng-template #autoAddress>
        <input class="mr-2" (change)="hasError = false;"
            [(ngModel)]="selectedCityName" type="text" [placeholder]="langConfig.city"
            [disabled]="!address_manual" />
        <input class="mr-2" (change)="hasError = false;"
            [(ngModel)]="selectedDistrictName" type="text"
            [placeholder]="langConfig.districtId" [disabled]="!address_manual" />
    </ng-template>

    <input class=" mr-2" type="text" *ngIf="!address_manual"
        (change)="hasError = false;" [(ngModel)]="zipCode"
        [placeholder]="langConfig.postalCode"
        [disabled]="!address_manual" />
    <div class="address-error text-danger mt-2 note" *ngIf="hasError && !hasCityDistError">*{{
        langConfig?.addressNotAvailable }}. </div>
</ng-template>
<ng-container *ngIf="showToast">
    <app-notifications [showToast]="showToast" [errorMessage]="errorMessage"
        [toastMessage]="toastMessage"></app-notifications>
</ng-container>
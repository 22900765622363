<div class="custom-address-container d-flex location-medium-device" (click)="showAddressPopup()">
    <img src="./assets/image/location-icon-white.svg" class="mr-2" alt="Location">
    
    <div class="delivery d-lg-none d-xl-none d-sm-block d-xs-block  delivery-label hide-small-device">
        <div class="mr-2 d-inline-block">{{langConfig.deliverTo}} {{ userInfo?.firstName }}</div>
        <ng-container *ngIf="isUserLoggedIn else noLogin">
            <div class="d-inline-block">{{headerService.regionSelected}}</div>
        </ng-container>
        <ng-template #noLogin>
            <!-- <div class="d-inline-block delivery-location">{{(guestAddress && guestAddress?.localizedTown) ? guestAddress?.localizedTown : headerService.regionSelected}} ({{langConfig.change}})</div>   -->
            <div class="d-inline-block delivery-location">{{(guestAddress && guestAddress?.localizedTown) ? guestAddress?.localizedTown : headerService.regionSelected}}</div>
        </ng-template>
    </div>
    <div class="delivery d-lg-block d-xl-block d-sm-none d-xs-none show-small-device">
        <span class="delivery-to">{{langConfig.deliverTo}} {{ userInfo?.firstName }}</span>
        <ng-container *ngIf="isUserLoggedIn else noLogin">
            <!-- <span class="delivery-location">{{headerService.regionSelected}} ({{langConfig.change}})</span> -->
            <span class="delivery-location">{{headerService.regionSelected}}</span>
        </ng-container>
        <ng-template #noLogin>
            <span class="delivery-location">{{guestAddress ? guestAddress?.localizedTown : headerService.regionSelected}} ({{langConfig.change}})</span> 
        </ng-template>
    </div>
</div>
<ng-container *ngIf="showToast">
    <app-notifications [showToast]="showToast" [errorMessage]="errorMessage" [toastMessage]="toastMessage"></app-notifications>
</ng-container>

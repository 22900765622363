import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { GET_CART_DETAILS, GET_CART_LIST, UPDATE_CART_ENTRY, REMOVE_CART_ITEM, CART_SIMILAR_PRODUCTS, SAVE_CART, CLEAR_CART, GET_ANONYMOUS_CART_DETAILS, UPDATE_ANONYMOUS_CART_ENTRY, GET_SAVED_CART_List, RESTORE_SAVE_CART, SAVE_CART_ITEM, RESTORE_SAVED_CART_ITEM, RESTORE_SAVED_CART_ITEM_WITHOUT_CART, UPDATE_BUY_NOW_CART_ENTRY, GIFT_CARD_CART, VALIDATE_CART, MERGE_CART, GET_VERIFIED_BIN_DISCOUNT_DATA } from './endpointURL';

import { GlobalService } from './global.service';
import { CacheService } from './cache.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cartSubject = new BehaviorSubject<boolean>(true);
  constructor(
    private http: HttpClient,
    public globalService: GlobalService,
    private cacheService: CacheService,
    private localStorageService: LocalStorageService
  ) { }

  public getCartList(cartType?: any, cartId?: any): Observable<any> {
    return this.http.get(GET_CART_LIST(cartType, cartId).url)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public createCartId(): Observable<any> {
    // const anonymousToken = this.globalService.getToken();
    return this.http
      .get<any>(GET_CART_DETAILS?.url, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  public getCurrentCartDetails(): Observable<any> {
    this.localStorageService.setValue('anonymous', false);
    return this.http
      .get<any>(GET_CART_DETAILS?.url, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  // update update buy Now CartId
  public updateBuyNowCartId(payLoad: any, cartId: any, cartType: any): Observable<any> {
    this.cacheService.requestUpdated();
    return this.http
      .post<any>(UPDATE_BUY_NOW_CART_ENTRY(cartId, cartType)?.url, payLoad, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  // update cart entry
  public updateCartEntry(payLoad: any, cartId: any, cartType: any): Observable<any> {
    this.cacheService.requestUpdated();
    return this.http
      .post<any>(UPDATE_CART_ENTRY(cartType, cartId)?.url, payLoad, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  // update cart entry
  public giftCardCart(payLoad: any, cartId: any, cartType: any): Observable<any> {
    this.cacheService.requestUpdated();
    return this.http
      .post<any>(GIFT_CARD_CART(cartType, cartId)?.url, payLoad, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  // Anonymousccreate new cart
  public createAnonymousCartId(): Observable<any> {
    this.localStorageService.setValue("anonymous", true);
    return this.http
      .post<any>(GET_ANONYMOUS_CART_DETAILS?.url, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  // Anonymous update cart entry
  public updateAnonymousCartEntry(payLoad: any, cartId: any): Observable<any> {
    return this.http
      .post<any>(UPDATE_BUY_NOW_CART_ENTRY(cartId, 'anonymous')?.url, payLoad, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  // remove cart Item

  public removeCartItem(cartType: any, cartId: any, entryNumber: any): Observable<any> {
    this.cacheService.requestUpdated();
    return this.http.delete(REMOVE_CART_ITEM(cartType, cartId, entryNumber)?.url)
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  // update cart Item
  public updateCartItem(cartType: any, cartId: any, quantity: any, entryNumber: any, deliveryPointOfService?: any): Observable<any> {
    let payLoad: any = {
      "quantity": quantity
    };
    if (deliveryPointOfService) {
      // payLoad.deliveryPointOfService.name = deliveryPointOfService;
      payLoad = {
        "quantity": quantity,
        deliveryPointOfService: {
          name: deliveryPointOfService
        }
      }
    }
    this.cacheService.requestUpdated();
    return this.http.put(REMOVE_CART_ITEM(cartType, cartId, entryNumber)?.url, payLoad)
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  // cart history products
  public cartHistoryProducts(componentName: string, catCode: string): Observable<any> {
    return this.http.get(CART_SIMILAR_PRODUCTS(componentName, catCode)?.url)
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  public saveCart(cartId: any, savedCartData: any): Observable<any> {
    this.cacheService.requestUpdated();
    const url = SAVE_CART(cartId)?.url + '?CartName=' + savedCartData.name + '&saveCartDescription=' + savedCartData.description;
    return this.http.patch(url, savedCartData)
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }


  public clearCart(cartType: any, cartId: any): Observable<any> {
    this.cacheService.requestUpdated();
    const url = CLEAR_CART(cartType, cartId)?.url;
    return this.http.delete(url)
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  public getSavedCartList(cartType: any,): Observable<any> {
    // const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globalService.getToken()}`);
    return this.http.get<any>(GET_SAVED_CART_List(cartType)?.url)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public restorSaveCart(cartId: any): Observable<any> {
    const url = RESTORE_SAVE_CART(cartId)?.url;
    return this.http.patch(url, {})
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  public saveCartItem(cartType: any, savecartId: any, entryNumber: any, cartId: any, saveTocart: boolean): Observable<any> {
    const url = saveTocart ? SAVE_CART_ITEM(cartType, savecartId, entryNumber, cartId)?.url : RESTORE_SAVED_CART_ITEM(cartType, savecartId, entryNumber, cartId)?.url;
    return this.http.post(url, {})
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  // RESTORE_SAVED_CART_ITEM_WITHOUT_CART
  public restoreItemWithoutCart(cartType: any, savecartId: any, entryNumber: any): Observable<any> {
    const url = RESTORE_SAVED_CART_ITEM_WITHOUT_CART(cartType, savecartId, entryNumber)?.url;
    return this.http.post(url, {})
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  // Validate Cart
  public validateCart(cartId: any, refreshCart: boolean): Observable<any> {
    const userType = this.localStorageService.getValue('isLoggedIn') ? 'current' : 'anonymous';
    const url = VALIDATE_CART(cartId, refreshCart,userType)?.url;
    return this.http.get(url, {})
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  // Validate Cart
  public mergeCart(toMergeCartGuid: any, oldCartId: any): Observable<any> {
    const url = MERGE_CART?.url + '?oldCartId=' + oldCartId + '&toMergeCartGuid=' + toMergeCartGuid + '&fields=FULL';
    return this.http.post(url, {})
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }


  //Verify Bin Discount -
  getVerifyBinDiscount(cartId?: any): Observable<any> {
    this.cacheService.requestUpdated();
    return this.http
      .post<any>(
        GET_VERIFIED_BIN_DISCOUNT_DATA(cartId)?.url,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .pipe(
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}

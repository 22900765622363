<section class="custom-header" id="customHeader" *ngIf="!isLoginPage">
    <app-header-app-notification></app-header-app-notification>
    <div class="d-flex header-box">
        <div class="container py-3 p-1">
            <div class="d-flex header-top-container pb-1">
                <!-- <div class="d-lg-none mr-2" *ngIf="isMobile">
                    <cx-page-slot position="NavigationBar"></cx-page-slot>
                </div> -->
                <div class="site-logo mr-4">
                    <cx-page-slot position="SiteLogo"></cx-page-slot>
                </div>
                <div class="search-box-container  col">
                    <cx-page-slot position="SearchBox"></cx-page-slot>
                </div>
                <!-- <div 
                    class="custom-header-location mr-4 mt-1 d-sm-none d-xs-none d-lg-block d-xl-block"  *ngIf="!isMobile">
                    <app-custom-location></app-custom-location>
                </div> -->
            </div>

            <div
                class="custom-header-location mt-2 mt-md-0 mt-xs-0 d-sm-flex d-xs-flex d-lg-none d-xl-none mb-2">
                <div class="user-info pl-1 mt-1">
                    <span class="d-inline-flex">
                        {{ langConfig?.helloTxt }}&nbsp;
                    </span>
                    <a class="link d-inline-flex"
                        href="/account?pagename=update-profile"
                        *ngIf="userInfo?.firstName; else loginInfo">
                        {{ userInfo?.firstName }}
                    </a>
                    <ng-template #loginInfo>
                        <a class="link" href="/login">
                            {{ langConfig.signIn
                            }}
                        </a>
                    </ng-template>
                </div>
                <!-- <app-custom-location  *ngIf="isMobile"></app-custom-location> -->
            </div>
        </div>
    </div>
    <div
        class="navigation-links m-0 py-2 d-sm-none d-xs-none d-lg-block d-xl-block navbar-light header-top-container">
        <div class="container p-0">
            <cx-page-slot position="NavigationBar"></cx-page-slot>
        </div>
    </div>
</section>

<ngb-toast *ngIf="showToast" [autohide]="true" [delay]="2000">
    <img src="../../../../src/assets/image/Invited.svg" [alt]="langConfig?.locationUpdatedSuccess" /> {{ langConfig?.locationUpdatedSuccess }}
    <i class="icon-close toast-close" aria-label="Close"
        (click)="closeToast()"></i>
</ngb-toast>
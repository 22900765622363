import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GET_PRODUCT_LIST, GET_SUB_CATEGORY_INFO, GET_RELATED_SEARCHES, UNBX_SUGGESTION, UNBX_PRODUC_CUSTOM_DATA, GET_SUB_CATEGORY_INFO_CLP } from './endpointURL';
import { HeaderService } from './header.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  subject = new BehaviorSubject<any>(null);
  clearHeaderSearchQuery = new BehaviorSubject<any>(false);
  widgetUpdated = new BehaviorSubject<any>(false);

  constructor(
    private http: HttpClient,
    private headerService: HeaderService,
    private localStorageService: LocalStorageService
  ) { }

  public categoryAPI(categoryCode: any) { 
    const apiUrl = GET_SUB_CATEGORY_INFO(categoryCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public categoryAPICLP(categoryCode: any, isFullField? : boolean) {
    const apiUrl = isFullField ? (GET_SUB_CATEGORY_INFO_CLP(categoryCode)?.url + '&fields=FULL') : GET_SUB_CATEGORY_INFO_CLP(categoryCode)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  


  //categoryCode = category selected
  //facetsValues = search query typed by user
  //isCategoryAndFreeTextSearch = if true then add categoryCode + facetsValues both in the search param
  //noOfResults = results required in query
  public getCategorySearchList(categoryCode?: any, facetsValues?: any, isCategoryAndFreeTextSearch?: boolean, noOfResults?: number, uid?: any, currentPage?: number, sorting?: string): Observable<any> {
    let params = new HttpParams();
    let selectedCity: any;


    if (isCategoryAndFreeTextSearch) {
      let queryParam
      if (facetsValues != null && facetsValues != undefined && facetsValues != "") {
        queryParam = facetsValues // categoryCode != undefined ? facetsValues + ':allCategories:' + categoryCode : facetsValues;
      } else {
        queryParam = categoryCode != undefined ? facetsValues + ':relevance:allCategories:' + categoryCode : facetsValues;
      }
      params = params.set("query", queryParam)
    }
    else {
      if (facetsValues != null && facetsValues != undefined && facetsValues != "") {
        // console.log(facetsValues)
        params = params.set("query", facetsValues)
      } else {
        params = params.set("query", ':relevance:allCategories:' + categoryCode)
      }
    }

    params = params.set("currentPage", currentPage ? currentPage : 0);
    params = params.set("pageSize", noOfResults ? noOfResults : 9);
    params = params.set('uid', uid)
    if (sorting != null || sorting != undefined) {
      params = params.set("sort", sorting)
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    selectedCity = this.localStorageService.getValue('SELECTEDCITY') || 3;
    return this.http.get<any>(GET_PRODUCT_LIST.url, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        'SELECTEDCITY': selectedCity
      }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public unbxSuggestion(query: any, uid: any) {
    const url = UNBX_SUGGESTION.url + query + '&uid=' + uid;
    return this.http.get<any>(url, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // public getRelatedSearchesAPI(term: string, numberOfResults: number) {
  //   const apiUrl = UNBX_SUGGESTION.url;
  //   let params = new HttpParams();
  //   params = params.set("term", term)
  //   params = params.set("max", numberOfResults)
  //   return this.http.get<any>(apiUrl, {
  //     params: params,
  //     headers: { 'Content-Type': 'application/json' }
  //   })
  //     .pipe(
  //       catchError(errorRes => {
  //         return throwError(errorRes);
  //       })
  //     );
  // }

  public getRelatedSearchesAPI(term: string, uid: any) {
    const apiUrl = UNBX_SUGGESTION.url+term;
    let params = new HttpParams();
    // params = params.set("term", term);
    params = params.set('uid', uid);
    return this.http.get<any>(apiUrl, {
      params: params,
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public productsCustomSearch(payload: any) {
    const apiUrl = UNBX_PRODUC_CUSTOM_DATA.url;
    return this.http.post<any>(apiUrl, payload, {
      headers: { 
        'Content-Type': 'application/json',
        'SELECTEDCITY':  this.localStorageService.getValue('SELECTEDCITY') || 3,
      }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, fromEvent, merge } from 'rxjs';
import { mapTo, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  private localStorageChanges$: Observable<Event> | any;

  private storageSubject: BehaviorSubject<{ [key: string]: any }>;

  constructor() {
    const initialData = this.retrieveDataFromLocalStorage();
    this.storageSubject = new BehaviorSubject<{ [key: string]: any }>(initialData);
    this.localStorageChanges$ = merge(
      fromEvent(window, 'storage'),
      fromEvent(document, 'visibilitychange').pipe(mapTo(undefined))
    ).pipe(startWith(undefined));
  }

  getChanges(): Observable<Event> {
    return this.localStorageChanges$;
  }

  private retrieveDataFromLocalStorage(): { [key: string]: any } {
    const storedData = localStorage.getItem('app_data');
    return storedData ? JSON.parse(storedData) : {};
  }

  private updateLocalStorage(data: { [key: string]: any }): void {
    localStorage.setItem('app_data', JSON.stringify(data));
    this.storageSubject.next(data);
  }

  setValue(key: string, value: any): void {
    const currentData = this.storageSubject.value;
    const newData = { ...currentData, [key]: value };
    this.updateLocalStorage(newData);
  }

  getValue(key: string): any {
    const currentData = this.storageSubject.value;
    return currentData[key];
  }

  removeValue(key: string): void {
    const currentData = this.storageSubject.value;
    delete currentData[key];
    this.updateLocalStorage(currentData);
  }

  clearStorage(): void {
    localStorage.removeItem('app_data');
    this.storageSubject.next({});
  }

  getStorageChanges(): Observable<{ [key: string]: any }> {
    return this.storageSubject.asObservable();
  }
}

import { Injectable } from '@angular/core';
import { CmsService, PageMetaResolver, PageMetaService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPageMetaResolver extends PageMetaResolver {
  constructor(
    protected cmsService: CmsService,
    protected pageMetaService: PageMetaService
  ) {
    super();
  }
  resolveDescription(): Observable<string> {
    return this.cmsService.getCurrentPage().pipe(
      map(page => page?.description || '')
    );
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BroadcastChannelService {
  private channels: Map<string, BroadcastChannel> = new Map();
  private sharedDataSubjects: Map<string, BehaviorSubject<string>> = new Map();

  constructor() { }

  createChannel(channelName: string, message?: any): void {
    if (!this.channels.has(channelName)) {
      const newChannel = new BroadcastChannel(channelName);
      this.channels.set(channelName, newChannel);

      // Create a BehaviorSubject for each channel
      const newSubject = new BehaviorSubject<string>(message);
      this.sharedDataSubjects.set(channelName, newSubject);

      // Listen for messages from the new channel
      newChannel.onmessage = ((message: any) => {
        const newValue = message?.data;
        this.sharedDataSubjects?.get(channelName)?.next(newValue);
      })

      this.channels?.get(channelName)?.postMessage(message);
    } else {
      this.channels?.get(channelName)?.postMessage(message);
      this.sharedDataSubjects?.get(channelName)?.next(message);
    }
  }

  updateMesage(channelName: string, message: any): void {

  }

  getSharedDataObservable(channelName: string): BehaviorSubject<string> | undefined {
    // Return the BehaviorSubject for the specified channel
    return this.sharedDataSubjects?.get(channelName);
  }

  updateSharedData(channelName: string, newValue: any): void {
    // Broadcast the data to the specified channel
    if (this.channels.has(channelName)) {
      // console.log('Channel Avialable with message : ', newValue);
      this.channels?.get(channelName)?.postMessage(newValue);
    }
  }
}

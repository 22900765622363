import { Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
@Component({
  selector: 'app-custom-footer',
  templateUrl: './custom-footer.component.html',
  styleUrls: ['./custom-footer.component.scss']
})
export class CustomFooterComponent implements OnInit {
footerCopyright:any;
footerLinks:any;
socialIcon:any
footerLangConfig:any;
socialLinks: any[] = [];
guestUserLogin: boolean = false;
  constructor(private cmsService: CmsService) { 
    let lang = localStorage.getItem('spartacus⚿⚿language') ? localStorage.getItem('spartacus⚿⚿language') : 'en'
    this.footerLangConfig = (lang == '"ar"') ? ArabicEnum : EnglishEnum;
  }
  
  ngOnInit(): void {
    this.cmsService.getComponentData('FooterCopyrightParagraphComponent').subscribe((data: any) => {
      this.footerCopyright=data?.content;
    })
    this.guestUserLogin = (localStorage.getItem('guestUserLogin') == 'true');
  }
  backtoTop(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

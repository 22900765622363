import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomProductFacetsComponent } from './custom-product-facets.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { RatingsModule } from '../ratings/ratings.module';
import { SortPipe } from '../../pipes/sort.pipe';

import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [
    CustomProductFacetsComponent,
    SortPipe
  ],
  imports: [
    CommonModule,
    RatingsModule,
    NgxSliderModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductRefinementComponent: {
          component: CustomProductFacetsComponent
        }
      }
    } as CmsConfig)
  ],
 
  exports:[CustomProductFacetsComponent]
})
export class CustomProductFacetsModule { }

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OngoingRequestsService } from './ongoing-requests.service';

@Injectable()
export class SingleApiInterceptor implements HttpInterceptor {
  constructor(private ongoingRequestsService: OngoingRequestsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only handle requests that have a custom header indicating that they should be debounced
    if (request?.headers?.has('debounce')) {
      // Get the debounce key from the custom header
      const debounceKey = request?.headers.get('debounce') || '';

      // Check if the request is already ongoing
      if (this.ongoingRequestsService['request']?.has(debounceKey)) {
        // If the request is already ongoing, return an empty observable instead of sending the request
        return EMPTY;
      } else {
        // If the request is not already ongoing, add it to the ongoing requests and send it
        const ongoingRequest = this.ongoingRequestsService.addRequest(debounceKey);
        return next.handle(request).pipe(
          mergeMap(() => {
            // When the request completes, complete the ongoing request
            this.ongoingRequestsService.completeRequest(debounceKey);
            return ongoingRequest || EMPTY;
          })
        );
      }
    } else {
      // If the request does not have the custom header, continue with the request as normal
      return next.handle(request);
    }
  }
}
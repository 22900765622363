<div class="lang-selector-container d-sm-none d-xs-none d-lg-block d-xl-block language-container show-small-device medium-device">
  <div class="d-flex">
    <p class="m-2 language-select cursor-pointer" (click)="changeLanguage()"> {{langText}} </p>
    <!-- <div class="col medium-device-position cursor-pointer">
      <p class="mb-0 language-select"> {{langConfig.changeLanguage}} </p>
      <div ngbDropdown class="d-inline-block" (mouseenter)="onHover($event)" (mouseleave)="onHover($event)">
        <p class="m-0" id="dropdownBasic1" ngbDropdownToggle>
          <span *ngIf="languageSelected == 'en'">English</span>
          <span *ngIf="languageSelected == 'ar'">{{langConfig.arabic}}</span>
        </p>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown1-box" [ngClass]="{ show: showDropdown }">
          <div class="nav-arrow">
            <div class="arrow-inner"></div>
          </div>
          <div class="lang-popover1">
            
            <div class="lang ml-0" *ngIf="languageSelected">
                <div class="form-check form-group radio-btn">
                    <input
                      class="form-check-input mr-2"
                      type="radio"
                      [checked]="languageSelected === 'ar'"
                      (change)="onLangChange('ar')"
                    />
                    <label class="form-check-label">
                      {{langConfig.arabic}} - AR
                    </label>
                  </div>
                  <hr/>
                  <div class="form-check form-group radio-btn">
                    <input
                      class="form-check-input mr-2"
                      type="radio"
                      [checked]="languageSelected === 'en'"
                      (change)="onLangChange('en')"
                    />
                    <label class="form-check-label">
                      {{langConfig.english}} - EN
                    </label>
                  </div>
                  <hr/>
                  <div class="shopping"><img [alt]="langConfig.you_are_shopping_on" class="px-2 mb-2" src="./assets/image/ksa-flag.png">{{langConfig.you_are_shopping_on}}</div>
            </div>
        </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
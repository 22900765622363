import { Options } from '@angular-slider/ngx-slider';
import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsService, LanguageService } from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { EnglishEnum } from 'src/app/core/constants/en';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { SessionCookieService } from 'src/app/core/services/session-cookie.service';
import { UnboxdService } from 'src/app/core/services/unbxd.service';
import { CommonUtils } from 'src/app/core/utils/utils';
import { environment } from 'src/app/environments/environment';
import { SortPipe } from '../../pipes/sort.pipe';

@Component({
  selector: 'app-custom-product-facets',
  templateUrl: './custom-product-facets.component.html',
  styleUrls: ['./custom-product-facets.component.scss']
})
export class CustomProductFacetsComponent implements OnInit, OnChanges, AfterContentChecked {

  @Input() customFacetsData: any;
  facetsData: any = [];
  showWaitCursor = new BehaviorSubject<boolean>(false);
  @Output() facetsQuery = new EventEmitter<any>();
  showDetails: any = [];
  selectedFacetsNames: any = [];
  disabled: boolean = false;
  productListLangConfig: any = EnglishEnum;
  queryParamValue: string = '';
  isDesktop = true;
  isMobile = false;
  isTablet = false;
  isUserLoggedIn: boolean = false;
  unboxUserId: any;
  value: number = 0;
  highValue: number = 99999;
  selectedQuery: string = '';
  private previousValue: number;
  private previousHighValue: number;
  isProductListingPage: boolean = false;
  categoryCode: any = '';
  constructor(public cd: ChangeDetectorRef, private languageService: LanguageService, private route: ActivatedRoute,
    private localStorageService: LocalStorageService, private sessionCookieService: SessionCookieService,
    private unbxdService: UnboxdService, private router: Router) {
    this.previousValue = this.value;
    this.previousHighValue = this.highValue;
  }
  ngOnInit(): void {
    this.route.url.subscribe(segments => {
      if (segments.length > 0 && segments[segments.length - 1].path != 'search') {
        this.categoryCode = segments[segments.length - 1].path;
        console.log(this.categoryCode)
        // :relevance:categoryCodes_uFilter:
      }
    })
    this.isUserLoggedIn = this.localStorageService.getValue('isLoggedIn');
    this.unboxUserId = this.sessionCookieService.getCookieValue("unbxd.userId");
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.productListLangConfig = ArabicEnum;
      }
    });
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.queryParamValue = params['q'];
        console.log(this.queryParamValue)
      }
    })
    this.isProductListingPage = window.location.href.includes('productlist');
    this.selectedQuery = sessionStorage.getItem('selectedQuery') || '';
    if (this.isProductListingPage && this.selectedQuery != '') {
      this.selectedFacetsNames = JSON.parse(sessionStorage.getItem('selectedFilters') || '');
    }
    this.showWaitCursor.next(true);
    this.getFacetsData();
    this.onPageRefresh();
  }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes?.['customFacetsData']?.previousValue != changes?.['customFacetsData']?.currentValue) {
      this.disabled = false;
      this.facetsData = this.customFacetsData;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.cd.detectChanges();
      this.cd.markForCheck();
      this.getFacetsData();
    }
  }
  ngAfterContentChecked(): void {

  }
  ngDoCheck() {
    if (this.value !== this.previousValue || this.highValue !== this.previousHighValue) {
      this.enforceValueBoundaries();
      this.previousValue = this.value;
      this.previousHighValue = this.highValue;
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): any {
    if (this.isProductListingPage && this.selectedFacetsNames && this.selectedFacetsNames.length > 0) {
      sessionStorage.setItem('selectedFilters', JSON.stringify(this.selectedFacetsNames))
      sessionStorage.setItem('selectedQuery', this.selectedQuery);
    }
  }
  enforceValueBoundaries() {
    if (this.value >= this.highValue) {
      this.value = this.highValue - 1;
    }
  }
  getFacetsData() {
    setTimeout(() => {
      if (this.customFacetsData && this.customFacetsData != undefined) {
        this.facetsData = JSON.parse(JSON.stringify(this.customFacetsData));
        this.cd.markForCheck();
        for (let i = 0; i < this.customFacetsData?.length; i++) {
          this.showDetails[i] = false;
        }
      }
      this.showWaitCursor.next(false);
    }, 1000);
    this.cd.markForCheck();
  }
  clearAllClick() {
    this.showWaitCursor.next(true);
    this.facetsQuery.emit(this.queryParamValue ? this.queryParamValue : '');
    this.selectedFacetsNames = [];
    this.selectedQuery = '';
    this.cd.markForCheck();
    setTimeout(() => {
      if (this.customFacetsData && this.customFacetsData != undefined) {
        this.facetsData = JSON.parse(JSON.stringify(this.customFacetsData));
      }
      this.showWaitCursor.next(false);
    }, 2000);
  }
  checkValue(event: any, data: any, i: any, j: any) {
    let value = data?.query?.query?.value;
    const checkedFacet = {
      i: i,
      j: j,
      ...data
    }
    //console.log(checkedFacet)
    const name = data.name;
    if (event.currentTarget.checked) {
      this.selectedFacetsNames.push(checkedFacet);
      data.selected = true;
      value = data?.query?.query?.value;
      this.selectedQuery = this.selectedQuery == '' ? data?.query?.query?.value : (this.selectedQuery + data?.query?.removeQuery);
      value = decodeURIComponent(value);
      this.facetsQuery.emit(value);
      this.cd.markForCheck();
    } else {
      const ind = this.selectedFacetsNames.findIndex(
        (element: any) => element.name == name
      );
      if (ind > -1) this.selectedFacetsNames.splice(ind, 1);
      data.selected = false;
      const regex = new RegExp(data?.query?.removeQuery, "g");
      value = value.replace(regex, "");
      this.selectedQuery = value;
      value = decodeURIComponent(value);
      this.facetsQuery.emit(value);
      this.cd.markForCheck();
    }

    if (data?.code && this.facetsData[i].name) {
      this.triggerUnbxdAnalyticsFacetClick(data?.code, this.facetsData[i].name)
    }
  }
  showMore(ind: any) {
    this.showDetails[ind] = !this.showDetails[ind];
  }
  checkedStatus(name: any) {
    const ind = this.selectedFacetsNames.findIndex((facetName: any) => facetName.name == name);
    return (ind > -1);
  }
  removeFacet(facet: any) {
    const index = this.selectedFacetsNames.indexOf(facet);
    if (index !== -1) {
      const regex = new RegExp(facet?.query?.removeQuery, "g");
      this.selectedQuery = this.selectedQuery.replace(regex, "");
      this.facetsQuery.emit(decodeURIComponent(this.selectedQuery))
      this.selectedFacetsNames.splice(index, 1);
      this.selectedQuery = this.selectedFacetsNames.length == 0 ? '' : this.selectedQuery;
    }
  }
  clearFacets() {
    this.selectedFacetsNames = [];
    this.cd.markForCheck();
  }
  disableDoubleClick(event: MouseEvent) {
    event.preventDefault();
  }
  filterBrands(searchTerm: any, ind: number) {
    this.facetsData[ind].values = this.customFacetsData[ind].values.filter((value: any) =>
      value.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.cd.markForCheck();
  }
  onResize(event: any) {
    event.target.innerWidth;
    if (event.target.innerWidth < 768) {
      this.isMobile = true;
      this.isTablet = false;
      this.isDesktop = false;
    }
    else if (event.target.innerWidth < 992) {
      this.isTablet = true;
      this.isDesktop = false;
      this.isMobile = false;
    }
    else {
      this.isDesktop = true;
    }
  }
  onPageRefresh() {
    this.isMobile = CommonUtils.isMobile();
    this.isTablet = CommonUtils.isTablet();
    this.isDesktop = CommonUtils.isDesktop();
  }
  //Search Impression Analytics API:
  triggerUnbxdAnalyticsFacetClick(prodCode: any, prodCategory: any) {
    let visitId = this.unbxdService.getCookieValue('unbxd.visitId') ? this.unbxdService.getCookieValue('unbxd.visitId') : this.unbxdService.getVisitId()
    let facetObject = {
      [prodCategory]: [
        prodCode
      ]
    }
    let param =
    {
      "analyticsRequestData": {
        "query": '',
        "url": environment.UIsiteURl + this.router.url,
        "referrer": document.referrer,
        "visitId": visitId,
        "visitType": this.unbxdService.getCookieValue('unbxd.visit'),
        "facets": [
          facetObject
        ]
      },
      "analyticsParams": [
        {
          "key": "action",
          "value": 'search'
        },
        {
          "key": "uid",
          "value": (this.isUserLoggedIn) ? this.unbxdService.getCookieValue('unbxd.userid') : this.unbxdService.getCookieValue('unbxd.userId')
        }
      ],
      "miscRequestData": {
        "os": "Web"
      }
    }
    if (this.isUserLoggedIn) {
      this.unbxdService.getSearchHitAnalytics(param, false).subscribe((data => {
        // console.log(data, 'Data///')
      }))
    }
    else {
      this.unbxdService.getSearchHitAnalytics(param, true).subscribe((data => {
        // console.log(data, 'Data///')
      }))
    }
  }
  filterPrice() {
    
    let query = this.categoryCode == '' ?  ':relevance:' : this.categoryCode.startsWith('MC') ? ':relevance:categoryCodes_uFilter:'  : ':relevance:exclusiveCatgeoryCodes:' ;
     if ( this.queryParamValue != '') {
      query = this.queryParamValue + query;
    } else {
      query = query + this.categoryCode + ':'; 
    }
    query = this.selectedQuery != '' ? this.selectedQuery + ':' : query;
    this.facetsQuery.emit(query + `price:%5B${this.value}%20TO%20${this.highValue}%5D`);
    this.cd.markForCheck();
  }
  getHighValue = (data: any) => {
    this.highValue = Number(data.code.split('-')[1])
    return Number(this.highValue)
  }
}

import { Injectable, InjectionToken } from '@angular/core';
import { GlobalMessageConfig, GlobalMessageService, GlobalMessageType } from "@spartacus/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { SessionCookieService } from './session-cookie.service';

import { ANONYMOUS_TOKEN, CURRENCY_SYMBOL, MOCK_DATA_API } from './endpointURL';
import { LocalStorageService } from './local-storage.service';

import { environment } from 'src/app/environments/environment';
import { AnalyticsGTMService } from './analytics-gtm.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  $token: any;
  private readonly STORAGE_KEY = 'keepMeLoggedIn';
  private currencyResponse: any;

  constructor(
    private globalMessageService: GlobalMessageService,
    private globalConfig: GlobalMessageConfig,
    private http: HttpClient,
    private sessionCookieService: SessionCookieService,
    private localStorageService: LocalStorageService,
    private gtmService: AnalyticsGTMService
  ) { }

  // To show on fly notfications
  public notificationInfo(message: any): void {
    if (this.globalConfig.globalMessages?.['[GlobalMessage] Information']?.timeout) {
      this.globalConfig.globalMessages['[GlobalMessage] Information'].timeout = 10000;
    }
    this.globalMessageService.add(
      message,
      GlobalMessageType.MSG_TYPE_INFO
    );
  }

  // To hide info fly notfications
  public hideNotificationInfo(): void {
    this.globalMessageService.remove(
      GlobalMessageType.MSG_TYPE_INFO
    );
  }

  // To show on fly notfications
  public notificationError(message: any): void {
    if (this.globalConfig.globalMessages?.['[GlobalMessage] Information']?.timeout) {
      this.globalConfig.globalMessages['[GlobalMessage] Information'].timeout = 20000;
    }
    this.gtmService.onNotificationErrorEventGTM(window.location.href)
    this.globalMessageService.add(
      message,
      GlobalMessageType.MSG_TYPE_ERROR
    );
  }
  // To show on fly notfications
  public notificationWarning(message: any): void {
    if (this.globalConfig.globalMessages?.['[GlobalMessage] Information']?.timeout) {
      this.globalConfig.globalMessages['[GlobalMessage] Information'].timeout = 5000;
    }
    this.globalMessageService.add(
      message,
      GlobalMessageType.MSG_TYPE_WARNING
    );
  }
  public notificationSuccess(message: any): void {
    if (this.globalConfig.globalMessages?.['[GlobalMessage] Information']?.timeout) {
      this.globalConfig.globalMessages['[GlobalMessage] Information'].timeout = 10000;
    }
    this.globalMessageService.add(
      message,
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
  }
  setKeepLoggedIn(value: boolean): void {
    // localStorage.setItem(this.STORAGE_KEY, String(value));
    this.localStorageService.setValue(this.STORAGE_KEY, value);
  }

  getKeepLoggedIn(): boolean {
    return this.localStorageService.getValue(this.STORAGE_KEY) === false || this.localStorageService.getValue(this.STORAGE_KEY) === 'false';
  }

  public getGeneralToken(): Observable<any> {
    return this.http.post<any>(ANONYMOUS_TOKEN.url, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public setToken(accessToken?: any): void {
    // console.log('Has Token Data : ', this.localStorageService.getValue('hasToken'));
    if (!this.localStorageService.getValue('hasToken')) {
      if (accessToken) {
        this.$token = accessToken;
        this.localStorageService.getValue('isLoggedIn')
          ? this.sessionCookieService.setTimelessCookie('current-session-token', accessToken)
          : this.sessionCookieService.setTimelessCookie('general-session-token', accessToken);
        this.localStorageService.setValue('hasToken', true);
      } else if (this.sessionCookieService.getCookieValue('general-session-token') || this.sessionCookieService.getCookieValue('current-session-token')) {
        this.$token = this.localStorageService.getValue('isLoggedIn')
          ? this.sessionCookieService.getCookieValue('current-session-token')
          : this.sessionCookieService.getCookieValue('general-session-token');
        this.localStorageService.setValue('hasToken', true);
      } else if (!this.localStorageService.getValue('isLoggedIn')) {
        this.getGeneralToken().subscribe(
          (tokenData: any) => {
            if (tokenData?.access_token) {
              // console.log('Anonymous Token Data : ', tokenData);
              this.localStorageService.setValue('hasToken', true);
              this.sessionCookieService.setTimelessCookie('general-session-token', tokenData.access_token);
              this.$token = tokenData.access_token;
            }
          },
          (error: any) => {
            this.notificationInfo('Error occured please try later.');
          }
        )
      }
    }
  }

  public getSessionToken(): any {
    return this.localStorageService.getValue('isLoggedIn') ? this.sessionCookieService?.getCookieValue('current-session-token') : this.sessionCookieService.getCookieValue('general-session-token');
  }

  public getToken(): any {
    return this.$token ? this.$token : this.getSessionToken();
  }

  public getMockData(): Observable<any> {
    return this.http.get<any>(MOCK_DATA_API.url, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public getUserIP(): Observable<any> {
    const IPApi = 'https://api.ipify.org?format=json';
    const newApi = 'https://checkip.amazonaws.com/';
    return this.http.get(newApi, { responseType: 'text' })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  getCurrency(): Observable<any> {
    
    this.currencyResponse = {
      "saudiCurrency": null,
      "currencyMediaURL":  environment.occBaseUrl + "/medias/currency-riyal-Symbol.svg?context=bWFzdGVyfGltYWdlc3w5MjN8aW1hZ2Uvc3ZnK3htbHxhRFl4TDJneVpTOHlPREE0TXpNMU16UTFNalUzTkM5amRYSnlaVzVqZVMxeWFYbGhiQzFUZVcxaWIyd3VjM1pufGI3OGFkODFkZGEzYWQ5ZmUxMzRmMzM2ZmYxNmVmZjdjOGQ1MzdlYTNlZGQ4ZGU3OTE5MWUzZWM3YzkyZDA5YTE"
    }
    // this.currencyResponse = {
    //   "saudiCurrency": "SAR",
    //   "currencyMediaURL": null
    // }
    if(this.currencyResponse){
      return of(this.currencyResponse)
    }
    return this.http.post<any>(CURRENCY_SYMBOL.url, {
      headers: { 'Content-Type': 'application/json' },
    })
    .pipe(tap(data=>{console.log("currencyResponse", data)}))
    .pipe(map(data=>{
      if(data.currencyMediaURL) {
        data.currencyMediaURL = environment.occBaseUrl + data.currencyMediaURL;
      }
      return data;
    }))
    .pipe(tap(data => this.currencyResponse = data))
    .pipe(
      catchError(errorRes => {
        return throwError(errorRes);
      })
    );
  }
  

}

import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CategoryNavigationComponent, CmsComponentData, NavigationService } from '@spartacus/storefront';
import { CmsNavigationComponent, LanguageService } from '@spartacus/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { SessionCookieService } from 'src/app/core/services/session-cookie.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { BroadcastChannelService } from 'src/app/shared/broadcast-channel.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { LoginSecurityService } from 'src/app/core/services/login-security.service';
@Component({
  selector: 'app-custom-account',
  templateUrl: './custom-account.component.html',
  styleUrls: ['./custom-account.component.scss'],
  changeDetection:ChangeDetectionStrategy.Default
})
export class CustomAccountComponent extends CategoryNavigationComponent implements OnInit, OnChanges, AfterContentChecked {
  showWaitCursor = new BehaviorSubject<boolean>(true);
  accountsDropdown: any = [];
  serviceSubscribe: Subscription = new Subscription;
  userInfo: any = null;
  isLoading: boolean = false;
  showDropdown = false;
  langConfig: any = EnglishEnum;
  isProfileUpdated: boolean = true;
  guestUserLogin: boolean = false;
  constructor(
    public cd: ChangeDetectorRef,
    service: NavigationService,
    componentData: CmsComponentData<CmsNavigationComponent>,
    private router: Router,
    private userAccountService: UserAccountService,
    private sessionCookieService: SessionCookieService,
    private globalService: GlobalService,
    private broadcastChannelService: BroadcastChannelService,
    private localStorageService: LocalStorageService,
    private languageService: LanguageService,
    private loginSecurityService: LoginSecurityService
  ) {
    super(componentData, service);
  }

  ngOnInit(): void {
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.langConfig = ArabicEnum;
      }
    })
    this.navMenuList();
    this.userInfo = null;
    this.isLoading = false;
    this.loadProfileData();
    this.guestUserLogin = (localStorage.getItem('guestUserLogin') == 'true');
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
 loadProfileData(){
  this.loginSecurityService.isUserProfileUpdated.subscribe((data:any)=>{
      if (data.length) {
        this.userInfo = data;
        this.cd.detectChanges();
        this.cd.markForCheck();
      }
    })
 }
  ngAfterContentChecked(): void {
    if (this.localStorageService.getValue('isLoggedIn')) {
      if (this.broadcastChannelService.getSharedDataObservable('userDetailsInfo')) {
        this.broadcastChannelService.getSharedDataObservable('userDetailsInfo')?.subscribe(
          (userData: any) => {
            if (userData) {
              this.userInfo = userData;
              this.cd.detectChanges();
              this.cd.markForCheck();
            } else if (this.isProfileUpdated) {
              this.userAccountService.updateUserAccountDetails(this.isProfileUpdated);
              this.isProfileUpdated = false;
            }
          }
        );
      } else if (!this.userInfo && !this.isLoading && this.isProfileUpdated) {
        this.userAccountService.updateUserAccountDetails(this.isProfileUpdated);
        this.isProfileUpdated = false;
      }
    }
    this.loadProfileData();
  }

  navMenuList(): void {
    this.serviceSubscribe = this.node$.subscribe((data: any) => {
      this.showWaitCursor.next(true);
      this.accountsDropdown = data;
      this.showWaitCursor.next(false);
      this.cd.markForCheck();
    })
  }

  naviageToLogin(signUp?: any): void {
    localStorage.setItem('screenName', signUp);
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.globalService.getGeneralToken().subscribe(
      (tokenData: any) => {
        this.sessionCookieService.clearSessionDataAndLogout();
        this.isLoading = false;
        this.broadcastChannelService.createChannel('userDetailsInfo', null);
        this.globalService.setToken(tokenData?.access_token);
        location.href = '/';
      });
  }

  onHover(event: any): void {
    this.showDropdown = !this.showDropdown;
    this.cd.detectChanges();
    this.cd.markForCheck();
  }
}
<div class="accounts-lists-container  top-container mr-4 pr-4 pr-xl-0 pr-lg-0 mt-3">
    <div class="row">
        <div class="col">
            <div ngbDropdown class="d-inline-block" (mouseenter)="onHover($event)" (mouseleave)="onHover($event)">
                <button type="button" class="btn d-flex" id="dropdownBasic1" ngbDropdownToggle>
                    <!-- <img src="../../../../assets/image/account_circle.svg" class="account-icon" alt="Account"> -->
                    <div class="label ml-2">
                        <p class="m-0 text-left">{{langConfig.hello}} {{userInfo?.firstName || langConfig.signIn }}</p>
                        <p class="m-0 text-left">{{accountsDropdown?.title}}</p>
                    </div>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown1-box" [ngClass]="{ show: showDropdown }">
                    <div class="lists-dropdown1">
                        <div class="nav-arrow">
                            <div class="arrow-inner"></div>
                          </div>
                    <!-- for non-loggedin user -->
                        <ng-container *ngIf="!userInfo?.firstName">
                            <div class="sign-in-section d-flex justify-content-center my-3">
                                <div class="sign-in-btn">
                                    <button (click)="naviageToLogin('phoneSignIn')" class="btn btn-block btn-primary custom-primary-btn">{{langConfig.signIn}}</button>
                                    <p class="text-center mb-0 mt-2"><span>{{langConfig.newCustomer}} &nbsp;</span><span class="start-here" (click)="naviageToLogin('signUp')">{{langConfig.startHere}}</span></p>
                                </div>
                            </div>
                            <hr/>
                        </ng-container>
                         <!-- for non-loggedin user -->
                
                          <!-- for loggedin user -->
                        <!-- <div *ngIf="userInfo?.firstName" class="sign-in-section d-flex justify-content-center my-3">
                            <div class="sign-in-btn">
                                <button (click)="logout()" class="btn btn-block btn-primary custom-primary-btn">{{langConfig.logout}}</button>
                            </div>
                        </div> -->
                        <!-- for loggedin user -->
                        <div class="list-account-section d-flex" *ngIf="accountsDropdown?.children">
                            <div class="lists col-12">
                                <h5 class="user-title">{{accountsDropdown?.children[0]?.title}}</h5>
                                <ng-container *ngFor="let item of accountsDropdown?.children[0]?.children;">
                                    <ng-container *ngIf="!guestUserLogin">
                                        <li *ngIf="item?.title && item?.title!=langConfig?.signOutLbl" class="user-nav">
                                            <a [href]="userInfo?.firstName ? item?.url : '/login'" class="lists-nav">
                                                {{item?.title}}
                                            </a>
                                        </li>
                                    </ng-container>
                                    <li *ngIf="item?.title && item?.title==langConfig?.signOutLbl && userInfo?.firstName" class="user-nav">
                                        <span class="sign-out lists-nav" (click)="logout()">{{item?.title}}</span>
                                    </li>
                                </ng-container>
                            </div>
                            <!-- <div class="account col-6 custom-border-left">
                                <h5>{{accountsDropdown?.children[1]?.title}}</h5>
                                <li *ngFor="let item of accountsDropdown?.children[1]?.children;">
                                    <a [href]="userInfo?.firstName ? item?.url : '/login'" class="lists-nav">
                                        {{item?.title}}
                                    </a>
                                </li>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        
    </div>


</div>

import {
  Injectable,
  Inject,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import {
  CmsService,
  LanguageService,
  SiteContextParamsService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HrefLangAndCanonicalService {
  private renderer: Renderer2;
  private pageDescription: any = '';
  private pageTitle: any = '';

  constructor(
    private languageService: LanguageService,
    private siteContextParamsService: SiteContextParamsService,
    private router: Router,
    private rendererFactory: RendererFactory2,
    private cmsService: CmsService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private title: Title
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // Initialize both hreflang and canonical tags
  initSeoTags(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd
          )
        )
        .subscribe(() => {
          this.getHreflangTags().subscribe((hreflangs) => {
            this.updateAlternateLink(hreflangs);
          });
          this.getNoFollowTags().subscribe((hrefnofollowuo) => {
            this.noFollowTag(hrefnofollowuo);
          });
          this.updateCanonicalLink(); // Call method to update canonical URL
          this.cmsService.getCurrentPage().subscribe((page) => {
            // Extracting the description from the page data

            this.pageDescription = page?.description;
            this.pageTitle = page?.title;
            if (this.pageDescription) {
              this.updateMetaDescription(this.pageDescription);
              this.setTitle(this.pageTitle);
            }
          });
        });
    }
  }

  public setTitle(newtitle: string): void {
    this.title.setTitle(newtitle);
  }
  getHreflangTags(): Observable<Array<{ lang: string; url: string }>> {
    return this.languageService?.getActive()?.pipe(
      map((activeLanguage: any) => {
        const baseUrl = window.location.origin;
        const currentUrl = this.router?.url
          ?.replace(/ar\//, '')
          ?.replace(/en\//, '');

        const locales: any =
          this.siteContextParamsService?.getValue('languages') ||
          this.siteContextParamsService?.getValue('language') ||
          this.siteContextParamsService?.getValue('lang');
        const hreflangs = [
          { lang: 'en', url: `${baseUrl}/${'en'}/${currentUrl}` },
          { lang: 'ar', url: `${baseUrl}/${'ar'}/${currentUrl}` },
        ];

        // const hreflangs = [locales]?.map((locale: any) => {
        //   return {
        //     lang: locale,
        //     url: `${baseUrl}/${locale}/${currentUrl}`,
        //   };
        // });
        return hreflangs;
      })
    );
  }

  getNoFollowTags(): Observable<Array<{ lang: string; url: string }>> {
    return this.languageService?.getActive()?.pipe(
      map((activeLanguage: any) => {
        const baseUrl = window.location.origin;
        const currentUrl = this.router?.url
          ?.replace(/ar\//, '')
          ?.replace(/en\//, '');
        const nofollowlangs = [
          { lang: 'en', url: `${baseUrl}/${'en'}/${currentUrl}` },
          { lang: 'ar', url: `${baseUrl}/${'ar'}/${currentUrl}` },
        ];
        return nofollowlangs;
      })
    );
  }

  private noFollowTag(
    nofollowlangs: Array<{ lang: string; url: string }>
  ): void {
    const head = this.document.head;
    const orderlink: string[] = nofollowlangs[0].url.split('/');
    console.log(orderlink[orderlink.length - 1]);
    if (orderlink[orderlink.length - 1] == 'orders') {
      nofollowlangs.forEach((element: any) => {
        const linkElement = this.renderer.createElement('link');
        this.renderer.setAttribute(linkElement, 'rel', 'nofollow');
        this.renderer.setAttribute(linkElement, 'href', element.lang);
        this.renderer.setAttribute(linkElement, 'href', element.url);
        this.renderer.appendChild(head, linkElement);
      });
    }
  }
  //<a rel="nofollow" href="https://www.w3schools.com/">W3Schools.com</a>

  private updateAlternateLink(
    hreflangs: Array<{ lang: string; url: string }>
  ): void {
    const head = this.document.head;
    const existingLink = head.querySelector('link[rel="alternate"]');
    if (existingLink) {
      this.renderer.removeChild(head, existingLink);
    }
    if (hreflangs && hreflangs.length > 0) {
      const activeHreflang = hreflangs.find(
        (h: any) =>
          h?.lang ===
          (this.siteContextParamsService?.getValue('languages') ||
            this.siteContextParamsService?.getValue('language') ||
            this.siteContextParamsService?.getValue('lang'))
      );
      hreflangs.forEach((element: any) => {
        const linkElement = this.renderer.createElement('link');
        this.renderer.setAttribute(linkElement, 'rel', 'alternate');
        this.renderer.setAttribute(linkElement, 'hreflang', element.lang);
        this.renderer.setAttribute(linkElement, 'href', element.url);
        this.renderer.appendChild(head, linkElement);
      });

      // if (activeHreflang) {
      //   const linkElement = this.renderer.createElement('link');
      //   this.renderer.setAttribute(linkElement, 'rel', 'alternate');
      //   this.renderer.setAttribute(linkElement,'hreflang',activeHreflang.lang);
      //   this.renderer.setAttribute(linkElement, 'href', activeHreflang.url);
      //   this.renderer.appendChild(head, linkElement);
      // }
    }
  }

  private updateCanonicalLink(): void {
    const head = this.document.head;
    const existingCanonicalLink = head.querySelector('link[rel="canonical"]');

    // Remove any existing canonical link
    if (existingCanonicalLink) {
      this.renderer.removeChild(head, existingCanonicalLink);
    }

    // Create a new canonical URL based on the current page URL
    const canonicalUrl = window.location.origin + '/' + this.router.url;
    // Create and append the new <link rel="canonical"> tag
    const linkElement = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElement, 'rel', 'canonical');
    this.renderer.setAttribute(linkElement, 'href', canonicalUrl);
    this.renderer.appendChild(head, linkElement);
  }

  private updateMetaDescription(desc?: any): void {
    const head = this.document.head;
    const existingMetaDescription = head.querySelector(
      'meta[name="description"]'
    );

    // Remove any existing canonical link
    if (existingMetaDescription) {
      this.renderer.removeChild(head, existingMetaDescription);
    }

    // Create and append the new <link rel="canonical"> tag
    const linkElement = this.renderer.createElement('meta');
    this.renderer.setAttribute(linkElement, 'name', 'description');
    this.renderer.setAttribute(linkElement, 'content', desc);
    this.renderer.appendChild(head, linkElement);
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UNBXD_GET_VISITOR_ANALYTICS, UNBXD_GET_VISITOR_ANALYTICS_ANONYMOUS } from './endpointURL';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UnboxdService {
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getCookieValue(cookieName: string): string {
    return this.cookieService?.get(cookieName);
  }

   getVisitId() {
    var visitId = this.getCookieValue('unbxd.visitId');
    var now = new Date().getTime();
    var expire = new Date(now + 30 * 60000);
    if (!visitId) {
      visitId = 'visitId-' + now + '-' + Math.floor(Math.random() * 100000);
      this.cookieService.set('unbxd.visitId', visitId, expire);
    } else {
      // extend visitId expire time if exists
      // visitId should expire on more than 30min inactivity
      this.cookieService.set('unbxd.visitId', visitId, expire);
    }
    return visitId;
  };

  public getVisitorAnalyticsLoggedInUser(param:any) {
    
    const apiUrl = UNBXD_GET_VISITOR_ANALYTICS?.url;
    return this.http.post<any>(apiUrl, param)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public getVisitorAnalyticsAnonymousInUser(param:any) {
    const cartId = 'anonymous';
    const apiUrl = UNBXD_GET_VISITOR_ANALYTICS_ANONYMOUS(cartId)?.url;
    return this.http.post<any>(apiUrl, param)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }


  public getSearchHitAnalytics(param:any, isAnynomous?:boolean) {
    let apiUrl;
    apiUrl = (isAnynomous) ? UNBXD_GET_VISITOR_ANALYTICS_ANONYMOUS('anonymous')?.url : UNBXD_GET_VISITOR_ANALYTICS?.url;
    return this.http.post<any>(apiUrl, param)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public getSearchImpressionAnalytics(param:any, isAnynomous?:boolean) {
    let apiUrl;
    apiUrl = (isAnynomous) ? UNBXD_GET_VISITOR_ANALYTICS_ANONYMOUS('anonymous')?.url : UNBXD_GET_VISITOR_ANALYTICS?.url;
    return this.http.post<any>(apiUrl, param)
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }



}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OngoingRequestsService {
  [x: string]: any;
  private requests = new Map<string, Subject<any>>();

  addRequest(key: string): Observable<any>  | undefined{
    if (!this.requests.has(key)) {
      this.requests.set(key, new Subject());
    }
    return this.requests.get(key);
  }

  completeRequest(key: string): void {
    if (this.requests.has(key)) {
      this.requests?.get(key)?.complete();
      this.requests.delete(key);
    }
  }
}
import { ChangeDetectorRef, Component, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HeaderService } from 'src/app/core/services/header.service';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { LanguageService, SiteContextConfig } from '@spartacus/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-language-selection',
  templateUrl: './custom-language-selection.component.html',
  styleUrls: ['./custom-language-selection.component.scss']
})
export class CustomLanguageSelectionComponent extends SiteContextConfig implements OnInit, OnChanges {
  constructor(
    private headerService: HeaderService,
    private languageService: LanguageService,
    private renderer2: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {
    super()
   }
  languageSelected: string = 'en';
  languages: any;
  showDropdown = false;
  langConfig: any = EnglishEnum;
  langText: any = 'English'

  ngOnInit(): void {
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.langConfig = ArabicEnum;
        this.languageSelected = 'ar';
        this.langText = this.langConfig.langDisplayText;
        // document.getElementsByName('body').
        this.renderer2.addClass(document.body, this.languageSelected);
      } else {
        this.langConfig = EnglishEnum;        
        this.langText = this.langConfig.langDisplayText;
        this.languageSelected = 'en'
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  changeLanguage(): void {
    this.languageSelected = (this.languageSelected == 'ar') ? 'en' : 'ar';    
    this.languageService.setActive(this.languageSelected); 
    this.cdr.detectChanges();
    this.cdr.markForCheck();    
    location.reload();
  }

  onLangChange(lang: string) {
    this.languageSelected = lang;
    this.languageService.setActive(lang);    
    localStorage.removeItem('reviewPopUpCalled'); //need to check with Saad
    this.cdr.detectChanges();
    this.cdr.markForCheck();
    location.reload();
  }

  onHover(event: any): void {
    this.showDropdown = !this.showDropdown;
  }

}

<div id="cookieNotice" class="cookie-notice" *ngIf="!cookieAccepted">
    <div class="cookie-content">
        <div class="title-wrap">
            <h4>{{cookieLangConfig?.cookieConsent}}</h4>
        </div>
        <div class="content-wrap">
            <div class="msg-wrap">
                <p>{{cookieLangConfig?.cookiePolicy}}</p>
            </div>
            <div class="btn-wrap">
                <button class="btn btn-primary" (click)="acceptCookies(true)">{{cookieLangConfig?.accept}}</button>
            </div>
        </div>
    </div>
</div>
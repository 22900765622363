import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFooterComponent } from './custom-footer.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import { CustomLanguageSelectionModule } from '../custom-header/custom-language-selection/custom-language-selection.module';
import { CustomLanguageSelectionComponent } from '../custom-header/custom-language-selection/custom-language-selection.component';



@NgModule({
  declarations: [
    CustomFooterComponent,
    // CustomLanguageSelectionComponent
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    CustomLanguageSelectionModule,
    CustomLanguageSelectionModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomFooterComponent: {
          component: CustomFooterComponent
        }
      }
    } as CmsConfig)
  ],
  exports:[
    CustomFooterComponent
  ]
})
export class CustomFooterModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomAccountComponent } from './custom-account.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    CustomAccountComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    ConfigModule.withConfig({
      cmsComponents: {
        NavigationComponent: {
          component: CustomAccountComponent
        }
      }
    } as CmsConfig)
  ],
  exports:[
    CustomAccountComponent
  ]
})
export class CustomAccountModule { 
}

import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthService,
  CmsService,
  LanguageService,
  Page,
  WindowRef,
} from '@spartacus/core';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { EnglishEnum } from 'src/app/core/constants/en';
import { AnalyticsGTMService } from 'src/app/core/services/analytics-gtm.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { BroadcastChannelService } from 'src/app/shared/broadcast-channel.service';


@Component({
  selector: 'app-custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
})
export class CustomHeaderComponent implements OnInit, AfterContentChecked, OnChanges, AfterViewInit {

  isMobile: boolean = false;
  isLoggedIn: boolean = false;
  isLoginPage: boolean = false;
  langConfig: any = EnglishEnum;
  userInfo: any = null;
  isLoading: boolean = false;
  isProfileUpdated: boolean = true;
  showToast: boolean = false;
  modalRef: any;
  isGMTEventTriggered: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    private userAccountService: UserAccountService,
    private broadcastChannelService: BroadcastChannelService,
    private GTMService: AnalyticsGTMService
  ) { }

  ngOnInit(): void {

    this.isLoggedIn = this.localStorageService.getValue('isLoggedIn');

    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.langConfig = ArabicEnum;
      }
    })
    if (window.innerWidth <= 1024) {
      this.isMobile = true;
    }
    if (location.href.includes('/login')) {
      this.isLoginPage = true;
    }
    this.cdr.detectChanges();
    this.cdr.markForCheck();
    

  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterContentChecked(): void {
    if (location.href.includes('/login') && !this.localStorageService.getValue('isLoggedIn')) {
      this.isLoginPage = true;
    } else {
      this.isLoginPage = false;
    }

    if (this.localStorageService.getValue('isLoggedIn')) {
      if (this.broadcastChannelService.getSharedDataObservable('userDetailsInfo')) {
        this.broadcastChannelService.getSharedDataObservable('userDetailsInfo')?.subscribe(
          (userData: any) => {
            if (userData) {
              if( !this.userInfo && this.isLoggedIn){
                //Trigger GTM Event on All pages
                this.triggerGTMEvent_userID(userData)
              }
              this.userInfo = userData;
              this.cdr.detectChanges();
              this.cdr.markForCheck();
            } else if (this.isProfileUpdated) {
              this.userAccountService.updateUserAccountDetails(this.isProfileUpdated);
              this.isProfileUpdated = false;
            }
          }
        );
      } else if (!this.userInfo && !this.isLoading && this.isProfileUpdated) {
        this.userAccountService.updateUserAccountDetails(this.isProfileUpdated);
        this.isProfileUpdated = false;
      }
    }

    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    if (location.href.includes('/login') && !this.localStorageService.getValue('isLoggedIn')) {
      this.isLoginPage = true;
    } else {
      this.isLoginPage = false;
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }



  closeToast() {
    this.showToast = false;
    return;
  }

  triggerGTMEvent_userID(userData:any){
    console.log('triggering GTM Event..')
    let user_id:any = userData?.uid;
    let user_email: string = userData?.uid;
    let user_city: string= userData?.defaultAddress?.town;
    let user_country: string= userData?.defaultAddress?.country?.isocode;
    let user_postalCode: string= userData?.defaultAddress?.postalCode
    let user_state:string= userData?.defaultAddress?.localizedDistrict
    let user_type:string= userData?.type;
    this.GTMService.onPageLoadGTM(user_id, user_email, user_city, user_country, user_postalCode, user_state, user_type);
  }

}

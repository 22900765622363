import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { GET_USER_DETAILS, LOGIN, REGISTRATION, GET_LOGIN_WITH_OTP, VERIFY_WITH_OTP, FORGET_PASSWORD_MOBILE, FORGET_PASSWORD_OTP, FORGET_PASSWORD_RESET, SOCIAL_LOGIN, GUEST_USER_LOGIN, LOYALTY_CART_REGISTRATION, ENABLE_GUEST_CHECKOUT } from 'src/app/core/services/endpointURL';
import { BroadcastChannelService } from 'src/app/shared/broadcast-channel.service';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UserAccountService {

  userInfo: any;

  constructor(
    private http: HttpClient,
    private broadcastChannelService: BroadcastChannelService,
    private localStorageService: LocalStorageService
  ) { }

  // Login with credentials
  public loginToAccount(formData?: any): Observable<any> {
    const apiUrl = LOGIN.url + formData.emailOrPhoneNumber + '&password=' + formData.password;
    return this.http.post<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Login with credentials
  public socialAccountLogin(token?: any): Observable<any> {
    const apiUrl = SOCIAL_LOGIN.url + token;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' },
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Create/Register with new account
  public createNewAccount(formData?: any): Observable<any> {
    const apiUrl = REGISTRATION.url;
    return this.http.post<any>(apiUrl, formData, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }


  // Get User Account details
  public getUserAccountDetails(): Observable<any> | undefined {
    const apiUrl = GET_USER_DETAILS.url;
    if (this.localStorageService.getValue('isLoggedIn')) {
      return this.http.get<any>(apiUrl, {
        headers: { 'Content-Type': 'application/json' }
      })
        .pipe(
          catchError(errorRes => {
            localStorage.clear();
            return throwError(errorRes);
          })
        );
    }
    return;
  }


  public updateUserAccountDetails(isProfileUpdated: boolean = false): void {
    if (isProfileUpdated) {
      this.getUserAccountDetails()?.subscribe((userInfo: any) => {
        if (userInfo) {
          this.userInfo = userInfo;
          this.broadcastChannelService.createChannel('userDetailsInfo', userInfo);
          this.localStorageService.setValue('annexOptInStatus', userInfo?.annexOptInStatus);
        }
      }, (error: any) => {
        this.userInfo = null;
        this.broadcastChannelService.createChannel('userDetailsInfo', null);
        localStorage.clear();
      })
    }
  }

  // Login With OTP details
  public getLoginWithOTP(emailOrPhoneNumber?: any): Observable<any> {
    const apiUrl = GET_LOGIN_WITH_OTP.url + emailOrPhoneNumber;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // Verify OTP details
  public verifyOTPdetails(formData?: any): Observable<any> {
    const apiUrl = VERIFY_WITH_OTP(formData)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // forgot Password send mobile no
  public forgotPasswordMobile(mobileNo?: any): Observable<any> {
    const apiUrl = FORGET_PASSWORD_MOBILE(mobileNo).url;
    return this.http.post<any>(apiUrl, mobileNo, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // forgot Password send mobile no and OTP
  public forgotPasswordOTP(otp?: any, uid?: any): Observable<any> {
    const apiUrl = FORGET_PASSWORD_OTP(otp, uid).url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // forgot Password reset
  public forgotPasswordReset(forgetData?: any): Observable<any> {
    const apiUrl = FORGET_PASSWORD_RESET.url;
    return this.http.post<any>(apiUrl, forgetData, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public guestUserLogin(cartId: any, email: string): Observable<any> {
    const apiUrl = GUEST_USER_LOGIN(cartId).url + email;
    return this.http.put<any>(apiUrl, email, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // loyalty Card Registration API
  public loyaltyCardRegistration(cardId: any, mobileNo:any, email:any): Observable<any> {
    const apiUrl = LOYALTY_CART_REGISTRATION(cardId, mobileNo, email).url;
    return this.http.post<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  public enableGuestCheckout(): Observable<any> {
    const apiUrl =  ENABLE_GUEST_CHECKOUT?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  isRequestUpdated: boolean = false;

  constructor(){}

  requestUpdated(): void {
    this.isRequestUpdated = true;
  }
  
  getIsRequestUpdated(): boolean {
    return this.isRequestUpdated;
  }

  requestUpdateDone(): void {
    this.isRequestUpdated = false;
  }
}

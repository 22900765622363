import { Injectable, ErrorHandler, Injector, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
// import { ToastrService } from 'ngx-toastr';

import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private globalService: GlobalService) { }

  handleError(error: any) {
    // const toastrService = this.toastService;
    if (error instanceof HttpErrorResponse) {
      //Backend returns unsuccessful response codes such as 404, 500 etc
      const errors: any = error?.error?.errors | error?.error;
      if (Object.keys(errors)?.length > 0) {
        for (const message of errors?.message) {
          this.globalService.notificationInfo(message?.message + '1');
          console.error('An error occurred: 1', message?.message);
        }
      } else {
        const statusText = Object.keys(errors)?.length > 0 ? errors[0]?.message : errors?.message
        this.globalService.notificationInfo(statusText + '2');
        console.error('An error occurred: ', statusText);
      }
    } else if (error?.message?.indexOf('classList') < 0) {
      //A client-side or network error occurred.	     
      this.globalService.notificationInfo('An error occurred. Please try again later.');
      console.error('An error occurred:', error.message + '3');
    }
  }
} 

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddtocartComponent } from './addtocart.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AddtocartComponent, 
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbToastModule
  ],
  exports:[AddtocartComponent]
})
export class AddtocartModule { }

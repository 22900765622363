import { Injectable } from '@angular/core';
import { CHANGE_USER_DATA, CHANGE_PASSWORD, VERIFY_USER_DATA, CHANGE_MOBILE_EMAIL, GET_OTP_WITH_EMAIL, VERIFY_EMAIL_OTP, GET_LOGIN_WITH_OTP, VERIFY_MOBILE_OTP, DELETE_USER } from './endpointURL';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginSecurityService {
  isUserProfileUpdated = new BehaviorSubject<any>({});
  enableSocialLogin = false;
  constructor(
    private http: HttpClient,
  ) { }


  // Verify user details
  public verifyUserData(mobileNo: any): Observable<any> {
    const apiUrl = VERIFY_USER_DATA(mobileNo)?.url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // get Email OTP
  public getEmailOTP(oldEmail?: any, newEmail?: any): Observable<any> {
    const apiUrl = GET_OTP_WITH_EMAIL(oldEmail, newEmail).url;
    return this.http.post<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // get Verify Email OTP
  public getVerifyEmailOTP(email:any, otp?: any): Observable<any> {
    const apiUrl = VERIFY_EMAIL_OTP(email, otp).url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  // get mobile OTP
  public getMobileOTP(mobile?: any): Observable<any> {
    const apiUrl = GET_LOGIN_WITH_OTP.url + mobile;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // get Verify Mobile OTP
  public getVerifyMobileOTP(otp?: any): Observable<any> {
    const apiUrl = VERIFY_MOBILE_OTP(otp).url;
    return this.http.get<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // change User data API
  public changeUserData(mobileNo?: any): Observable<any> {
    const apiUrl = CHANGE_USER_DATA.url;
    return this.http.patch<any>(apiUrl, mobileNo,{
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // change mobile and email API
  public changeMobileEmail(email?: any, mobileNo?: any, oldPassword?: any): Observable<any> {
    const apiUrl = CHANGE_MOBILE_EMAIL(email, mobileNo, oldPassword).url;
    return this.http.post<any>(apiUrl, mobileNo,{
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  // change Password API
  public changePassword(oldPassword?: any, newPassword?: any): Observable<any> {
    const apiUrl = CHANGE_PASSWORD(oldPassword, newPassword).url;
    return this.http.post<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
  public deleteUser() {
    const apiUrl = DELETE_USER.url;
    return this.http.delete<any>(apiUrl, {
      headers: { 'Content-Type': 'application/json' }
    })
      .pipe(
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }
}

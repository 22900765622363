import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCardComponent } from './custom-card.component';
import { CarouselModule, MediaModule } from '@spartacus/storefront';
import { ConfigModule } from '@spartacus/core';



@NgModule({
  declarations: [
    CustomCardComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomSection1DComponent: {
          component: CustomCardComponent
        },
        CustomSection2AComponent: {
          component: CustomCardComponent
        },
        CustomSection2BComponent: {
          component: CustomCardComponent
        },
        CustomSection6AComponent: {
          component: CustomCardComponent
        },
        CustomSection6CComponent: {
          component: CustomCardComponent
        },
        CustomSection6DComponent: {
          component: CustomCardComponent
        },
        CustomSection6BComponent: {
          component: CustomCardComponent
        },
      }
    })
  ],
  exports:[CustomCardComponent]
})
export class CustomCardModule { }

import { environment } from "src/app/environments/environment";


// occBaseUrl:'https://api.cj8mhwb-saudicomp2-s4-public.model-t.cc.commerce.ondemand.com/',
// baseSiteId:"occ/v2/sacoStore/",

const apiEndpointURL = environment.occBaseUrl + environment.baseSiteId;

export const MOCK_DATA_API = {
    url: '../../../assets/data/home/todaydeal.json'
}

export const REGIONS = {
    url: `${apiEndpointURL}countries/SA/regions?fields=FULL`
};

export const LANGUAGES = {
    url: `${apiEndpointURL}languages`
};

export const ANONYMOUS_TOKEN = {
    url: `${environment.occBaseUrl}authorizationserver/oauth/token?client_id=${environment.clientId}&client_secret=${environment.clientSecret}&grant_type=client_credentials`
};

export const CURRENCY_SYMBOL = {
    url: `${environment.occBaseUrl}occ/v2/sacoStore/currency?currencyCode=SAR`
};

export const LOGIN = {
    url: `${environment.occBaseUrl}authorizationserver/oauth/token?client_id=${environment.clientId}&client_secret=${environment.clientSecret}&grant_type=password&username=`
};

export const SOCIAL_LOGIN = {
    url: `${environment.occBaseUrl}${environment.baseSiteId}social/google/login?client_id=${environment.clientId}&client_secret=${environment.clientSecret}&grant_type=password&credential=`
};

export const REGISTRATION = {
    url: `${environment.occBaseUrl}${environment.baseSiteId}sacoUsers`
};

export const VERIFY_USER_DATA = ((emailOrMobileNo?: any) => {
    return { url: `${environment.occBaseUrl}occ/v2/saco/mobile/otp/verifyuser?data=${emailOrMobileNo}` }
});
export const VERIFY_EMAIL_OTP = ((email?: any, otp?: any) => {
    return { url: `${apiEndpointURL}email/otp/verify?loginId=${email}&otp=${otp}` }
});

export const GET_OTP_WITH_EMAIL = ((oldEmail?: any, newEmail?: any) => {
    return { url: `${apiEndpointURL}email/otp/send?userId=${oldEmail}&newId=${newEmail}` }
});

export const VERIFY_MOBILE_OTP = ((mobileNo?: any) => {
    return { url: `${apiEndpointURL}mobile/otp/verify?otp=${mobileNo}` }
});
export const CHANGE_USER_DATA = {
    url: `${environment.occBaseUrl}occ/v2/saco/sacoUsers/current`
};
export const CHANGE_MOBILE_EMAIL = ((email?: any, mobileNo?: any, oldPassword?: any) => {
    return { url: `${environment.occBaseUrl}occ/v2/saco/sacoUsers/current/login?newLogin=${email}&newPhone=${mobileNo}&password=${oldPassword}` }
});
export const CHANGE_PASSWORD = ((oldPassword?: any, newPassword?: any) => {
    return { url: `${apiEndpointURL}sacoUsers/current/password?old=${oldPassword}&new=${newPassword}` }
});
export const GET_USER_DETAILS = {
    url: `${apiEndpointURL}sacoUsers/current`
};
export const DELETE_USER = {
    url: `${apiEndpointURL}users/current`
};
export const GET_LOGIN_WITH_OTP = {
    url: `${apiEndpointURL}mobile/otp/send?fields=DEFAULT&mobile=`
};
export const VERIFY_WITH_OTP = ((loginData?: any) => {
    return { url: `${apiEndpointURL}mobile/otp/verifyAndLogin?otp=${loginData?.otp}&loginId=${loginData?.emailOrPhoneNumber}&client_id=${environment.clientId}&grant_type=otp` }
});
export const FORGET_PASSWORD_MOBILE = ((mobileNo?: any) => {
    return { url: `${apiEndpointURL}otp/forgetPassword/tokens?userId=${mobileNo}` }
});
export const FORGET_PASSWORD_OTP = ((otp?: any, uid?: any) => {
    return { url: `${apiEndpointURL}otp/forgetPassword/verify?otp=${otp}&uid=${uid}` }
});
export const FORGET_PASSWORD_RESET = {
    url: `${apiEndpointURL}resetpassword`
};
export const MAIN_CATEGORY_LIST = {
    // url: `${apiEndpointURL}catalogs/sacoProductCatalog/Online/categories/MC?fields=id,name,subcategories(id,name)`
    url: `${apiEndpointURL}saco/category/?categoryCode=MC`
};

export const GET_SUB_CATEGORY_INFO = ((categoryCode: any) => {
    return { url: `${apiEndpointURL}sacoCategory/?catCode=${categoryCode}` }
});
//
export const GET_SUB_CATEGORY_INFO_CLP = ((categoryCode: any) => {
return { url: `${apiEndpointURL}saco/category/?categoryCode=${categoryCode}` }
});
//SOLR search
// export const GET_PRODUCT_LIST = {
//     url: `${apiEndpointURL}sacoproducts/search?fields=FULL`
// };

//Unboxed search
export const GET_PRODUCT_LIST = {
    url: `${apiEndpointURL}unbxd/search?fields=FULL`
};

//Unboxed Suggestion
export const UNBX_SUGGESTION = {
    url: `${apiEndpointURL}unbxd/suggestion?query=`
};

export const GET_PRODUCT_LIST_WITH_UID = {
    url: `${apiEndpointURL}sacocms/item?uid=`
};
export const ADD_TO_WISHLIST = {
    url: `${apiEndpointURL}users/current/wishlist/product/add/`
};
export const REMOVE_FROM_WISHLIST = {
    url: `${apiEndpointURL}users/current/wishlist/product/remove/`
};
export const GET_WISHLIST = {
    url: `${apiEndpointURL}users/current/wishlist?fields=FULL`
};
export const GET_RELATED_SEARCHES = {
    url: `${apiEndpointURL}products/suggestions`
};
export const GET_PRODUCT_DETAILS = ((productCode?: any) => {
    return { url: `${apiEndpointURL}sacoproducts/${productCode}?fields=FULL` }
});
export const GET_BUNDLING_PRODUCT = ((productCode?: any) => {
    return { url: `${apiEndpointURL}sacoproducts/${productCode}?fields=BUNDLING,FULL` }
});
export const GET_MIXANDMATCH_BUNDLE = ((cartId: any, promotionId: any, productCode?: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/addMixAndMatchBundle?promotionId=${promotionId}&productCode=${productCode}` }
});
export const GET_PRODUCT_BREADCRUMB = ((productCode?: any) => {
    return { url: `${apiEndpointURL}sacoproducts/${productCode}/breadcrumb?fields=FULL` }
});
export const GET_CATEGORY_BREADCRUMB = ((categoryCode?: any) => {
    return { url: `${apiEndpointURL}categories/${categoryCode}/breadcrumb?fields=FULL` }
});
export const GET_REFFERENCE_PRODUCTS = ((productCode?: any, sellType?: any) => {
    return { url: `${apiEndpointURL}products/${productCode}/references?referenceType=${sellType}&fields=FULL` }
});

export const GET_CART_LIST = ((cartType: any, cartId: any) => {
    return { url: `${apiEndpointURL}users/${cartType}/sacocarts/${cartId}?fields=FULL` }
});
export const CHECKOUT_GET_CART_LIST = ((cartId?: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}?fields=FULL` }
});

export const GUEST_USER_LOGIN = ((cartId?: any) => {
    return { url: `${apiEndpointURL}users/anonymous/sacocarts/${cartId}/email?email=` }
});

export const GET_CART_DETAILS = {
    url: `${apiEndpointURL}users/current/sacocarts/?fields=FULL`
};

export const UPDATE_CART_ENTRY = ((cartType: any, cartId?: any) => {
    return { url: `${apiEndpointURL}users/${cartType}/sacocarts/${cartId}/entries` }
});
export const GIFT_CARD_CART = ((cartType: any, cartId?: any) => {
    return { url: `${apiEndpointURL}users/${cartType}/sacocarts/${cartId}/addGiftCard?fields=FULL` }
});
export const UPDATE_BUY_NOW_CART_ENTRY = ((cartId: any, cartType: any) => {
    return { url:`${apiEndpointURL}users/${cartType}/sacocarts/buyNow?cartId=${cartId}`}
});
export const MANAGE_BUY_NOW_CART = {
    url: `${apiEndpointURL}users/current/sacocarts/manageBuyNow`
};
export const GET_ANONYMOUS_CART_DETAILS = {
    url: `${apiEndpointURL}users/anonymous/carts`
};

export const UPDATE_ANONYMOUS_CART_ENTRY = ((cartId?: any) => {
    return { url: `${apiEndpointURL}users/anonymous/sacocarts/${cartId}/entries` }
});

export const REMOVE_CART_ITEM = ((cartType: any, cartId?: any, productCode?: any) => {
    return { url: `${apiEndpointURL}users/${cartType}/carts/${cartId}/entries/${productCode}` }
})
export const CART_SIMILAR_PRODUCTS = ((componentName?: any, catCode?: any) => {
    return { url: `${apiEndpointURL}dynamicProducts/?uid=${componentName}&catCode=${catCode}&fields=FULL` }
})
export const SAVE_CART = ((cartId?: any) => {
    return { url: `${apiEndpointURL}users/current/carts/${cartId}/save` }
})

export const RESTORE_SAVE_CART = ((cartId?: any) => {
    return { url: `${apiEndpointURL}users/current/carts/${cartId}/restoresavedcart` }
})

export const GET_SAVED_CART_List = ((cartType: any) => {
    return { url: `${apiEndpointURL}users/${cartType}/sacocarts/savedcarts?savedCartsOnly=true&fields=FULL` }
});
export const CLEAR_CART = ((cartType: any, cartId?: any) => {
    return { url: `${apiEndpointURL}users/${cartType}/carts/${cartId}` }
})
export const PRODUCTS_COMPARISON = ((productCodes?: any) => {
    return { url: `${apiEndpointURL}sacoproducts/comparison/?productCodes=${productCodes}&fields=FULL` }
});
export const GET_ADDRESS_LAT_LAN = ((latLang: any) => {
    return { url: `${apiEndpointURL}addressSelector/delivery-address/geocode/${latLang?.lat}/${latLang?.lng}` }
});

export const ADDRESS_SELECTOR = {
    url: `${apiEndpointURL}addressSelector/delivery-address`
};

export const ADDRESS_SELECTOR_ANONYMOUS = ((guid: any) => {
    return { url: `${apiEndpointURL}addressSelector/${guid}/delivery-address` }
});
export const MANUAL_SELECTOR_ANONYMOUS = {
    url: `${apiEndpointURL}addressSelector/manualdelivery-address`
};
export const ADDRESS_MANUAL_SELECTOR_ANONYMOUS = ((guid: any) => {
    return { url: `${apiEndpointURL}addressSelector/${guid}/manualdelivery-address` }
});
export const ADDRESS_SELECTOR_FROM_ADDRESS_BOOK = {
    url: `${apiEndpointURL}addressSelector/address-book`
};

export const GET_SHORT_ADDRESS = ((shortCode: any) => {
    return { url: `${apiEndpointURL}addressSelector/delivery-address/${shortCode}` }
});

export const ADDRESS_GET_CITY_LIST = {
    url: `${apiEndpointURL}city`
};

export const ADDRESS_GET_DISTRICT_BY_CITY_CODE = {
    url: `${apiEndpointURL}district?cityCode=`
};

export const ADDRESS_GET_DISTRICT_BY_CITY_NAME = {
    url: `${apiEndpointURL}district/byname?cityName=`
};

export const ADDRESS_GET_DELIVERY_ADDRESS = ((cartID: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartID}/delivery-address` }
});

export const ADDRESS_GET_DELIVERY_ADDRESS_BY_GUID = ((cartguid: any) => {
    return { url: `${apiEndpointURL}users/anonymous/sacocarts/${cartguid}/delivery-address` }
});

export const SACO_CLUB_OPT_IN = {
    url: `${apiEndpointURL}users/current/my-account/loyalty/opt-in`
};

export const SACO_CLUB_ACCOUNT_ACTIVITY = {
    url: `${apiEndpointURL}users/current/my-account/loyalty/account-activity`
};

export const LOYALTY_CARD = {
    url: `${apiEndpointURL}my-account/loyalty/card`
};

export const SACO_CLUB_HISTORY = {
    url: `${apiEndpointURL}users/current/my-account/loyalty/history`
};

export const LOYALTY = {
    url: `${apiEndpointURL}my-account/loyalty/dashboard`
};
//url: `${apiEndpointURL}users/current/my-account/loyalty` //old API
export const CURRENT_ADDRESS = ((userType: string) => {
    return { url: `${apiEndpointURL}users/${userType}/addresses?fields=FULL` }
});
export const DIGITAL_WALLET_BALANCE = {
    url: `${apiEndpointURL}sacoUsers/current/digitalwalletbalance`
};
export const UPDATE_ADDRESS = ((id: any) => {
    return { url: `${apiEndpointURL}users/current/addresses/${id}` }
});
export const UPDATE_ADDRESS_MOBILE_NAME = ((addressId?: any, name?: any, phoneNo?: any, defaultAdd?: any) => {
    return { url: `${apiEndpointURL}sacoAddress/update?addressId=${addressId}&name=${name}&phoneNo=${phoneNo}&defaultAddress=${defaultAdd}` }
});
export const UPDATE_DELIVERY_ADDRESS = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/carts/${cartId}/addresses/delivery?addressId=` }
});


export const UPDATE_PAYMENT_DETAILS = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/paymentdetails?invokedFrom=spa` }
});

export const PICKUP_POST_FETCH_STORES = ((productId?: any, lat?: any, lng?: any) => {
    return { url: `${apiEndpointURL}store-pickup/${productId}/pointOfServices?fields=FULL&latitude=${lat}&longitude=${lng}&cartPage=false&baseSiteId=sacoStore&entryNumber=0` }
});
export const PICKUP_POST_SEARCH_STORES = ((productId?: any, query?: any) => {
    return { url: `${apiEndpointURL}store-pickup/${productId}/pointOfServices?fields=FULL&locationQuery=${query}&cartPage=false&baseSiteId=sacoStore&entryNumber=0` }
});
export const PICKUP_POST_ADD_QTY = ((productId?: any, cartID?: any, storeID?: any, quantity?: any, cartType?: any) => {
    return { url: `${apiEndpointURL}users/${cartType}/sacocarts/${cartID}/store-pickup/add?hiddenPickupQty=${quantity}&productCodePost=${productId}&storeNamePost=${storeID}` }
});
export const GET_ADDITIONAL_INFO_PDP = ((productId?: any) => {
    return { url: `${apiEndpointURL}sacoproducts/${productId}/productAdditionalInfo?fields=FULL` }
});

export const SAVE_CART_ITEM = ((cartType: any, saveCartId: any, entryNumber: any, cartId: any) => {
    return { url: `${apiEndpointURL}/users/${cartType}/sacocarts/${saveCartId}/entry/${entryNumber}?cartId=${cartId}` }
});

export const RESTORE_SAVED_CART_ITEM = ((cartType: any, saveCartId: any, entryNumber: any, cartId: any) => {
    return { url: `${apiEndpointURL}/users/${cartType}/sacocarts/${cartId}/entry/${entryNumber}?cartId=${saveCartId}` }
});

export const RESTORE_SAVED_CART_ITEM_WITHOUT_CART = ((cartType: any, cartId: any, entryNumber: any) => {
    return { url: `${apiEndpointURL}/users/${cartType}/sacocarts/${cartId}/entry/${entryNumber}` }
});
export const GET_QTAF_OTP = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/qitaf` }
});
export const SUBMIT_QTAF_OTP = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/redeem` }
});

export const PLACE_ORDER_URL = ((cartId: any) => {
    return { url: `${apiEndpointURL}sacoorders/users/current/orders?cartId=${cartId}&invokedFrom=spa` }
});

export const SET_DELIVERY_MODE = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/carts/${cartId}/deliverymode?deliveryModeId=` }
});
export const CART_DELIVERY_MODE = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/deliverymode` }
});
export const GET_TAMARA_DETAILS = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/tamara?paymentMode=PAY_BY_INSTALMENTS_2` }
});
export const GET_TAMARA_CHECKOUT_DETAILS = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/tamara/checkout` }
});
export const GET_TAMARA_ORDER_STATUS = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/check-status?cartCode=${cartId}` }
});
export const GET_TABBY_STATUS = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/tabby/check-status?cartCode=${cartId}` }
});
export const TABBY_RECEIPT = ((users: any, cartId: any, paymentId: any) => {
    return { url: `${apiEndpointURL}users/${users}/sacocarts/${cartId}/tabby/receipt?payment_id=${paymentId}` }
});
export const GET_ORDER_DETAILS = {
    url: `${apiEndpointURL}sacoorders/users/current/orders/`
};
export const PRODUCTS_PRICE_DETAILS = ((productCode: any) => {
    return { url: `${apiEndpointURL}users/current/productinterests?productCode=${productCode}` }
});
export const PRODUCTS_ADD_PRICE_DROP = ((productCode: any, channelName: any) => {
    return { url: `${apiEndpointURL}users/current/productinterests?productCode=${productCode}&notificationType=PRICE_DROP&channels=${channelName}&fields=FULL` }
});
export const PRODUCTS_DELETE_PRICE_DROP = ((productCode: any) => {
    return { url: `${apiEndpointURL}users/current/productinterests?productCode=${productCode}&notificationType=PRICE_DROP` }
});
export const ANONYMOUS_PRODUCTS_PRICE_DETAILS = ((guestUID?: any, email?: any, productCode?: any) => {
    return { url: `${apiEndpointURL}users/anonymous/productinterests/${guestUID}%7C${email}?notificationType=PRICE_DROP&guestUID=${guestUID}%7C${email}&productCode=${productCode}` }
});
export const ANONYMOUS_PRODUCTS_ADD_PRICE_DROP = ((productCode: any, emailOrGuest?: any, mobile?: any) => {
    return { url: `${apiEndpointURL}users/anonymous/productinterests?productCode=${productCode}&notificationType=PRICE_DROP${emailOrGuest}&phone=${mobile}` }
});
export const ANONYMOUS_PRODUCTS_DELETE_PRICE_DROP = ((guestUID?: any, email?: any, productCode?: any) => {
    return { url: `${apiEndpointURL}users/anonymous/productinterests/${guestUID}%7C${email}/?productCode=${productCode}&notificationType=PRICE_DROP&guestUID=${guestUID}%7C${email}` }
});
export const GET_ORDER_HISTORY = {
    url: `${apiEndpointURL}sacoorders/users/current/orderHistory/`
};
export const GET_ORDER_HISTORY_DROPDOWN_OPTIONS = {
    url: `${apiEndpointURL}sacoorders/users/current/orderHistory/dropdown?fields=FULL`
};
export const GET_SUPPORT_TICKETS_LIST = {
    url: `${apiEndpointURL}users/current/my-account/support-tickets?fields=FULL`
};
export const GET_SUPPORT_TICKETS_BY_ID = ((id: any) => {
    return { url: `${apiEndpointURL}users/current/my-account/support-ticket/${id}` }
});
export const GET_SUPPORT_TICKETS_CATEGORY = {
    url: `${apiEndpointURL}users/current/my-account/support-ticketsdetails`
};
export const GET_SUPPORT_TICKETS_SUB_CATEGORY = ((catName: any) => {
    return { url: `${apiEndpointURL}users/current/my-account/serviceSubcategories/${catName}` }
});
export const CREATE_SUPPORT_TICKET = {
    url: `${apiEndpointURL}users/current/my-account/add-support-ticket`
};
export const UPDATE_SUPPORT_TICKETS_BY_ID = ((id: any) => {
    return { url: `${apiEndpointURL}users/current/my-account/support-ticket/${id}` }
});
export const LOYALTY_POINTS_REDEEM = ((cartId: any) => {
    return { url: `${apiEndpointURL}my-account/loyalty/users/current/sacocart/${cartId}/loyality-points/` }
});
export const MERIT_PAYMENT_REDEEM = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/merit/` }
});
export const COUPON_PAYMENT_REDEEM = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/coupon/` }
});
export const STORE_CREDITS_REDEEM = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/addDigitalWallet/` }
});
export const REMOVE_CREDITS_REDEEM = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/removeDigitalWallet/` }
});
export const GIFT_CARD_REDEEM = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/gift-card/` }
});
export const GIFT_CARD_BALANCE = {
    url: `${apiEndpointURL}users/current/gift-card-balance`
};
export const POST_WRITE_REVIEW = {
    url: `${apiEndpointURL}sacoorders/users/current/orders/`
};
export const POST_REVIEW_IMAGE_UPLOAD = {
    url: `${apiEndpointURL}sacoorders/users/current/orders/`
};
export const UNBXD_GET_VISITOR_ANALYTICS = {
    url: `${apiEndpointURL}users/current/unbxd/analytics`
};
export const UNBXD_GET_VISITOR_ANALYTICS_ANONYMOUS = ((guid: any) => {
    return { url: `${apiEndpointURL}users/${guid}/unbxd/analytics` }
});
export const TABBY_VISIBILTY_STATUS = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/tabby/prescore/` }
});
export const TAMARA_VISIBILTY_STATUS = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/tamara/paymentModes` }
});


export const TABBY_PAYMENT = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/tabby/checkout?prescore=true` }
});
export const POST_CAREERS_UPLOAD = {
    url: `${apiEndpointURL}users/current/career`
};
export const GET_NATIONALITIES = ((userType: any) => {
   return { url: `${apiEndpointURL}users/${userType}/nationalities` }
});
export const GET_STORE_LOCATOR = ((id: any, userType: any) => {
    return { url: `${apiEndpointURL}users/${userType}/store-finder?page=${id}&q=` }
});

export const PAYFORT_CARD_INFO = {
    url: `${apiEndpointURL}users/current/paymentdetails`
};
export const VALIDATE_CART = ((cartId: any, refreshCart: boolean, userType: any) => {
    return { url: `${apiEndpointURL}users/${userType}/sacocarts/${cartId}/validateCart?updateCart=` + refreshCart }
});
export const UPDATE_MANUAL_ADDRESS = ((cartid: any) => {
    return { url: `${apiEndpointURL}addressSelector/${cartid}/addmanualdelivery-address?fields=FULL` }
});
export const PRODUCT_WIDGET = ((cartType: any) => {
    return { url: `${apiEndpointURL}users/${cartType}/unbxd/recommendation?&fields=FULL` }
});

export const PRODUCTS_SUGGESTION = ((catCode: any) => {
    return { url: `${apiEndpointURL}/products/search?query=:relevance:allCategories:${catCode}&fields=FULL&sort=` }
})

export const HOME_PRODUCTS_SUGGESTION = ((catCode: any) => {
    return { url: `${apiEndpointURL}/unbxd/search?query=:relevance:allCategories:${catCode}&fields=FULL&isCarrousel=true` }
})

export const HOME_PAGE_TILES_ALL = ((catCode: any) => {
    return { url: `${apiEndpointURL}/unbxd/searchTile?query=:relevance:allCategories:${catCode}&isCarrousel=true` }
})

export const SPECIAL_CATEGORY_PRODUCTS = ((catCode: any) => {
    return { url: `${apiEndpointURL}unbxd/search?query=:relevance:exclusiveCatgeoryCodes:${catCode}&fields=FULL&isCarrousel=true` }
})
export const GET_REVIEWS_QUESTION = {
    url: `${apiEndpointURL}/reviews/users/current/`
}

export const New_Arrival = ((catCode: any) => {
    return { url: `${apiEndpointURL}/unbxd/search?query=:relevance:exclusiveCatgeoryCodes:${catCode}` }
})
export const HOME_PAGE_TILES_EXCLUSIVE = ((catCode: any) => {
    return { url: `${apiEndpointURL}/unbxd/searchTile?query=:relevance:exclusiveCatgeoryCodes:${catCode}&isCarrousel=true` }
})
export const SPECIAL_CATEGORY =  {
    url: `${apiEndpointURL}/unbxd/search?query=`
}

export const SMALL_APPLIANCES = ((catCode: any) => {
    return { url: `${apiEndpointURL}/sacoCategory/?catCode=${catCode}&fields=FULL` }
})
export const GET_PROFILE_INFORMAION = {
    url: `${apiEndpointURL}sacoUsers/current/getProfileInformation`
}
export const GET_PROFILE_STATUS_AND_COUNTRY_LIST = ((urlName: any) => {
    return { url: `${apiEndpointURL}${urlName}` }
})
export const GET_LOYALTY_POINTS = {
    url: `${apiEndpointURL}my-account/loyalty/loyalty/points`
}
export const UPDATE_USER_PROFILE = {
    url: `${apiEndpointURL}sacoUsers/current/completeProfile`
}
export const COMPLETE_USER_PROFILE = {
    url: `${apiEndpointURL}sacoUsers/current/completeUserProfile`
}
export const PROFILE_COMPLETION = {
    url: `${apiEndpointURL}sacoUsers/current/profileCompletion`
}
export const UPDATE_XENO_DETAILS = {
    url: `${apiEndpointURL}sacoUsers/current/updateCustomerXenoDetail`
}
export const GET_SELECTED_PAYMENT_INFO = ((cartId: any) => {
    return { url: `${apiEndpointURL}users/current/sacocarts/${cartId}/cartPaymentInfoDetails` }
});
export const UNBX_PRODUC_CUSTOM_DATA = {
    url: `${apiEndpointURL}unbxd/productCustomData`
};
export const FLIX_MEDIA = ((lang: any = 'd2') => {
    return { url: `https://media.flixcar.com/delivery/webcall/content/14414/${lang}/mpn/` }
});


export const STC_PAY = ((cartGuid: any) => {
    return { url: `${apiEndpointURL}orders/payment/payFort/${cartGuid}?invokedFrom=spa` }
});
export const GET_XENO_OTO_PDP_DATA = ((productCode: any, cartGuid: any) => {
    return { url: `${apiEndpointURL}sacoproducts/${productCode}/xenoAndOTO?cartId=${cartGuid}` }
});
export const GET_WISHLIST_PRODUCTS = {
    url: `${apiEndpointURL}users/current/wishlist/products?fields=FULL`
};
export const GET_ORDER_CANCEL = ((email: any, orderNo: any) => {
    return { url: `${environment.occBaseUrl}occ/v2/saco/sacoorders/users/${email}/orders/${orderNo}/cancellation` }
});
export const GET_ORDER_RETURN = ((email: any) => {
    return { url: `${environment.occBaseUrl}occ/v2/saco/sacoorders/users/${email}/orderReturns` }
});
export const GET_UPDATE_QUANTITY = ((orderNo: any, entryNo:any ) => {
    return { url: `${apiEndpointURL}sacoorders/users/current/orders/${orderNo}/${entryNo}?fields=FULL` }
});
export const GET_BRAND_LIST = {
    url: `${apiEndpointURL}sacoCategory/brands?fields=FULL`
};
export const MERGE_CART = {
    url: `${apiEndpointURL}users/current/sacocarts/`
};
export const LOYALTY_CART_REGISTRATION = ((cardId: any, mobileNo:any, email:any) => {
    return { url: `${apiEndpointURL}card/migratecard?cardId=${cardId}&phoneNumber=${mobileNo}&email=${email}` }
});
export const CATEGORY_CARD_LIST = ((catCode: any) => {
    return { url: `${apiEndpointURL}saco/category/categoryCardList/?categoryCode=${catCode}` }
});
export const NEW_ARRIVAL_BRANDS = {
    url: `${apiEndpointURL}sacoCategory/firstBrands?brandCode=brands&fields=FULL`
};
export const ENABLE_GUEST_CHECKOUT = {
    url: `${apiEndpointURL}globalConfiguration/`
};

export const GET_VERIFIED_BIN_DISCOUNT_DATA = ((cartId?: any) => {
  return { url: `${apiEndpointURL}users/current/sacocarts/verifyBinDiscount/${cartId}?fields=FULL` }
});

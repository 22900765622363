import { Component, OnInit, ChangeDetectorRef, OnChanges, AfterContentChecked, SimpleChanges, AfterViewChecked } from '@angular/core';
import { CmsService, LanguageService } from '@spartacus/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationSelectionPopupComponent } from './location-selection-popup/location-selection-popup.component';
import { HeaderService } from 'src/app/core/services/header.service';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { BroadcastChannelService } from 'src/app/shared/broadcast-channel.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { SessionCookieService } from 'src/app/core/services/session-cookie.service';
import { Router } from '@angular/router';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { CartService } from 'src/app/core/services/cart.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { GeoLocationService } from 'src/app/core/services/geo-location.service';
import { DeliveryLocation } from 'src/app/shared/components/delivery-location/delivery-location.component';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-custom-location',
  templateUrl: './custom-location.component.html',
  styleUrls: ['./custom-location.component.scss']
})
export class CustomLocationComponent implements OnInit, OnChanges, AfterContentChecked, AfterViewChecked {
  showWaitCursor = new BehaviorSubject<boolean>(true);
  modalRef: any;
  userInfo: any = null;
  isLoading: boolean = false;
  langConfig: any = EnglishEnum;
  lat: any = 24.774265; // Inital set for Riyadh
  lng: any = 46.738586;
  payLoad: any;
  cartId: any;
  guid: any;
  locationSelected: boolean = false;
  isPopupShown: boolean = false;
  isUserLoggedIn: boolean = false;
  guestAddress: any;
  isProfileUpdated: boolean = true;
  isCalled: boolean = false;
  showToast: boolean = false;
  errorMessage: any = null;
  toastMessage: any = null;

  unkonwnLocation: boolean = false;

  selectedAddressHeaders: any;

  currentLocationStatus: any = '';

  showAddressPopupClicked = false;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public headerService: HeaderService,
    private userAccountService: UserAccountService,
    public cdr: ChangeDetectorRef,
    private broadcastChannelService: BroadcastChannelService,
    private languageService: LanguageService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private activeCartService: ActiveCartService,
    private cartService: CartService,
    private geoLocationService: GeoLocationService,
    private sessionCookieService: SessionCookieService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
  ) {
    const orderPlacement = this.route.snapshot.queryParamMap.get('cp');
    if (!this.isNullOrEmpty(orderPlacement) && orderPlacement == 'success') {
      localStorage.removeItem('spartacus⚿sacoStore⚿cart');
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    }
  }

  async ngOnInit() {
    //this.createNewCart(); // TODO need to uncomment once national address issue resolved
    this.locationSelected = false;
    this.showWaitCursor.next(true);
    this.isUserLoggedIn = this.localStorageService.getValue('isLoggedIn');
    this.localStorageService.setValue('locationAccess', false);

    this.cartService.getCurrentCartDetails().subscribe((cartInfo: any) => {
      if (cartInfo && this.localStorageService.getValue('isLoggedIn')) {
        this.setCartDetails(cartInfo);
        this.getLocationAddress(false, cartInfo?.carts[0]?.deliveryAddress);
      } else {
        this.openLocationPopup();
      }
    });

    this.geoLocationService.getLocationPermissionStatus().subscribe((status: any) => {
      this.currentLocationStatus = status;
      this.updateAddressInfo(status);
    });

    // const position = await this.getLocation();
    await this.geoLocationService.askForLocationPermission()
      .then((position: any) => {
        // if (this.isNullOrEmpty(sessionStorage.getItem('SELECTEDCITY')) && !this.isUserLoggedIn )
        this.geoLocationService.hasGeolocationPermission().then((permission: any) => {
          //console.log('Permission : ', permission);
          if (permission) {
            this.localStorageService.setValue('hasLocation', !permission);
            this.updateAddressInfo(this.currentLocationStatus);
          } else if (!this.localStorageService.getValue('isLoggedIn')) {
            this.localStorageService.setValue('hasLocation', false);
            this.updateAddressInfo(this.currentLocationStatus);
            this.openLocationPopup();
          }
        }).catch(() => {
          this.updateAddressInfo(this.currentLocationStatus);

        });
      })
      .catch((error) => {
        this.updateAddressInfo(this.currentLocationStatus);
      });


    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.langConfig = ArabicEnum;
        this.cdr.detectChanges();
        this.cdr.markForCheck();
      }
    })
    this.userInfo = null;
    this.headerService.detectLocation.subscribe((res: any) => {
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    });
    this.headerService.isLocationSelected.subscribe(
      (data) => {
        if (Object.keys(data)?.length) {
          this.guestAddress = data;
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        }
      }
    );

  }

  updateAddressInfo(status: any): void {
    switch (status) {
      case 'granted':
        if (!this.localStorageService.getValue('isLoggedIn') && !this.localStorageService.getValue('hasLocation') && !this.localStorageService.getValue('isLocationAddressSet')) {
          //console.log('Current Status : ', status);
          this.getLocationAddress(true, null);
          // this.localStorageService.setValue('hasLocation', true);
        }
        break;
      case 'prompt':
        if (!this.localStorageService.getValue('isLoggedIn')) {
          //console.log('Current Status : ', status);
          this.localStorageService.setValue('hasLocation', false);
          this.localStorageService.setValue('isLocationAddressSet', false)
        }
        break;
      case 'denied':
        if (!this.localStorageService.getValue('isLoggedIn') && !this.localStorageService.getValue('hasLocation')) {
          this.openLocationPopup();
        }
        break;
      default:
        //console.log('Current Status : ', status);
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  // getLocation(): Promise<GeolocationPosition> {
  //   return new Promise((resolve, reject) => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(resolve, reject);
  //     } else {
  //       reject(new Error("Geolocation is not supported by this browser."));
  //     }
  //   });
  // }

  setCartDetails(cartInfo: any): void {
    this.cartId = cartInfo?.carts && cartInfo?.carts[0] ? cartInfo?.carts[0]?.code : cartInfo?.code;
    const newCartId = { active: this.cartId };
    localStorage.setItem("spartacus⚿sacoStore⚿cart", JSON.stringify(newCartId));
    this.localStorageService.setValue('isCartAvailable', true);
    this.localStorageService.setValue('guid', cartInfo?.carts && cartInfo?.carts[0] ? cartInfo?.carts[0]?.guid : cartInfo?.guid)
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  ngAfterViewChecked(): void {

    // if (this.localStorageService.getValue('isLoggedIn') && !this.localStorageService.getValue('isCartAvailable') && !this.isCalled) {
    //   this.isCalled = true;
    //   this.cartService.getCurrentCartDetails().subscribe((cartInfo: any) => {
    //     if (cartInfo) {
    //       this.setCartDetails(cartInfo);
    //       if (!this.localStorageService.getValue('isCurrentSession')) {
    //         this.getCurrentDeliveryAddress(true);
    //       }
    //     }
    //   });
    // } else if (!this.localStorageService.getValue('isCartAvailable') && !this.isCalled) {
    //   this.isCalled = true;
    //   if (this.localStorageService.getValue('isLoggedIn')) {
    //     this.cartService.createAnonymousCartId().subscribe((cartInfo: any) => {
    //       if (cartInfo) {
    //         if (cartInfo?.guid) {
    //           localStorage.setItem("anonymous_cart_guid", cartInfo?.guid);
    //           if (!this.localStorageService.getValue('isCurrentSession')) {
    //             this.getCurrentDeliveryAddress(true);
    //           }
    //         }
    //         this.setCartDetails(cartInfo);
    //       }
    //     });
    //   }
    // }

    // this.cdr.detectChanges();
    // this.cdr.markForCheck();
  }

  ngAfterContentChecked(): void {
    if (this.localStorageService.getValue('isLoggedIn')) {
      if (this.broadcastChannelService.getSharedDataObservable('userDetailsInfo') && !this.userInfo) {
        this.broadcastChannelService.getSharedDataObservable('userDetailsInfo')?.subscribe(
          (userData: any) => {
            if (userData) {
              this.userInfo = userData;
              if (this.userInfo?.unbxdUid) {
                //Unbox is also setting its cooke with name : unbxd.userId we will use if for guest user
                //We need to set our own cookie that we get from userinfo call and save it, so we are saving this as below unbxd.userid
                //its with small 'i' unbxd.userid
                this.sessionCookieService.setTimelessCookie("unbxd.userid", this.userInfo?.unbxdUid);
              }
              this.cdr.detectChanges();
              this.cdr.markForCheck();
            } else if (this.isProfileUpdated) {
              this.userAccountService.updateUserAccountDetails(this.isProfileUpdated);
              this.isProfileUpdated = false;
            }
          }
        );
      } else if (!this.userInfo && !this.isLoading && this.isProfileUpdated) {
        this.userAccountService.updateUserAccountDetails(this.isProfileUpdated);
        this.isProfileUpdated = false;
      }
      if (this.localStorageService.getValue('isLocationUpdated')) {
        this.localStorageService.setValue('isLocationUpdated', false);
        this.getCurrentDeliveryAddress()
      }
    }
  }

  showAddressPopup() {
    this.showAddressPopupClicked = true;
    this.openLocationPopup(true)
  }


  openLocationPopup(status: boolean = false): void {
    // return; //TODO need to uncomment when national address issue resolved
    this.showWaitCursor.next(false);
    this.showToast = false;
    this.toastMessage = null;
    this.errorMessage = null;
    if (this.router?.url?.indexOf('login') > 0 || (this.localStorageService.getValue("isLocationAddressSet") && !status)) {
      return;
    }
    if (!this.isPopupShown || status) {

      if (this.isUserLoggedIn) {
        this.isLoginLocationPopup()
      } else {
        this.isPopupShown = true;
        if(!this.showAddressPopupClicked) {
          return;
        };
        this.showAddressPopupClicked = true;
        this.modalRef = this.modalService.open(LocationSelectionPopupComponent, {
          windowClass: 'delivery-location-popup',
          centered: true,
          backdrop: 'static',
          keyboard: false,
        });
        this.modalRef.componentInstance.unknownLocation = this.unkonwnLocation;
        this?.modalRef?.componentInstance?.closeLocationPopup?.subscribe((value: any) => {
          //console.log('Close : ', value);
          this.onPopupClose(value);
        });

        this.modalRef?.componentInstance?.disableUnknownLocation.subscribe((value: boolean) => {
          this.unkonwnLocation = value;
        })
      }
    }
  }


  onPopupClose(value: any) {
    {
      // console.log('Close : ', value);
      this.isPopupShown = false;
      this.unkonwnLocation = false;
      this.localStorageService.setValue('isLocationAddressSet', true);
      this.localStorageService.setValue('hasLocation', true);
      this.showToast = true;
      this.toastMessage = this.langConfig?.locationUpdatedSuccess;
      this.errorMessage = null;
      this.hideTosterPopup();
      if (value && value !== 'close') {
        this.headerService.regionSelected = value || this.headerService.regionSelected;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
        this.modalRef.close();
      } else {
        this.modalRef.close();
      }

      setTimeout(() => {
        this.reloadPage();
      }, 2000);

      this.cdr.detectChanges();
      this.cdr.markForCheck();
    }
  }


  reloadPage(): void {
    window.location.reload();
    // Get the current URL without query parameters or fragments
    // const currentUrlTree = this.router.parseUrl(this.router.url);
    // const currentUrl = this.router.url;
    // const currentUrlTree = this.router.url;
    // Reset the route to the current URL, which will reload the page content
    // this.router.navigateByUrl(currentUrlTree, { replaceUrl: true });
    // this.headerService?.isNewAddressSelected?.next(true);

    // console.log('Current Url - Before Reload : ', currentUrlTree);

    // if (location.pathname != '/' && location.pathname != '/login') {
    //   this.router.navigateByUrl(currentUrlTree, { replaceUrl: true, skipLocationChange: true }).then(() => {
    //     // console.log('Current Url - After Reload : ', currentUrlTree);
    //     this.router.navigate([currentUrl]);
    //   });
    // }

  }

  isLoginLocationPopup(): void {
    this.modalRef = this.modalService.open(DeliveryLocation, {
      windowClass: 'delivery-location-popup',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    this.modalRef.componentInstance.isHeaderLocation = true;

    this.modalRef.componentInstance.addressUpdated.subscribe((emmitedValue: any) => {
      //console.log('Emittedvalue : ', emmitedValue);
      this.showToast = true;
      this.toastMessage = this.langConfig?.locationUpdatedSuccess;
      this.errorMessage = null;
      this.hideTosterPopup();
      setTimeout(() => {
        this.reloadPage();
      }, 2000);
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    });
  }

  updateGeoLocation(): void {

    //console.log("updateGeoLocation");
    this.showToast = false;
    this.toastMessage = null;
    this.errorMessage = null;
    if (this.lat && this.lng) {
      const latLng = {
        lat: this.lat,
        lng: this.lng
      }
      this.headerService.getAddressLatLan(latLng)?.subscribe(
        (res: any) => {
          if (res && !res?.errorMsg) {
            //console.log("Lat Lang Response : ", res);
            const payLoad: any = {
              appartment: res?.appartment,
              line1: res?.line1,
              line2: res?.line2,
              townId: res?.townId,
              districtId: res?.districtId,
              postalCode: res?.postalCode
            };

            this.selectedAddressHeaders = {
              SELECTEDCITY: res?.townId || this.selectedAddressHeaders?.SELECTEDCITY,
              SELECTEDDISTRICT: res?.districtId || this.selectedAddressHeaders?.SELECTEDDISTRICT,
              LATLONG: this.lat,
              LONGIT: this.lng
            }
            sessionStorage.setItem('SELECTEDCITY', res?.townId || this.selectedAddressHeaders?.SELECTEDCITY);
            this.localStorageService.setValue('SELECTEDCITY', res?.townId || this.selectedAddressHeaders?.SELECTEDCITY);
            this.localStorageService.setValue('currentCity', res?.localizedTown || res?.town);

            this.headerService.addressHeaders.next(this.selectedAddressHeaders);
            const guid = this.guid || this.localStorageService.getValue('guid') || localStorage.getItem('anonymous_cart_guid');
            this.headerService.selectAddress(payLoad, guid)?.subscribe(
              (data: any) => {
                if (data) {
                  this.unkonwnLocation = false;
                  this.headerService.regionSelected = (data?.town || res?.town) + (data?.postalCode ? ' ' + data?.postalCode : '');
                  this.localStorageService.setValue('hasLocation', true);
                  this.broadcastChannelService.createChannel('locationUpdated', true);
                  this.showWaitCursor.next(false);
                  this.cdr.detectChanges();
                  this.cdr.markForCheck();
                }
              }, (error: any) => {
                this.unkonwnLocation = true;
                this.getCurrentDeliveryAddress();
              }
            )
          } else {
            this.globalService.notificationError(res?.errorMsg)
            this.unkonwnLocation = true;
            this.cdr.markForCheck();
            this.getCurrentDeliveryAddress();
          }
        }, (error: any) => {
          this.getCurrentDeliveryAddress();
        }
      )
    } else {
      this.getCurrentDeliveryAddress();
    }
  }

  getUserLocation(): void {
    const isLocationChecked = sessionStorage.getItem('isLocationChecked');
    if (navigator.geolocation && this.geoLocationService.allowLocationPermission) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        if (isLocationChecked != 'true') {
          sessionStorage.setItem('isLocationChecked', 'true')
          this.updateGeoLocation();
        }
      });
    } else {
      if (isLocationChecked != 'true') {
        sessionStorage.setItem('isLocationChecked', 'true')
        this.openLocationPopup();
      }
    }
  }


  getLocationAddress(isNew: boolean = false, data?: any): void {
    this.showWaitCursor.next(true);
    if (data && data?.localizedTown) {
      this.locationSelected = true;
      this.localStorageService.setValue('locationAccess', true)
      this.headerService.regionSelected = data?.localizedTown + (data?.postalCode ? ' ' + data?.postalCode : '');
      this.showWaitCursor.next(false);
      this.selectedAddressHeaders = {
        SELECTEDCITY: data?.townId || this.selectedAddressHeaders?.SELECTEDCITY,
        SELECTEDDISTRICT: data?.districtId || this.selectedAddressHeaders?.SELECTEDDISTRICT,
        LATLONG: null,
        LONGIT: null
      }
      this.headerService.addressHeaders.next(this.selectedAddressHeaders);
      sessionStorage.setItem('SELECTEDCITY', data?.townId || this.selectedAddressHeaders?.SELECTEDCITY);
      this.localStorageService.setValue('SELECTEDCITY', data?.townId || this.selectedAddressHeaders?.SELECTEDCITY);
      this.localStorageService.setValue('currentCity', data?.localizedTown || data?.town);
      this.localStorageService.setValue('hasLocation', true);
      this.cdr.detectChanges();
      this.cdr.markForCheck();
    } else {
      this.getUserLocation();
    }
  }


  getCurrentDeliveryAddress(setSession?: boolean): void {
    this.cartId = !this.isNullOrEmpty(localStorage.getItem("spartacus⚿sacoStore⚿cart"))
      ? JSON.parse(localStorage.getItem("spartacus⚿sacoStore⚿cart") || "")?.active
      : "";
    if (!this.isNullOrEmpty(this.cartId)) {
      if (this.localStorageService.getValue('isLoggedIn')) {
        this.headerService.getDeliveryAddress(this.cartId)?.subscribe(
          (data: any) => {
            if (data) {
              this.localStorageService.setValue('isCurrentSession', true);
              this.getLocationAddress(false, data);
              this.headerService.defaultAddress.next(true);
            } else {
              this.openLocationPopup();
            }
          }, (error: any) => {
            this.openLocationPopup();
          }
        );
      } else {
        const guid = this.localStorageService.getValue('guid') || localStorage.getItem('anonymous_cart_guid');
        this.headerService.getDeliveryAddressByguid(guid)?.subscribe(
          (data: any) => {
            if (data) {
              this.localStorageService.setValue('isCurrentSession', true);
              this.getLocationAddress(false, data);
            } else if (!this.isPopupShown) {
              this.openLocationPopup();
            }
          }, (error: any) => {
            this.openLocationPopup();
          }
        );
      }
    } else {
      this.openLocationPopup();
    }
  }

  isNullOrEmpty(value: any): boolean {
    return value == null || value == undefined || value == "" || value == " ";
  }

  closeToast() {
    this.showToast = false;
    return;
  }
  hideTosterPopup() {
    setTimeout(() => {
      this.showToast = false;
    }, 3000);
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }
}

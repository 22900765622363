import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CategoryService } from 'src/app/core/services/category.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { LanguageService } from '@spartacus/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { environment } from 'src/app/environments/environment';
import { UnboxdService } from 'src/app/core/services/unbxd.service';
import { SessionCookieService } from 'src/app/core/services/session-cookie.service';
import { MY_CATEGORYLISTS_PAGE_SIZE } from 'src/app/core/constants/saco-constants';
@Component({
  selector: 'app-custom-search',
  templateUrl: './custom-search.component.html',
  styleUrls: ['./custom-search.component.scss']
})
export class CustomSearchComponent implements OnInit {
  constructor(private headerService: HeaderService, public cd: ChangeDetectorRef, private categoryService: CategoryService,
    private localStorageService: LocalStorageService,
    private router: Router, private route: ActivatedRoute, private languageService: LanguageService,
    private unbxdService: UnboxdService, private sessionCookieService: SessionCookieService,) {
    this.setParamValInSearch();
  }
  langConfig: any = EnglishEnum;
  isLoading$ = new BehaviorSubject<boolean>(true);
  @ViewChild('headerSearchContainer')
  headerSearchContainer!: ElementRef;
  searchQuery = new FormControl('');
  searchCategory = new FormControl('MC');
  showWaitCursor = new BehaviorSubject<boolean>(true);
  serviceSubscribe: Subscription = new Subscription;
  categorySelected = 'All'
  searchResult: any[] = [];
  searchDropwDownOptions: any = null;
  queryParam: any = '';
  trendingIcon: boolean = false;
  isUserLoggedIn: boolean = false;
  noResultsFoundMessage: any;
  unboxUserId: any;
  popularProducts: any;
  public readonly PAGE_SIZE_CATEGORY: number = MY_CATEGORYLISTS_PAGE_SIZE;
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const searchBoxLabel = document.querySelector('.searchbox-label');
    // Check if the clicked element is not inside the search box
    if (!searchBoxLabel || !searchBoxLabel.contains(event.target as Node)) {
      // Call your close function
      this.cClose();
    }
  }

  ngOnInit(): void {
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.langConfig = ArabicEnum;
      }
    })
    this.searchQuery.setValue(this.queryParam);
    this.queryParam
    this.headerService.getSearchCategoryOptions().subscribe(data => {
      this.showWaitCursor.next(true);
      this.searchDropwDownOptions = data;
      this.showWaitCursor.next(false);
      this.cd.markForCheck();
    })

    this.categoryService.clearHeaderSearchQuery.subscribe(data => {
      //console.log(data, 'Data---')
    })

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        //console.log(event, 'EVENT HERE..')
        //clearing the search query once page is changed for SS-912
        // if(!event?.url?.includes('search')){
        this.searchQuery.setValue('');
        //   this.searchQuery.setValue('');
        // }
      }
    });

    this.isUserLoggedIn = this.localStorageService.getValue('isLoggedIn');
    this.unboxUserId = this.sessionCookieService.getCookieValue("unbxd.userId");
  }
  // Check if any value is empty or undefined
  isNullOrEmpty(value: any): boolean {
    return value == null || value == undefined || value == "" || value == " ";
  }
  cSearch(): void {
    const cartId = (this.localStorageService.getValue('isLoggedIn') && !this.isNullOrEmpty(localStorage.getItem("spartacus⚿sacoStore⚿cart")))
      ? JSON.parse(localStorage.getItem("spartacus⚿sacoStore⚿cart") || "")?.active
      : localStorage.getItem('anonymous_cart_guid');
    // this.isLoading$.next(true);
    let category = this.searchCategory.value?.toLowerCase() === 'all' ? '' : this.searchCategory.value?.toLowerCase();
    let isCategoryAndFreeTextSearch = (category !== '') ? true : false;
    let paginate = this.PAGE_SIZE_CATEGORY;
    if (this.searchQuery.value && this.searchQuery.value?.length > 0) {
      this.categoryService.getCategorySearchList(category, encodeURIComponent(this.searchQuery.value), isCategoryAndFreeTextSearch, paginate).subscribe(data => {
        this.searchResult = data?.products;
        // this.isLoading$.next(false)
        this.cd.markForCheck();
        this.cd.detectChanges();


        // if(data.products && data.products.length == 0 && this.searchCategory.value?.toLowerCase() !== 'all'){

        //   this.searchCategory.setValue('All');
        //   console.log('Again do the search with ALL search filter')

        // }
      })
    }
    else {
      this.cClose();
    }

  }
  getSuggestions() {
    if (this.searchQuery.value && this.searchQuery.value != null && this.searchQuery.value.trim()?.length > 0)
    this.categoryService.unbxSuggestion(encodeURIComponent(this.searchQuery.value.trim()), this.unboxUserId).subscribe(data => {
        //this.searchResult = data?.products;
        //Fire Unbxd Analytics API here when we get the suggestions
        //this.triggerUnbxdAnalyticsSearchHit();
        this.searchResult = data?.products?.filter((element: any) => (element?.doctype == 'KEYWORD_SUGGESTION'));
        this.popularProducts = data?.products?.filter((element: any) => (element?.doctype == 'POPULAR_PRODUCTS'));
        this.noResultsFoundMessage = this.searchResult == undefined ? data.noResultsFoundMessage : undefined;

        // this.isLoading$.next(false);
        this.trendingIcon = false;
        this.cd.markForCheck();
        this.cd.detectChanges();
      }, (error) => {

        this.isLoading$.next(false);
        this.trendingIcon = false;
        this.cd.markForCheck();
        this.cd.detectChanges();
      })
  }
  getTrendingData() {
    this.categoryService.unbxSuggestion('*', this.unboxUserId).subscribe(data => {
      if (data) {
        //this.searchResult = data?.products;
        //"doctype":  "POPULAR_PRODUCTS", "TOP_SEARCH_QUERIES","IN_FIELD","KEYWORD_SUGGESTION"
        this.searchResult = data?.products?.filter((element: any) => (element?.doctype == 'TOP_SEARCH_QUERIES')); // need to updated trending Doctype
        this.popularProducts = data?.products?.filter((element: any) => (element?.doctype == 'POPULAR_PRODUCTS'));
        this.noResultsFoundMessage = this.searchResult == undefined ? data.noResultsFoundMessage : undefined;
        this.isLoading$.next(false);
        this.trendingIcon = true;
        this.cd.markForCheck();
        this.cd.detectChanges();
      }
    }, (error) => {
      this.isLoading$.next(false);
      this.trendingIcon = false;
      this.cd.markForCheck();
      this.cd.detectChanges();
    })
  }
  navigateToPage(result: any): void {
    if (this.searchQuery.value && this.searchQuery.value.length > 0) {
      this.cClose();
      this.router.resetConfig([result.url]);
      this.router.navigate([result.url]);
    }

  }
  navigateToCategory(result: any): void {
    // if (result != 'all') {
      const url: any = '/c/' + result;
      this.cClose();
      window.location.href = url;
    // }
    // this.router.navigate([url]);


  }
  cClose(): void {
    this.isLoading$.next(true)
  }
  cSearchFocus(): void {
    // this.isLoading$.next(false);
  }

  checkandclose(e: Event) {
    this.cClose();
    setTimeout(() => {
      if (!this.headerSearchContainer?.nativeElement?.contains(document.activeElement)) {
        this.cClose();
      }
    }, 200);
  }
  navigateToSRP(result?: any): void {
    this.cClose();
    //if (this.searchQuery.value && this.searchQuery.value.length > 0) { // commented for empty query
    let url = '/search/' ;
    this.cClose();
    const q = result?.autosuggest ? result?.autosuggest : this.searchQuery.value;
    // triggering Unbxd search impression call here
    this.triggerUnbxdAnalyticsSearchHit();

    if(result && result?.doctype === 'TOP_SEARCH_QUERIES'){
      this.triggerUnbxdAnalyticsOnTopSearchQuery(result);
    }

    this.categoryService.subject.next(result?.autosuggest ? result?.autosuggest : this.searchQuery.value);

    window.location.href = `${url}${q}?currentPage=1`;


    //}
  }
  setParamValInSearch(): void {
    this.route.queryParams.subscribe(params => {
      if (params['q']) {
        this.queryParam = params['q']
      }
    });
  }

  //Search Hit Analytics API:
  triggerUnbxdAnalyticsSearchHit() {
    let visitId = this.unbxdService.getCookieValue('unbxd.visitId') ? this.unbxdService.getCookieValue('unbxd.visitId') : this.unbxdService.getVisitId()


    let param =
    {
      "analyticsRequestData": {
        "query": this.searchQuery.value,
        "url": environment.UIsiteURl + this.router.url,
        "referrer": document.referrer,
        "visitId": visitId,
        "visitType": this.unbxdService.getCookieValue('unbxd.visit'),
        // "pid": ''
      },
      "analyticsParams": [
        {
          "key": "action",
          "value": 'search'
        },
        {
          "key": "uid",
          "value": (this.isUserLoggedIn) ? this.unbxdService.getCookieValue('unbxd.userid') : this.unbxdService.getCookieValue('unbxd.userId')
        }
      ],
      "miscRequestData": {
         "os": "Web"
     }
    }
    if (this.isUserLoggedIn) {
      this.unbxdService.getSearchHitAnalytics(param, false).subscribe((data => {
        // console.log(data, 'Data///')
      }))
    }
    else {
      this.unbxdService.getSearchHitAnalytics(param, true).subscribe((data => {
        // console.log(data, 'Data///')
      }))
    }
  }

  triggerUnbxdAnalyticsOnPopularProducts(result:any) {
    //trigger the 1st search query
    this.triggerUnbxdAnalyticsSearchHit();

    let visitId = this.unbxdService.getCookieValue('unbxd.visitId') ? this.unbxdService.getCookieValue('unbxd.visitId') : this.unbxdService.getVisitId()
    let pid = result?.url ? result?.url.split('p/') : '';
    let param =
    {
      "analyticsRequestData": {
        "query": this.searchQuery.value,
        "url": environment.UIsiteURl + this.router.url,
        "referrer": document.referrer,
        "visitId": visitId,
        "visitType": this.unbxdService.getCookieValue('unbxd.visit'),
        "pid": pid[1],
        "autosuggest_type": result?.doctype,
        'internal_query': this.searchQuery.value
      },
      "analyticsParams": [
        {
          "key": "action",
          "value": 'search'
        },
        {
          "key": "uid",
          "value": (this.isUserLoggedIn) ? this.unbxdService.getCookieValue('unbxd.userid') : this.unbxdService.getCookieValue('unbxd.userId')
        }
      ],
      "miscRequestData": {
         "os": "Web"
     }
    }
    if (this.isUserLoggedIn) {
      this.unbxdService.getSearchHitAnalytics(param, false).subscribe((data => {
        location.href=environment.UIsiteURl+result?.url;
      }))
    }
    else {
      this.unbxdService.getSearchHitAnalytics(param, true).subscribe((data => {
        location.href=environment.UIsiteURl+result?.url;
      }))
    }
  }

  triggerUnbxdAnalyticsOnTopSearchQuery(result:any) {
    let visitId = this.unbxdService.getCookieValue('unbxd.visitId') ? this.unbxdService.getCookieValue('unbxd.visitId') : this.unbxdService.getVisitId()
    let param =
    {
      "analyticsRequestData": {
        "query": this.searchQuery.value,
        "url": environment.UIsiteURl + this.router.url,
        "referrer": document.referrer,
        "visitId": visitId,
        "visitType": this.unbxdService.getCookieValue('unbxd.visit'),
        "autosuggest_type": result?.doctype,
        'internal_query': this.searchQuery.value
      },
      "analyticsParams": [
        {
          "key": "action",
          "value": 'search'
        },
        {
          "key": "uid",
          "value": (this.isUserLoggedIn) ? this.unbxdService.getCookieValue('unbxd.userid') : this.unbxdService.getCookieValue('unbxd.userId')
        }
      ],
      "miscRequestData": {
         "os": "Web"
     }
    }
    if (this.isUserLoggedIn) {
      this.unbxdService.getSearchHitAnalytics(param, false).subscribe((data => {
        // console.log(data, 'Data///')
        location.href=environment.UIsiteURl+result?.url;
      }))
    }
    else {
      this.unbxdService.getSearchHitAnalytics(param, true).subscribe((data => {
        // console.log(data, 'Data///')
        location.href=environment.UIsiteURl+result?.url;
      }))
    }
  }

  getProductImage(data: any) {
    let imageUrl;
    imageUrl = data?.images?.filter((img: any) => img.format == "product");
    return imageUrl;
  }

}

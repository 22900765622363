import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { ProductCategoryService } from 'src/app/core/services/product-category.service';


@Component({
  selector: 'app-custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss']
})
export class CustomCardComponent implements OnInit {

  @Input() items: any;
  isAPIAlreadyCalled: boolean = false;
  productsGridTitle: string = '';
  ctaLink: string = '';
  bannerLink: string = '';
  bannerMedia: any;
  isProductGrid: boolean = false;
  constructor(
    public service: ProductCategoryService,
    public cd: ChangeDetectorRef,
    private cmsComponentData: CmsComponentData<any>,
    private cmsService: CmsService) { }

  ngOnInit(): void {
    this.cmsComponentData.data$.subscribe(data => {
      let componentName = '';
      if (data.name == 'CustomSection2AComponent')
        componentName = 'SacoOfferBanner2GridComponent'

      if (data.name == 'CustomSection2BComponent')
        componentName = "SacoOfferBanner3GridComponent"
      if (data.name == 'CustomSection1DComponent')
        componentName = 'SacoOfferBanner1GridComponent'

      if (data.name == 'CustomSection6AComponent')
      componentName = 'SacoOfferBanner5GridComponent'
      if (data.name == 'CustomSection6CComponent')
      componentName = 'SacoOfferBanner6GridComponent'
      if (data.name == 'CustomSection6DComponent')
      componentName = 'SacoOfferBanner7GridComponent'
      if (data.name == 'CustomSection6BComponent')
        componentName = 'SacoOfferBanner8GridComponent'
      //console.log( 'componentName' ,  data.name, componentName)
      this.cmsService.getComponentData(componentName).subscribe((data: any) => {
        this.isProductGrid = false;
        if (data) {
          this.isAPIAlreadyCalled = true;
          this.productsGridTitle = data?.title;
          this.ctaLink = data?.ctaLink;
          this.cmsService.getComponentData(data.banners).subscribe((data: any) => {
            if (data) {
              this.bannerMedia = data?.media;
              this.bannerLink = data?.urlLink;
              this.cd.markForCheck();
            }
          })
        }
      })
    })
  }

}

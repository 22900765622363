<!-- Updated code-->
<div class="row" *ngIf="bannerMedia && productsGridTitle">
    <div class="col-12 col-sm-12 shop-contentbox">
        <div class="home-page-cartbox mb-3 mb-lg-0">
            <div class="card-box">
                <div class="card-box-inner">
                    <h2 class="product-heading"><a [title]="productsGridTitle" [href]="bannerLink">{{ productsGridTitle }}</a></h2>
                    <a [href]="bannerLink">
                        <cx-media
                        style="height: 380px;"
                        class="text-center"
                        [alt]="productsGridTitle"
                        [container]="bannerMedia"
                        ></cx-media> 
                    </a>
                    <div class="mt-2 text-left see-more-link" [innerHTML]="ctaLink"> </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Updated code-->
<div *ngIf="todayDeals && todayDeals.length > 0" class="home-page-cartbox mb-3 mb-lg-0">
    <div class="card-box">
        <div class="card-box-inner">
            <h3 class="product-heading"> <a [title]="productsGridTitle" [href]="ctaLink">{{productsGridTitle}} </a></h3>
            <div class="row">
                <div class="col-sm-6 col-6 text-center px-1" *ngFor="let item of todayDeals">
                    <ng-container *ngIf="isRecommendationGrid; else nonRecommended">
                        <a [href]="item.productUrl ? item.productUrl : item.url" class="tile-link">
                            <img loading="lazy"
                                [src]="item?.imageUrl ? item?.imageUrl : '../../../../assets/image/no-image.svg'"
                                [alt]="item?.name" class="product-img">
                            <p class="product-name text-truncate-label">{{item?.name || item?.title}} </p>
                        </a>

                    </ng-container>

                    <ng-template #nonRecommended>
                        <a [href]="item.url" class="tile-link">
                            <ng-container *ngIf="item?.images && item?.images[1] else galleryOne">
                                <img loading="lazy" *ngIf="isProductGrid" [src]="item?.images[1]?.url"
                                    [alt]="item?.name" class="product-img">
                            </ng-container>
                            <ng-template #galleryOne>
                                <img loading="lazy" *ngIf="isProductGrid" [src]="item?.imageUrl" [alt]="item?.name"
                                    class="product-img">
                            </ng-template>
                            <!-- <img *ngIf="!isProductGrid && item?.images else imageUrl" [src]="item?.images[0]?.url ? item?.images[0]?.url: '../../../../assets/image/no-image.svg'" [alt]="item?.name" class="product-img"> -->
                            <cx-media lazy="true" *ngIf="!isProductGrid && item?.images else imageUrl"
                                [container]="getProductImage(item)" [alt]="item?.name" class="product-img"></cx-media>
                            <ng-template #imageUrl>
                                <img loading="lazy" [src]="localUrl + item?.imageUrl" [alt]="item?.name"
                                    class="product-img">
                            </ng-template>
                            <p class="product-name text-left text-truncate-label">{{item?.name || item?.title}} </p>
                        </a>

                    </ng-template>
                </div>
                <a class="mt-2 see-more-link" [href]="browsingHistoryUrl != '' ? browsingHistoryUrl : ctaLink"> {{ctaName}} </a>
            </div>
        </div>
    </div>
</div>
<!-- <ng-template #t let-fill="fill">
    <i class="bi-star-fill">
      @if (fill > 0) { <i class="bi-star-fill filled" [style.width.%]="fill"></i> }
    </i>
  </ng-template> -->
<div class="ratings curson-pointer">
  <ng-template #t let-fill="fill">
      <i class="fas fa-star">
          <i *ngIf="fill > 0" class="fas fa-star filled" [style.width.%]="fill"></i>
      </i>
  </ng-template>
  <ngb-rating [(rate)]="ratings" [starTemplate]="t" [readonly]="true" [max]="5" [ariaValueText]="ariaValueText" />
</div>
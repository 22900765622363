
<div class="mincart-main-container d-inline-flex" ngbDropdown (mouseenter)="showDropdown = true" (mouseleave)="showDropdown = false">
    <a href="/cart" [title]="cartListLangConfig.cart" class="minicart-count-container d-sm-block d-xs-block d-lg-none d-xl-none ">
        <img class="curson-pointer" src="../../../../assets/image/cart-icon-white.svg" [alt]="cartListLangConfig?.cart" >
        <ng-container *ngIf="miniCartTotalItems > 0">
            <ng-container *ngIf="miniCartTotalItems > 99 else MoreCartItems">
                <span class="qty-container"> 99+</span> 
            </ng-container>       
            <ng-template #MoreCartItems>
                <span class="qty-container">{{miniCartTotalItems}}</span>
            </ng-template>
        </ng-container>
        <span class="cart-text">
            {{ cartListLangConfig?.cartIconText }}
        </span>
    </a>

    <a href="/cart" [title]="cartListLangConfig.cart" class="minicart-count-container d-sm-none d-xs-none d-lg-block d-xl-block" id="dropdownBasic" ngbDropdownToggle>
        <img class="curson-pointer" [alt]="cartListLangConfig?.cart" src="../../../../assets/image/cart-icon-white.svg" >
        <span class="pl-3"> </span>
        <ng-container *ngIf="miniCartTotalItems > 0">
            <ng-container *ngIf="miniCartTotalItems > 99 else MoreCartItems">
                <span class="qty-container"> 99+</span> 
            </ng-container>       
            <ng-template #MoreCartItems>
                <span class="qty-container">{{miniCartTotalItems}}</span>
            </ng-template>
        </ng-container>
        <span class="cart-text">
            {{ cartListLangConfig?.cartIconText }}
        </span>
    </a>

    <div ngbDropdownMenu aria-labelledby="dropdownBasic"  class="dropdown1-box" [ngClass]="{ 'show d-sm-none d-xs-none d-lg-block d-xl-block': showDropdown }">
        <div class="nav-arrow">
            <div class="arrow-inner"></div>
          </div>
        <ng-container *ngIf="miniCartData?.length > 0; else emptyCartDetails">
            <p class="mb-0 minicart-top-count">
                <span *ngIf="miniCartTotalItems"> {{cartListLangConfig.cart}} ({{miniCartTotalItems}} {{cartListLangConfig.items}})</span>                
            </p>
            <div class="minicart-popover">                
                <div class="row minicart-inner-container mx-0 my-2 p-2" *ngFor="let miniCartListData of miniCartData; let i = index">   
                    <div class="col-4 p-0">
                        <p class="product-image-container pr-2">
                            <ng-container *ngIf="miniCartListData?.product?.images?.length > 0 else noProductImage">
                                <cx-media [container]="getProductImage(miniCartListData?.product)" class="product-cx-media" format="product" [alt]="miniCartListData?.product?.name"></cx-media>
                            </ng-container>
                            <ng-template #noProductImage>
                                <img class="sub-category-noimage" src="../../../../assets/image/no-image.svg" [alt]="miniCartListData?.product?.name">
                            </ng-template>
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="mb-1 truncate-multiline">{{miniCartListData?.product?.name}}</p>
                        <ng-container *ngIf="miniCartListData?.totalPrice?.value != 0 else freeProductText">
                            <p class="minicart-price mb-1">
                                <span class="discount-price">
                                    @if (currencyResponse.currencyMediaURL){
                                        <img class="riyad_symbol" alt="SAR" src="{{currencyResponse.currencyMediaURL}}">
                                    }
                                    @else if(currencyResponse.saudiCurrency) {
                                        <sup>{{currencyResponse.saudiCurrency}}</sup>
                                    }
                                    @else {
                                        <sup>{{cartListLangConfig?.SARCurrency}} </sup>
                                    }
                                    <span [innerHTML]="miniCartListData?.totalPrice?.value | formatDecimalScript"></span>
                                </span>
                            </p>
                          </ng-container>
                          <ng-template #freeProductText>
                            <p class="minicart-price mb-1 text-success"> {{cartListLangConfig.free}}</p>
                          </ng-template>                       
                        <p class="mb-1 minicart-quantity">{{cartListLangConfig.qty}}: {{miniCartListData?.quantity}}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="text-right my-4 minicart-total">{{cartListLangConfig.total}} 
                        <span class="price-label-text">
                            @if (currencyResponse.currencyMediaURL){
                                <img class="riyad_symbol" alt="SAR" src="{{currencyResponse.currencyMediaURL}}">
                            }
                            @else if(currencyResponse.saudiCurrency) {
                                <sup>{{currencyResponse.saudiCurrency}}</sup>
                            }
                            @else {
                                <sup>{{cartListLangConfig?.SARCurrency}} </sup>
                            }
                            <span [innerHTML]="miniCartTotalPrice | formatDecimalScript"></span>
                        </span>                        
                    </p>
                </div>
                <div class="col-12 col-12 border-top-btn pt-4">
                    <a class="btn btn-primary btn-block text-decoration-none cta-btn-primary" [title]="cartListLangConfig.proceedToCart" href="/cart">{{cartListLangConfig.proceedToCart}}</a>
                </div>
            </div>
        </ng-container>
        <ng-template #emptyCartDetails>
            <p class="text-center empty-cart-container mt-3">
                <cx-page-slot position="EmptyCartMiddleContent"></cx-page-slot>
           </p>
        </ng-template>        
    </div>
</div>
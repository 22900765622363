import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDecimalScript'
})
export class FormatDecimalSuperScriptPipe implements PipeTransform {

  transform(n: number): string {
    if (typeof n !== 'number') {
      return n;
		}
    // Convert the number to a string and split it into integer and decimal parts
    let parts = n.toString().split('.');
    let integerPartWithCommas = parseInt(parts[0]).toLocaleString();

    // Check if there's a decimal part
    if (parts.length > 1) {
      let decimalDigit = (parts[1].length === 1) ? parts[1] + '0' : parts[1].slice(0, 2);
      let p = '<sup>' + decimalDigit + '</sup>';
      return integerPartWithCommas + p;
    }
    return integerPartWithCommas;
  }

}

import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CmsService, LanguageService } from '@spartacus/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HeaderService } from 'src/app/core/services/header.service';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { GlobalService } from 'src/app/core/services/global.service';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { BroadcastChannelService } from 'src/app/shared/broadcast-channel.service';
import { CacheService } from 'src/app/core/services/cache.service';
import { MyaccountService } from 'src/app/core/services/myaccount.service';
import { GeoLocationService } from 'src/app/core/services/geo-location.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-location-selection-popup',
  templateUrl: './location-selection-popup.component.html',
  styleUrls: ['./location-selection-popup.component.scss']
})
export class LocationSelectionPopupComponent implements OnInit {
  langConfig: any = EnglishEnum;
  mapLocation: any;
  shortAddressError: boolean = false;
  hasCityDistError: boolean = false;

  regions: any = [];
  selectedLocation: any;
  address_map: boolean = false;
  address_short_address: boolean = false;
  address_manual: boolean = true;
  showMap = false;
  myLat: any = 24.774265; // Inital set for Riyadh
  myLong: any = 46.738586;
  initialCoordinates: any;
  // markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPositions: google.maps.LatLngLiteral[] = [];

  clickedPosition: any = null;

  flatNo: any;
  streetNo: any;
  streetName: any;
  selectedCity: any;
  selectedCityName: any;
  cities: any = [];
  selectedDistrict: any;
  selectedDistrictName: any;
  zipCode: any;
  districts: any = [];
  hasLocation: boolean = false;
  hasShortAddress: boolean = false;
  shortAddress: any = '';
  modalRef: any;
  isLocationSelected = false;
  payLoad: any
  cartId: any;
  isUserLoggedIn: boolean = false;
  hasError: any = null;

  siteLogo: any = '../../../../../assets/image/logo-desktop-white.svg';

  @ViewChild('googleMaps') googleMaps: any;

  @Output() closeLocationPopup = new EventEmitter();
  @Output() disableUnknownLocation = new EventEmitter(false);

  markerOptions: google.maps.MarkerOptions = {
    draggable: false, // Set marker as non-draggable
    position: { lat: this.myLat, lng: this.myLong }
  };


  isLocationAccessBlocked: boolean = false;
  showToast: boolean = false;
  errorMessage: any = null;
  toastMessage: any = null;

  showWaitCursor = new BehaviorSubject<boolean>(true);

  @Input() unknownLocation: boolean = false;

  constructor(
    private cmsService: CmsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private headerService: HeaderService,
    private languageService: LanguageService,
    private localStorageService: LocalStorageService,
    private globalService: GlobalService,
    private cdr: ChangeDetectorRef,
    private broadcastChannelService: BroadcastChannelService,
    private activeCartService: ActiveCartService,
    private cacheService: CacheService,
    private myAccountService: MyaccountService,
    private geoLocationService: GeoLocationService,
    private router: Router
  ) {
    this.getUserLocation();
  }

  ngOnInit(): void {
    this.isUserLoggedIn = this.localStorageService.getValue('isLoggedIn');
    this.showToast = false;
    this.activeCartService.getActive().subscribe(data => {
      // console.log(data, 'cart')
    });
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.langConfig = ArabicEnum;
      }
    })
    this.selectedLocation = this.langConfig.choose_your_location;
    this.clickedPosition = this.markerOptions?.position;
    this.getRegions();
    this.getCities();

    // this.geoLocationService.getPermissionStatus().subscribe((hasPermission) => {
    //   if (hasPermission) {
    //     location.reload();
    //   }
    // });

    // this.geoLocationService.askForLocationPermission()
    //   .then(() => {
    //     if (!this.localStorageService.getValue('locationAccess')) {
    //       this.isLocationAccessBlocked = false;
    //       this.cdr.detectChanges();
    //       this.cdr.markForCheck();
    //     }
    //   })
    //   .catch((error) => {
    //     // User denied location access or an error occurred, handle it here
    //     console.error('Error while obtaining location:', error);
    //     this.showToast = true;
    //     this.toastMessage = null;
    //     this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
    //     this.isLocationAccessBlocked = true;
    //     this.hideTosterPopup();
    //     this.cdr.detectChanges();
    //     this.cdr.markForCheck();
    //   });

  }

  getErrorMessage(error: any): any {
    return (error?.error?.errors && error?.error?.errors[0]?.message) || (error?.error?.errors && error?.error?.errors[0]?.errorMsg) || (error?.error?.errors && error?.error?.errors[0].errorMessage) || error?.error?.errorMsg || error?.error?.errorMessage || error?.error?.message || (error?.error?.errors && error?.error?.errors[0]?.description) || error?.error?.errors?.description || error?.error?.description || (error?.error?.errors && error?.error?.errors[0]?.error_description) || error?.error?.errors?.error_description || error?.error?.error_description;
  }

  onMapDrag(event: any, isDragCompleted: boolean = false): void {
    event?.preventDefault();
    this.clickedPosition = {
      lat: this.googleMaps.getCenter().lat(),
      lng: this.googleMaps.getCenter().lng()
    }
    if (isDragCompleted) {
      this.getCurrentLocation();
    }
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  closeModal() {

    // const city = this.cities.find((city: any) => city.code == this.selectedCity)
    // this.closeLocationPopup.emit((city && city.name) ? city.name : this.headerService.regionSelected);
    // sessionStorage.setItem('SELECTEDCITY', this.selectedCity);
    this.closeLocationPopup.emit('close')
    window.location.reload();
    this.modalService.dismissAll();
  }

  getRegions() {
    this.headerService.getRegionsList().subscribe(
      (data: any) => {
        this.regions = data;
      }
    )
  }

  getCities(): void {
    this.showToast = false;
    this.headerService?.getCityList()?.subscribe(
      (data: any) => {
        if (data) {
          this.cities = data?.cities;
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        } else {
          this.hasCityDistError = true;
        }
      }, (error: any) => {
        this.showToast = true;
        this.toastMessage = null;
        this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
        this.hasCityDistError = true;
        this.hideTosterPopup();
        this.cdr.detectChanges();
        this.cdr.markForCheck();
      }
    )
  }

  updateDistricts(districtCode?: any): void {
    this.selectedDistrict = null; //need to check 
    this.selectedDistrictName = null; // need to check
    this.hasError = false;
    this.showToast = false;
    this.hasCityDistError = false;
    this.cdr.detectChanges();
    this.showWaitCursor.next(true);
    if (this.selectedCity) {
      this.headerService?.getDistrictList(true, this.selectedCity)?.subscribe(
        (data: any) => {
          if (data) {
            this.showWaitCursor.next(false);
            this.districts = data?.districts;

            if (districtCode) {
              this.selectedDistrictName = this.districts?.filter((district: any) => { return district?.code == districtCode })[0]?.name;

              if (this.isNullOrEmpty(this.selectedDistrictName)) {
                this.selectedDistrict = districtCode;
                this.hasCityDistError = true;
                this.hasError = true;
              }
              else {
                this.selectedDistrict = districtCode;
              }

              this.disableUnknownLocation.emit(this.isNullOrEmpty(this.selectedDistrictName));
            }

            this.cdr.detectChanges();
            this.cdr.markForCheck();
          } else {
            this.hasCityDistError = true;
          }
        }, (error: any) => {
          this.showWaitCursor.next(false);
          this.showToast = true;
          this.toastMessage = null;
          this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
          this.hasCityDistError = true;
          this.hideTosterPopup();
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        }
      )
    }
  }

  regionSelection() {
    this.headerService.regionSelected = this.selectedLocation.name
    this.headerService.detectLocation.next(true);
    this.closeModal();
  }

  onAddressChange(address: any): void {
    this.address_manual = false;
    this.address_short_address = false;
    this.address_map = false;
    this.hasLocation = false;
    this.hasShortAddress = false;
    switch (address) {
      case 'address_manual': {
        this.address_manual = true;
        // this.hasLocation = true;
        break;
      }
      case 'address_short_address': {
        this.address_short_address = true;
        // this.hasShortAddress = true;
        break;
      }
      default: {
        this.address_map = true;
        break;
      }
    }
  }

  showMapBox(): void {
    this.showMap = !this.showMap;
    this.hasError = false;
    this.toggleMapSize();
  }

  toggleMapSize(): void {
    if (this.showMap) {
      let elements = document.querySelectorAll(".delivery-location-popup .modal-dialog-centered");
      // let elements = document.getElementsByClassName('delivery-location-popup')
      //console.log(elements)
      if (elements.length > 0) {
        //console.log(elements)
        const element = elements[0] as HTMLElement;
        element.style.minWidth = '50%';
      }
    }
    else {
      let elements = document.querySelectorAll('.delivery-location-popup .modal-dialog-centered')
      if (elements.length > 0) {
        //console.log(elements)
        const element = elements[0] as HTMLElement;
        element.style.minWidth = '25%';
      }
    }
  }

  mapAddressInfo(addressInfo: any): void {
    // console.log(this.cities)
    // console.log(addressInfo)
    this.hasError = false;
    this.flatNo = addressInfo?.flatNo;
    this.streetNo = addressInfo?.line1;
    this.streetName = addressInfo?.line2;
    this.selectedCity = addressInfo?.townId;

    sessionStorage.setItem('SELECTEDCITY', addressInfo?.townId);
    this.localStorageService.setValue('SELECTEDCITY', addressInfo?.townId);
    this.localStorageService.setValue('currentCity', addressInfo?.localizedTown || addressInfo?.town);
    this.selectedCityName = this.cities?.filter((city: any) => { return addressInfo?.townId == city?.code })[0]?.name;
    this.updateDistricts(addressInfo?.districtId);
    this.zipCode = addressInfo?.postalCode;
    this.cdr.detectChanges();
    this.cdr.markForCheck();
  }

  confirmLocation(): void {
    this.showToast = false;
    if (this.address_manual) {
      this.payLoad = {
        townId: this.selectedCity,
        districtId: this.selectedDistrict,
        manualAddress: true
      };
    } else {
      this.payLoad = {
        appartment: this.flatNo || '',
        line1: this.streetNo,
        line2: this.streetName,
        townId: this.selectedCity,
        districtId: this.selectedDistrict,
        postalCode: this.zipCode || ''
      };
    }
    this.hasError = false;

    // console.log(this.selectedDistrictName)
    // console.log('PayLoad : ', this.payLoad);
    const guid = this.localStorageService.getValue('guid') || localStorage.getItem('anonymous_cart_guid');
    if (this.address_manual) {
      this.headerService.selectManualAddress(this.payLoad, guid || null)?.subscribe(
        (res: any) => {
          if (res) {
            this.broadcastChannelService.createChannel('locationUpdated', true);
            this.cacheService.getIsRequestUpdated();
            this.localStorageService.setValue('locationAccess', false);
            this.localStorageService.setValue('SELECTEDCITY', this.selectedCity);

            const city = this.cities.find((city: any) => city.code == this.selectedCity)
            this.closeLocationPopup.emit((city && city.name) ? city.name : this.headerService.regionSelected);
            sessionStorage.setItem('SELECTEDCITY', this.selectedCity);
            this.localStorageService.setValue('currentCity', city.name);
            //this.myAccountService.isLocationSelected.next(res);
            this.getCurrentDeliveryAddress();
            this.globalService.notificationInfo('Location updated successfully!');
            this.headerService.isManualAddress.next(true);
            this.localStorageService.setValue('isLocationUpdated', true);
            this.cdr.detectChanges();
            this.cdr.markForCheck();
            this.closeModal();
          } else {
            this.hasError = true;
          }
        }, (error: any) => {
          console.log('Please select valid address : ', error.error);
          this.hasError = true;
          this.showToast = true;
          this.toastMessage = null;
          this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
          this.hasCityDistError = true;
          this.hideTosterPopup();
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        }
      )
    } else {
      this.headerService.selectAddress(this.payLoad, guid || null)?.subscribe(
        (res: any) => {
          if (res) {
            // console.log('Address Updated successfully!', res);
            this.broadcastChannelService.createChannel('locationUpdated', true);
            this.cacheService.getIsRequestUpdated();
            this.localStorageService.setValue('locationAccess', false);
            this.localStorageService.setValue('SELECTEDCITY', this.selectedCity);
            this.globalService.notificationInfo('Location updated successfully!');
            const city = this.cities.find((city: any) => city.code == this.selectedCity)
            this.localStorageService.setValue('currentCity', city.name);
            this.closeLocationPopup.emit((city && city.name) ? city.name : this.headerService.regionSelected);
            sessionStorage.setItem('SELECTEDCITY', this.selectedCity);
            this.headerService.isLocationSelected.next(res);
            this.localStorageService.setValue('isLocationUpdated', true);
            this.cdr.detectChanges();
            this.cdr.markForCheck();
            this.closeModal();
          } else {
            this.hasError = true;
          }
        }, (error: any) => {
          console.log('Please select valid address : ', error.error);
          this.hasError = true;
          this.showToast = true;
          this.toastMessage = null;
          this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
          this.hasCityDistError = true;
          this.hideTosterPopup();
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        }
      )
    }
  }

  addAddress(): void {
    // console.log(this.clickedPosition, 'this.clickedPosition')
    this.hasError = false;
    if (this.clickedPosition) {
      this.headerService.getAddressLatLan(this.clickedPosition).subscribe(
        (addressInfo: any) => {
          if (addressInfo) {
            this.hasLocation = true;
            this.mapAddressInfo(addressInfo);
            this.isLocationSelected = !this.isLocationSelected;
            this.showMap = false;
            this.toggleMapSize();
          } else {
            this.hasLocation = false;
            this.hasError = true;
          }
        }, (error: any) => {
          this.hasLocation = false;
          this.hasError = true;
          this.showToast = true;
          this.toastMessage = null;
          this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
          this.hasCityDistError = true;
          this.hideTosterPopup();
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        }
      );
    } else {
      this.hasLocation = false;
      this.globalService.notificationError('Please select proper location/address');
    }
  }

  getShortAddress(): void {
    if (this.shortAddress) {
      this.headerService.selectAddressByShortCode(this.shortAddress)?.subscribe(
        (shortAddresInfo: any) => {
          if (shortAddresInfo && !shortAddresInfo?.errorMsg) {
            this.hasShortAddress = true;
            this.mapAddressInfo(shortAddresInfo);
          } else {
            this.shortAddressError = true;
            this.hasShortAddress = false;
          }
        }, (error: any) => {
          this.shortAddressError = true;
          this.hasShortAddress = false;
          this.showToast = true;
          this.toastMessage = null;
          this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
          this.hasCityDistError = true;
          this.hideTosterPopup();
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        }
      )
    } else {
      this.hasShortAddress = false;
      this.shortAddressError = true;
      // this.globalService.notificationError('Please select proper location/address');
    }
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        //  this.myLat = position.coords.latitude;
        //  this.myLong = position.coords.longitude;
        this.initialCoordinates = {
          lat: +this.myLat,
          lng: +this.myLong,
        };
      });
    } else {
      this.globalService.notificationError('Please allow location access');
    }
  }

  getCurrentLocation(): void {
    this.showToast = false;
    this.errorMessage = null;
    this.toastMessage = null;
    this.headerService.getAddressLatLan(this.clickedPosition)?.subscribe(
      (res: any) => {
        if (res) {
          if (!(typeof res?.line1 === undefined || typeof res?.line1 === 'undefined') && !(typeof res?.line2 === undefined || typeof res?.line2 === 'undefined') && !(typeof res?.town === undefined || typeof res?.town === 'undefined')) {
            this.mapLocation = res?.line1 + ' ' + res?.line2 + ' ' + res?.town;
            this.hasError = false;
          } else {
            this.hasError = true;
            this.mapLocation = this.langConfig?.unknownLocationMapDrag;
            this.showToast = true;
            this.toastMessage = null;
            this.errorMessage = this.langConfig?.unknownLocationMapDrag;
            this.hideTosterPopup();
          }
          this.cdr.detectChanges();
          this.cdr.markForCheck();
        }
      }
    );
  }

  addMarker(event: google.maps.MapMouseEvent) {
    this.clickedPosition = event?.latLng?.toJSON();
    this.cdr.detectChanges();
    this.cdr.markForCheck();
    if (this.clickedPosition) {
      this.getCurrentLocation()
      // console.log('Marker here..', this.clickedPosition);
    }
  }

  isNullOrEmpty(value: any): boolean {
    return value == null || value == undefined || value == "" || value == " ";
  }
  getCurrentDeliveryAddress(): void {
    this.cartId = !this.isNullOrEmpty(localStorage.getItem("spartacus⚿sacoStore⚿cart"))
      ? JSON.parse(localStorage.getItem("spartacus⚿sacoStore⚿cart") || "")?.active
      : "";
    if (!this.isNullOrEmpty(this.cartId)) {
      if (this.localStorageService.getValue('isLoggedIn')) {
        this.headerService.getDeliveryAddress(this.cartId)?.subscribe(
          (data: any) => {
            if (data) {
              //console.log('getCurrentDeliveryAddress', data);
              // this.globalService.notificationInfo('Location updated successfuly...!');
              this.headerService.isLocationSelected.next(data);
              this.headerService.defaultAddress.next(true);
              this.headerService.isManualAddress.next(true);
              this.localStorageService.setValue('currentCity', data?.localizedTown || data?.town);
              this.showToast = true;
              this.toastMessage = this.langConfig?.locationUpdatedSuccess;
              this.errorMessage = null;
            } else {
              this.toastMessage = null;
              this.errorMessage = this.langConfig?.locationUpdatedError;
            }
          }, (error: any) => {
            this.showToast = true;
            this.toastMessage = null;
            this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
            this.hasCityDistError = true;
            this.hideTosterPopup();
            this.cdr.detectChanges();
            this.cdr.markForCheck();
          }
        );
      }
      else {
        const guid = this.localStorageService.getValue('guid') || localStorage.getItem('anonymous_cart_guid');
        this.headerService.getDeliveryAddressByguid(guid)?.subscribe(
          (data: any) => {
            if (data) {
              this.headerService.isLocationSelected.next(data);
              this.headerService.isManualAddress.next(true);
              this.showToast = true;
              this.toastMessage = this.langConfig?.locationUpdatedSuccess;
              this.localStorageService.setValue('currentCity', data?.localizedTown || data?.town);
              this.errorMessage = null;
            } else {
              this.toastMessage = null;
              this.errorMessage = this.langConfig?.locationUpdatedError;
            }
          }, (error: any) => {
            this.showToast = true;
            this.toastMessage = null;
            this.errorMessage = this.getErrorMessage(error) || this.langConfig?.applicationError;
            this.hasCityDistError = true;
            this.hideTosterPopup();
            this.cdr.detectChanges();
            this.cdr.markForCheck();
          }
        );
      }
    }
  }

  hasCityAndDistrictSelected(): boolean {
    return !this.isNullOrEmpty(this.selectedCity) && !this.isNullOrEmpty(this.selectedDistrict);
  }
  hideTosterPopup() {
    setTimeout(() => {
      this.showToast = false;
    }, 3000)
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {

      },
      layoutSlots: {
        header: {
          slots: [

          ],
        },
        // navigation: {
        //   slots: [
        //     'NavigationBar'
        //   ]
        // },

        Footer: {
          slots: [
            'Footer'
          ],
        },
        sacoHomepagePageTemplate: {
          slots: [
            // 'Section1',
            // 'Section1A',
            // 'Section2A',
            // 'PromotionalBanner',
            // 'Section3',
            // 'Section4',
            // 'Section5',
            // 'Section6A',
            // 'Section8',
            // 'Section7',
            // 'Section9',
            // 'Section10',
            // 'Section12',
            'CustomHomepageFlexSlotName'
          ],
        },
        sacoLoginPageTemplate: {
          slots: [
            'LoginSiteLogo',
            'CustomSectionFormSlotName',
            'LoginPageLinksSlotName',
            'CustomForgotpasswordSlotName',
            'CustomRegistrationSuccessSlotName',
            'CustomUpdateProfileSlotName',
          ],
        },
        SacoCategoryPageTemplate: {
          slots: [
            'CustomCategoryL1Section',
            'CustomCategoryL2Section',
            'CustomL3Flex',
          ],
        },
        SacoProductListPageTemplate: {
          slots: [
            'ProductLeftRefinements',
            'CustomProductList'
          ],
        },
        SacoSearchResultsListPageTemplate: {
          slots: [
            'CustomSearchList',
            //'CustomSacoClub',
            'CustomSacoWallet',
            'CustomSacoCard',
            'CustomSacoLoyalty', 
          ],
        },
        SacoProductDetailsPageTemplate: {
          slots: [
            'CustomProductComponentSlotName',
            'CustomProductsComparisonSlotName',
            'CrossSelling',
            'ProductWidget1SlotName',
          ],
        },
        SacoCartPageTemplate: {
          slots: [
            'CustomCartComponentSlotName',
            'CartWidget1SlotName',
            'CartWidget2SlotName'
          ],
        },
        SacoAccountPageTemplate: {
          slots: [
            'CustomAccountSlotName'
          ],
        },
        SacoCheckoutPageTemplate: {
          slots: [
            'CustomCheckoutComponentSlotName'
          ]
        },
        SacoOrderConfirmationPageTemplate: {
          slots: [
            'CustomOrderConfirmationSlotName',
            'ProductWidget2SlotName',
            'ProductWidget3SlotName'
          ]
        },
        sacoGiftCardPageTemplate: {
          slots: [
            'CustomGiftCard',
            'CustomPrivacyPolicy',
            'CustomLoyaltyProgram',
            'CustomReturnExchange',
            'CustomAboutUs',
            'CustomFAQ',
            'CustomTermsCondition',
            'CustomStoreFinder',
            'CustomDeliveryInstallation',
            'CustomContactUs',
            'CustomCareerForm',
            'CustomInvestorRelation',
            'CustomReports',
            'CustomSacoClub',
            'CustomNews',
            'CustomSalesFinderSlotName',
            'CustomNotFoundSlotName',
            'CustomBrandSlotName',
            'CustomOneDayDeliverySlotName',
            'SacoAljarahiFlexComponentName',
            'sacooffersFlexSlotName',
            'backToSchoolFlexSlotName',
            'Policy1Flex', //delivery-service
            'Policy2Flex', //maintenance-policy
            'Policy3Flex', //return-service
            'Section4SlotD',
            'CustomSiteMap',
            'moneybackflex',
            'licenseFlex',
            'contentPageComponent',
            'imagePageFlex',
            'leftrightflex',
          ]
        },
        surveyLinkPageTemplate:{
          slots:[
            'surveyLinkSlotName'
          ]
        }
      },
    } as LayoutConfig),

  ],
})
export class CustomLayoutRoutingModule { }

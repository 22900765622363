import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { RatingsComponent } from './ratings.component';


@NgModule({
  declarations: [RatingsComponent],
  imports: [
    CommonModule,
    NgbRatingModule,
  ],
  exports: [RatingsComponent]
})
export class RatingsModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoyaltyCartRegistrationComponent } from './loyalty-cart-registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsModule } from '../notifications/notifications.module';



@NgModule({
  declarations: [
    LoyaltyCartRegistrationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationsModule,
  ],
  exports:[
    LoyaltyCartRegistrationComponent
  ]
})
export class LoyaltyCartRegistrationModule { }

<div class="loading" *ngIf="showWaitCursor | async ">Loading&#8230;</div>
<div class="search-div">
    <div
        class="custom-header-location mt-1  d-lg-block d-xl-block show-small-device">
        <app-custom-location></app-custom-location>
    </div>
    <div class="d-flex special-width" role="search" aria-label="Find a product">
        <div class="search-dropdown d-sm-none d-xs-none d-lg-block d-xl-block">
            <!-- [(ngModel)]="categorySelected" -->
            <select [formControl]="searchCategory"
                (change)="navigateToCategory(searchCategory.value)">
                <option value="MC">{{langConfig.all}}</option>
                <option [value]="item?.id"
                    *ngFor="let item of searchDropwDownOptions?.subcategories">{{item?.name}}
                </option>
            </select>
        </div>
        <div class="searchbox-label">
            <!-- Search API-->
            <!-- (input)="cSearch(); -->
            <input [formControl]="searchQuery" (input)="getSuggestions();"
                (keydown.escape)="cClose();"
                (click)="getTrendingData()"
                (keydown.enter)="navigateToSRP();checkandclose($event)"
                (focus)="cSearchFocus()" class="search-input"
                autocomplete="off" aria-describedby="initialDescription"
                aria-controls="results"
                placeholder="{{langConfig.headerSearchPlaceholder}}">
            <img class="search-btn" src="./assets/image/search-new-yellow.svg"
                alt="Search"
                (click)="navigateToSRP()">
            <div class="search-results"
                *ngIf="!(isLoading$ | async)"
                #headerSearchContainer>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <ng-container *ngIf="!noResultsFoundMessage">
                            <ng-container
                                *ngIf="trendingIcon else keywordSuggestion">
                                <h5>{{langConfig?.TrendingSearches}}</h5>
                            </ng-container>
                            <ng-template #keywordSuggestion>
                                <h5>{{langConfig?.KeywordSuggestion}}</h5>
                            </ng-template>
                        </ng-container>
                        <ul class="auto-suggestion-container">
                            <li *ngFor="let result of searchResult"
                                class="d-flex py-1 border-bottom">
                                <!-- <span *ngIf="trendingIcon"> <img src="../../../../assets/image/trending-icon.svg" alt="Trending Icon" class="px-2 rounded-0"></span> -->
                                <span (click)="navigateToSRP(result)">
                                    {{result?.autosuggest}}
                                </span>
                            </li>
                            <li *ngIf="noResultsFoundMessage">
                                {{noResultsFoundMessage}} </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-8">
                        <h5
                            *ngIf="!noResultsFoundMessage">{{langConfig?.PopularProducts}}</h5>
                        <div class="row popularproducts-container"
                            *ngIf="popularProducts">
                            <div class="col-12 col-md-4 px-2 my-2"
                                *ngFor="let productList of popularProducts">
                                <div class="card p-2">
                                    <p
                                        class="search-image-container mb-1 text-center">
                                        <a [title]="productList?.name"
                                            (click)="triggerUnbxdAnalyticsOnPopularProducts(productList)">
                                            <ng-container
                                                *ngIf="productList?.images?.length > 0 else noProductImage">
                                                <cx-media
                                                    [container]="getProductImage(productList)"
                                                    class="product-media-image"
                                                    format="product"
                                                    [alt]="productList?.name"></cx-media>
                                            </ng-container>
                                            <ng-template #noProductImage>
                                                <img
                                                    class="search-category-noimage rounded-0"
                                                    src="../../../../assets/image/no-image.svg"
                                                    [alt]="productList?.name">
                                            </ng-template>
                                        </a>
                                        <span class="trending-label"
                                            *ngIf="productList?.shortPromoTexts">({{productList?.shortPromoTexts[0]}})</span>
                                    </p>
                                    <p *ngIf="productList?.name" class="mb-1">
                                        <a class="text-truncate-label"
                                            [title]="productList?.name"
                                            (click)="triggerUnbxdAnalyticsOnPopularProducts(productList)">
                                            {{productList?.name}}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="result" *ngFor="let result of searchResult" [innerHTML]="result?.name" (click)="navigateToPage(result)"></div> -->
            </div>
            <ng-template #noResults>
                <!-- <ul class="auto-suggestion-container">
            <li *noResultsFoundMessage> {{noResultsFoundMessage}} </li>
            </ul> -->
            </ng-template>
        </div>
    </div>
    <app-language-selection></app-language-selection>
    <div class="saco-club-link">
        <a href="/loyalty/join-now">{{ langConfig?.sacoClub }}</a>
    </div>
    <div class="mini-cart mt-2 mr-0">
        <cx-page-slot position="MiniCart"></cx-page-slot>
    </div>
    <!-- <div class="mini-cart mt-2">
    <cx-page-slot position="MiniCart"></cx-page-slot>
</div> -->
    <!-- <div 
    class="custom-header-location mr-4 mt-1 d-sm-none d-xs-none d-lg-block d-xl-block"  >
    <app-custom-account></app-custom-account>
</div> -->
</div>
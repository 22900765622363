import { Component, Input } from '@angular/core';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss']
})
export class RatingsComponent {

  @Input() ratings: any = 4;

  ariaValueText(current: number, max: number) {
    return `${current} out of ${max} hearts`;
  }
}

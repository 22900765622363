export const environment =  {
  production: true,
  occBaseUrl:'https://api.cj8mhwb-saudicomp2-p2-public.model-t.cc.commerce.ondemand.com/',
  baseSiteId:"occ/v2/sacoStore/",
  //UIsiteURl: 'https://jsapps.cj8mhwb-saudicomp2-p2-public.model-t.cc.commerce.ondemand.com/',
  UIsiteURl: 'https://www.saco.sa/',
  clientId: 'eic',
  clientSecret: 'secret',
  googleClientId: '896257414441-mtffpfq5ttun02sqma9tvdp16fe6uog5.apps.googleusercontent.com',
  googleApiKey: 'AIzaSyC1-U_t65fCMrHUYT0Rk69OlPMV6cJV1Ws',
  smartEditAllowOrigin: 'api.cj8mhwb-saudicomp2-p2-public.model-t.cc.commerce.ondemand.com:443, checkout.tabby.ai:443',
  tamaraPublicKey: 'dd0a72e6-627b-11eb-a5ac-02001700e43e',
  tabbyPublicKey:'pk_test_30598a51-94b1-4ef9-b269-af24fcc120c9',
  // Arabic file should be always at first place 
  testFreaks: [
    'https://js.testfreaks.com/onpage/saco.sa-ar/head.js',
    'https://js.testfreaks.com/onpage/saco.sa-en/head.js'
  ],
  companyName: 'SACO Hardware & Retail Company',
}
//P2 Environment Details
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [CommonModule,],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        Backtoschool: {
          module: () =>
            import('../../app/pages/backtoschool/backtoschool.module').then(
              (m) => m.BacktoschoolModule
            ),
          cmsComponents: [
            'backToSchoolFlexComponent',
          ],
        },
        SurveyLink: {
          module: () =>
            import('../../app/pages/survey-link/survey-link.module').then(
              (m) => m.SurveyLinkModule
            ),
          cmsComponents: [
            'surveyLinkComponent',
          ],
        },
        Promo: {
          module: () =>
            import('../../app/pages/promo/promo.module').then(
              (m) => m.PromoModule
            ),
          cmsComponents: [
            'sacooffersFlexComponent',
          ],
        },
        SacoTerm: {
          module: () =>
            import('../../app/pages/alrajhi-terms-condition/alrajhi-terms-condition.module').then(
              (m) => m.AlrajhiTermsConditionModule
            ),
          cmsComponents: [
            'SacoAljarahiFlexComponent',
          ],
        },
        OnedayDelivery: {
          module: () =>
            import('../../app/pages/oneday-delivery/oneday-delivery.module').then(
              (m) => m.OnedayDeliveryModule
            ),
          cmsComponents: [
            'CustomOneDayDeliveryComponent',
          ],
        },
        Brands: {
          module: () =>
            import('../../app/pages/brands/brands.module').then(
              (m) => m.BrandsModule
            ),
          cmsComponents: [
            'CustomShopBrandComponent',
          ],
        },
        PagenotFound: {
          module: () =>
            import('../../app/pages/pagenotfound/pagenotfound.module').then(
              (m) => m.PagenotfoundModule
            ),
          cmsComponents: [
            'CustomNotFoundComponent',
          ],
        },
        SalesFinder: {
          module: () =>
            import('../../app/pages/sales-finder/sales-finder.module').then(
              (m) => m.SalesFinderModule
            ),
          cmsComponents: [
            'CustomSalesFinderComponent',
          ],
        },
        NewsModule: {
          module: () =>
            import('../../app/pages/news/news.module').then(
              (m) => m.NewsModule
            ),
          cmsComponents: [
            'CustomNewsComponent',
          ],
        },
        UpdateProfile: {
          module: () =>
            import('../../app/pages/update-profile/update-profile.module').then(
              (m) => m.UpdateProfileModule
            ),
          cmsComponents: [
            'CustomUpdateProfileComponent',
          ],
        },
        RegistrationSuccessful: {
          module: () =>
            import('../../app/pages/registration-successful/registration-successful.module').then(
              (m) => m.RegistrationSuccessfulModule
            ),
          cmsComponents: [
            'CustomRegistrationSuccessComponent',
          ],
        },
        ReportsMod: {
          module: () =>
            import('../../app/pages/reports/reports.module').then(
              (m) => m.ReportsModule
            ),
          cmsComponents: [
            'CustomReportsComponent',
          ],
        },
        InvestorRelations: {
          module: () =>
            import('../../app/pages/investor-relations/investor-relations.module').then(
              (m) => m.InvestorRelationsModule
            ),
          cmsComponents: [
            'CustomInvestorRelationsComponent',
          ],
        },
        CareersMod: {
          module: () =>
            import('../../app/pages/careers/careers.module').then(
              (m) => m.CareersModule
            ),
          cmsComponents: [
            'CustomCareerFormComponent',
          ],
        },
        ContactUsMod: {
          module: () =>
            import('../../app/pages/contact-us/contact-us.module').then(
              (m) => m.ContactUsModule
            ),
          cmsComponents: [
            'CustomContactUsComponent',
          ],
        },
        DeliveryInstallations: {
          module: () =>
            import('../../app/pages/delivery-installations/delivery-installations.module').then(
              (m) => m.DeliveryInstallationsModule
            ),
          cmsComponents: [
            'CustomDeliveryInstComponent',
          ],
        },
        StoreLocator: {
          module: () =>
            import('../../app/pages/store-locator/store-locator.module').then(
              (m) => m.StoreLocatorModule
            ),
          cmsComponents: [
            'CustomStoreFinderComponent',
          ],
        },
        FaqMod: {
          module: () =>
            import('../../app/pages/faq/faq.module').then(
              (m) => m.FaqModule
            ),
          cmsComponents: [
            'CustomFAQComponent',
          ],
        },
        ReturnsExchange: {
          module: () =>
            import('../../app/pages/returns-exchange/returns-exchange.module').then(
              (m) => m.ReturnsExchangeModule
            ),
          cmsComponents: [
            'CustomReturnExchangeComponent',
          ],
        },
        TermsConditions: {
          module: () =>
            import('../../app/pages/terms-conditions/terms-conditions.module').then(
              (m) => m.TermsConditionsModule
            ),
          cmsComponents: [
            'CustomTermsConditionComponent',
          ],
        },
        LoyaltyProgram: {
          module: () =>
            import('../../app/pages/loyalty-program/loyalty-program.module').then(
              (m) => m.LoyaltyProgramModule
            ),
          cmsComponents: [
            'CustomLoyaltyProgramComponent',
          ],
        },
        AboutUsModule: {
          module: () =>
            import('../../app/pages/about-us/about-us.module').then(
              (m) => m.AboutUsModule
            ),
          cmsComponents: [
            'CustomAboutUsComponent',
          ],
        },
        PrivacyPolicy: {
          module: () =>
            import('../../app/pages/privacy-policy/privacy-policy.module').then(
              (m) => m.PrivacyPolicyModule
            ),
          cmsComponents: [
            'CustomPrivacyPolicyComponent',
          ],
        },
        GiftCard: {
          module: () =>
            import('../../app/pages/gift-card/gift-card.module').then(
              (m) => m.GiftCardModule
            ),
          cmsComponents: [
            'CustomGiftCardComponent',
          ],
        },
        Checkout: {
          module: () =>
            import('../../app/pages/checkout/checkout.module').then(
              (m) => m.CheckoutModule
            ),
          cmsComponents: [
            'CustomCheckoutComponent',
            'CustomPayfortComponent',
            'CustomPayfortSOPComponent',
            'CustomTabbyComponent',
            'CustomPayfortCancelComponent',
            'CustomPayfortFailureComponent',
          ],
        },
        OrderConfirmation: {
          module: () =>
            import('../../app/pages/order-confirmation/order-confirmation.module').then(
              (m) => m.OrderConfirmationModule
            ),
          cmsComponents: [
            'CustomOrderConfirmationComponent',
            'ProductWidget3FlexComponent',
            'Productwidget2FlexComponent',

          ],
        },

        MyAccountMod: {
          module: () =>
            import('../../app/pages/my-account/my-account.module').then(
              (m) => m.MyAccountModule
            ),
          cmsComponents: [
            'CustomMyAccountComponent',
            'CustomSacoCardComponent',
            'CustomSacoLoyaltyComponent',

          ],
        },
        SacoClubMod: {
          module: () =>
            import('../../app/pages/saco-club/saco-club.module').then(
              (m) => m.SacoClubModule
            ),
          cmsComponents: [
            'CustomSacoClubComponent',
          ],
        },
        CartPage: {
          module: () =>
            import('../../app/pages/cart-page/cart-page.module').then(
              (m) => m.CartPageModule
            ),
          cmsComponents: [
            'CustomCartPageComponent',
            'CartWidget1FlexComponent',
            'CartWidget2FlexComponent',
          ],
        },
        ProductsComparison: {
          module: () =>
            import('../../app/pages/products-comparison/products-comparison.module').then(
              (m) => m.ProductsComparisonModule
            ),
          cmsComponents: [
            'CustomProductsComparison',
          ],
        },
        SearchResultsPage: {
          module: () =>
            import('../../app/pages/srp/search-results-page/search-results-page.module').then(
              (m) => m.SearchResultsPageModule
            ),
          cmsComponents: [
            'CustomSearchListComponent',
            'ProductWidget1FlexComponent',
            'ProductWidget3FlexComponent',
          ],
        },
        ProductDetailPage: {
          module: () =>
            import('../../app/pages/pdp/product-detail-page/product-detail-page.module').then(
              (m) => m.ProductDetailPageModule
            ),
          cmsComponents: [
            'CustomProductDetailComponent',
            'ProductWidget1FlexComponent',
            'ProductWidget3FlexComponent',
          ],
        },
        ProductListing: {
          module: () =>
            import('../../app/pages/plp/product-listing-page/product-listing-page.module').then(
              (m) => m.ProductListingPageModule
            ),
          cmsComponents: [
            'CustomProductListComponent',
            'ProductWidget1FlexComponent',
            'ProductWidget3FlexComponent',
          ],
        },
        CategoryLevel2Page: {
          module: () =>
            import('../../app/pages/clp/category-level2-page/category-level2-page.module').then(
              (m) => m.CategoryLevel2PageModule
            ),
          cmsComponents: [
            'CustomCategoryL2Component',
          ],
        },
        CategoryLevel1Page: {
          module: () =>
            import('../../app/pages/clp/category-level1-page/category-level1-page.module').then(
              (m) => m.CategoryLevel1PageModule
            ),
          cmsComponents: [
            'CustomCategoryL1Component',
          ],
        },
        CustomCategoryPage : {
          module: () =>
            import('../../app/pages/clp/custom-clp/custom-clp.module').then(
              (m) => m.CustomClpModule
            ),
          cmsComponents: [
            'CustomL3FlexComponent',
            ],
        },
        ShopTheStore : {
          module: () =>
            import('../../app/custom-components/shop-the-store/shop-the-store.module').then(
              (m) => m.ShopTheStoreModule
            ),
          cmsComponents: [
            'CustomShopStoreComponent',
            'CustomSmallApplianceComponent',
          ],
        },
        ForgotPassword: {
          module: () =>
            import('../../app/custom-components/custom-forgot-password/custom-forgot-password.module').then(
              (m) => m.CustomForgotPasswordModule
            ),
          cmsComponents: [
            'ForgotPasswordComponent',

          ],
        },
        CustomCreateAccount: {
          module: () =>
            import('../../app/custom-components/custom-create-account/custom-create-account.module').then(
              (m) => m.CustomCreateAccountModule
            ),
          cmsComponents: [
            'CustomLoginFormComponent',

          ],
        },
        HomePageMod: {
          module: () =>
            import('../../app/pages/home-page/home-page.module').then(
              (m) => m.HomePageModule
            ),
          cmsComponents: [
            'CustomHomeFlexComponent',
            'AutomotiveBSComponent',
            'SmallAppliancesComponent',
            'NewAppliancesComponent',
            'TopDealsComponent',
            'BrowsingHistoryComponent',
            'CustomTopRatedTitleComponent',
            'RecommendationComponent',
            'CustomSmallApplianceComponent',
          ],
        },
        Deliveryservice: {
          module: () =>
            import('../../app/pages/deliveryservice/deliveryservice.module').then(
              (m) => m.DeliveryserviceModule
            ),
          cmsComponents: [
            'CustomPolicy1Component',
          ],
        },
        MaintenancePolicy: {
          module: () =>
            import('../../app/pages/maintenance-policy/maintenance-policy.module').then(
              (m) => m.MaintenancePolicyModule
            ),
          cmsComponents: [
            'CustomPolicy2Component',
          ],
        },
        ReturnService: {
          module: () =>
            import('../../app/pages/return-service/return-service.module').then(
              (m) => m.ReturnServiceModule
            ),
          cmsComponents: [
            'CustomPolicy3Component',
          ],
        },
        SiteMap: {
          module: () =>
            import('../../app/pages/site-map/site-map.module').then(
              (m) => m.SiteMapModule
            ),
          cmsComponents: [
            'CustomSiteMapFlexComponent',
          ],
        },
        Moneyback: {
          module: () =>
            import('../../app/pages/money-back/money-back.module').then(
              (m) => m.MoneyBackModule
            ),
          cmsComponents: [
            'moneyBackGuaranteeflexComponent',
          ],
        },
        CustomOnedayDelivery: {
          module: () =>
            import('../../app/pages/custom-oneday-delivery/custom-oneday-delivery.module').then(
              (m) => m.CustomOnedayDeliveryModule
            ),
          cmsComponents: [
            'customOneDayDeliveryFlexComponent',
            ],
        },
        licensePage: {
          module: () =>
            import('../../app/pages/license/license.module').then(
              (m) => m.LicenseModule
            ),
          cmsComponents: [
            'licensePageFlexComponent',
          ],
        },
        contentPage: {
          module: () =>
            import('../../app/pages/content-page/content-page.module').then(
              (m) => m.ContentPageModule
            ),
          cmsComponents: [
            'contentPageFlexComponent',
          ],
        },
        imagePage: {
          module: () =>
            import('../../app/pages/image-page/image-page.module').then(
              (m) => m.ImagePageModule
            ),
          cmsComponents: [
            'imagePageFlexComponent',
          ],
        },
        leftRightPage: {
          module: () =>
            import('../../app/pages/left-right-page/left-right-page.module').then(
              (m) => m.LeftRightPageModule
            ),
          cmsComponents: [
            'leftRightcontentFlexComponent',
          ],
        },
      },
    }),
  ],
})
export class SacoFeatureModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCarouselComponent } from './custom-carousel.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomCardModule } from '../custom-card/custom-card.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CarouselModule, MediaModule } from '@spartacus/storefront';



@NgModule({
  declarations: [
    CustomCarouselComponent
  ],
  imports: [
    CommonModule,
    NgbCarouselModule,
    CustomCardModule,
    CarouselModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CustomSection1AComponent: {
          component: CustomCarouselComponent
        },
        CustomSection1BComponent: {
          component: CustomCarouselComponent
        },
        CustomSection1CComponent: {
          component: CustomCarouselComponent
        },        
        CustomSection2CComponent: {
          component: CustomCarouselComponent
        },
        CustomSection2DComponent: {
          component: CustomCarouselComponent
        },
        // CustomSection6BComponent: {
        //   component: CustomCarouselComponent
        // },
      },
    })
  ],
  exports:[
    CustomCarouselComponent,
    CustomCardModule
  ]
})
export class CustomCarouselModule { }

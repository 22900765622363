import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, delay, mergeMap, tap } from 'rxjs/operators';

import { CacheService } from '../services/cache.service';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private cache: any;
  private isAPICalling = false;

  constructor(
    private cacheService: CacheService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request is cacheable (e.g., GET method)
    if (!this.localStorageService.getValue('isLoggedIn') &&
      (location?.pathname?.includes('checkout') ||
        location?.pathname?.includes('order-confirmation') ||
        location?.pathname?.includes('account'))
    ) {
      this.router.navigate(['/login']);
    }

    if (!this.localStorageService.getValue('isLoggedIn') && request.url.includes('occ/v2/sacoStore/users/current/sacocarts')) {
      return of();
    }

    // //acoStore/sacoUsers/current
    // if (request.url.includes('sacoUsers/current') && !request.url.includes('digitalwalletbalance') && request?.method?.toLocaleUpperCase() !== 'POST') {
    //   if (this.isAPICalling) {
    //     return EMPTY; // Return empty observable to avoid multiple calls
    //   }
    //   this.isAPICalling = true;
    // }

    if ((request?.url?.includes('users/anonymous/carts') || request?.url?.includes('/current')) && location.href.includes('/login')) {
      return next.handle(request);
    }

    if (this.localStorageService?.getValue("anonymous") && request?.url?.includes('users/anonymous/carts')) {
      this.localStorageService.removeValue("anonymous");
    } else if (request?.url?.includes('users/anonymous/carts') && request.method == 'GET') {
      return new Observable<HttpEvent<any>>();
    }

    if (request.method !== 'GET') {
      return next.handle(request);
    }

    // Check if the request has cache headers to bypass caching
    if (request.headers.get('Cache-Control') === 'no-cache') {
      return next.handle(request);
    }

    // Check if the response is already in the cache
    const cachedResponse = this.cache?.get(request.urlWithParams);
    if (cachedResponse && !this.cacheService.getIsRequestUpdated()) {
      // Return the cached response as an observable
      console.log('Cached Response : ', cachedResponse);
      return of(cachedResponse);
    } else {
      this.cacheService.requestUpdateDone();
      this.cache?.forEach((item: any, index: any) => {
        if (item.key == request?.urlWithParams) {
          delete this.cache[index];
        }
      })
    }

    // If not in cache, continue with the request and cache the response
    // return next.handle(request).pipe(
    //   tap((event: any) => {
    //     if (event instanceof HttpResponse) {
    //       // Cache the response for future use
    //       this.cache?.set(request.urlWithParams, event.body);
    //     }
    //   })
    // );

    return this.debounce(() => next.handle(request), 300);
  }

  // Function to debounce API calls
  private debounce(func: () => Observable<any>, delayTime: number): Observable<any> {
    return new Observable(observer => {
      let timeoutId: any;
      const subscription = func().subscribe(
        value => observer.next(value),
        error => observer.error(error),
        () => observer.complete()
      );

      return () => {
        clearTimeout(timeoutId);
        subscription.unsubscribe();
      };
    }).pipe(
      delay(delayTime),
      mergeMap(result => of(result)),
      catchError((error) => {
        console.error('Error occurred during debounced function execution:', error);
        return throwError(error);
      })
    );
  }
}

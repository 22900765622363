import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MyaccountService } from 'src/app/core/services/myaccount.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { EnglishEnum } from 'src/app/core/constants/en';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { LocationSelectionPopupComponent } from 'src/app/custom-components/custom-header/custom-location/location-selection-popup/location-selection-popup.component';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { HeaderService } from 'src/app/core/services/header.service';
@Component({
  selector: 'app-delivery-location',
  templateUrl: './delivery-location.component.html',
  styleUrls: ['./delivery-location.component.scss']
})
export class DeliveryLocation implements OnInit {
  isUserLoggedIn: boolean = false;
  constructor(
    public cd: ChangeDetectorRef,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private myAccountService: MyaccountService,
    private globalService: GlobalService,
    public languageService: LanguageService,
    private localStorageService: LocalStorageService,
    private headerService: HeaderService
  ) { }
  @Output() addressUpdated = new EventEmitter();
  @Input() isHeaderLocation: boolean = false;
  modalRef: any;
  langConfig: any = EnglishEnum;
  showWaitCursor = new BehaviorSubject<boolean>(true);
  isLoading: boolean = false;
  userAddress: any
  selectedAddress = 0;

  showToast: boolean = false;
  errorMessage: any = null;
  toastMessage: any = null;


  ngOnInit(): void {
    this.languageService.getActive().subscribe((lang) => {
      if (lang == 'ar') {
        this.langConfig = ArabicEnum;
      }
    })
    this.isUserLoggedIn = this.localStorageService.getValue('isLoggedIn');
    if (this.isUserLoggedIn != undefined) {
      this.showWaitCursor.next(true);
      this.currentAddress();
    }

  }
  currentAddress(): void {
    this.myAccountService.currentAddress().subscribe((data: any) => {
      if (data?.addresses) {
        this.userAddress = data?.addresses;
        this.showWaitCursor.next(false);
      }
    },
      (error: any) => {
        this.globalService.notificationInfo('Error occurred, please try later');
        this.showWaitCursor.next(false);
        this.showToast = true;
        this.toastMessage = null;
        this.errorMessage = this.langConfig?.locationUpdatedError;
      })
  }

  selectAddress(index: number): void {
    this.selectedAddress = index;
    this.makeAddressDefault(this.userAddress[index]?.id, this.userAddress[index])
  }

  closeModal() {
    this.localStorageService.setValue('isLocationAddressSet', true);
    this.addressUpdated.emit('Close');
    this.modalService.dismissAll();
  }

  moreOptions(): void {
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(LocationSelectionPopupComponent, {
      windowClass: 'delivery-location-popup',
      centered: true,
      size: 'md',
    });
  }

  isNullOrEmpty(value: any): boolean {
    return value == null || value == undefined || value == "" || value == " ";
  }

  makeAddressDefault(id: any, selectedaddressData?: any): void {
    let payLoad = {
      "defaultAddress": true
    }


    this.myAccountService.makeDefaultAddress(id, payLoad).subscribe(
      (data: any) => {
        //commenting the below if condition for time being as API is not giving any response, however, its 200 success
        // if (data) {
        if (this.isHeaderLocation) {

          const addressPayload = {
            appartment: selectedaddressData?.flatNo || '',
            line1: selectedaddressData?.line1,
            line2: selectedaddressData?.line2,
            townId: selectedaddressData?.townId,
            districtId: selectedaddressData?.districtId,
            postalCode: selectedaddressData?.postalCode || ''
          };

          this.localStorageService.setValue('isLocationUpdated', true);
          this.headerService.detectLocation.next(true);
          this.headerService.regionSelected = selectedaddressData?.town + (selectedaddressData?.postalCode ? ' ' + selectedaddressData?.postalCode : '');

          this.showToast = true;
          this.toastMessage = this.langConfig?.locationUpdatedSuccess;
          this.errorMessage = null;
        }
        this.showWaitCursor.next(false);
        this.cd.detectChanges();
        this.cd.markForCheck();
        this.addressUpdated.emit(id);
        this.modalService.dismissAll();
        // }
      },
      (error: any) => {
        this.globalService.notificationInfo('Error occurred, please try later');
        this.showWaitCursor.next(false);
        this.showToast = true;
        this.toastMessage = null;
        this.errorMessage = this.langConfig?.locationUpdatedError;
      });
  }
}

import { AfterContentChecked, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnChanges, AfterContentChecked {

  @Input() showToast: boolean = false;
  @Input() hasAutoHide: boolean = true;
  @Input() toastMessage: string = '';
  @Input() errorMessage: string = '';
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(): void {
  }
  ngAfterContentChecked(): void {
  }

  closeToast() {
    this.showToast = false;
    return;
  }

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ArabicEnum } from 'src/app/core/constants/ar';
import { EnglishEnum } from 'src/app/core/constants/en';
import { LanguageService } from '@spartacus/core';
import { CommonUtils } from 'src/app/core/utils/utils';
//import { available } from "@nativescript/appavailability";
//import * as appavailability from "@nativescript/appavailability";

@Component({
  selector: 'app-header-app-notification',
  templateUrl: './header-app-notification.component.html',
  styleUrls: ['./header-app-notification.component.scss']
})
export class HeaderAppNotificationComponent implements OnInit {

  isMobile: boolean = false;
  findMobileDevice: any;
  langConfig: any = EnglishEnum;
  isPopupShow: boolean = false;
  //isAppAvailable: boolean = false;
  constructor(
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    public platform: Platform
  ){

  }
ngOnInit(): void {
  this.languageService.getActive().subscribe((lang) => {
    if (lang == 'ar') {
      this.langConfig = ArabicEnum;
    }
  })
  this.findMobileDevice =  this.platform.ANDROID || this.platform.IOS;
  if (!this.isNullOrEmpty(this.localStorageService.getValue('isOpenOnApp'))) {
    this.isPopupShow=true;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
  this.onPageRefresh();
  // if(this.platform.ANDROID){
  //   appavailability.available("sa.saco.mobile").then((avail:any)=> {
  //     this.isAppAvailable=true;
  //     alert("Android App available? " + avail);
  //   })
  // } else if(this.platform.IOS){
  //   appavailability.available("saco://").then((avail: boolean) => {
  //     this.isAppAvailable=true;
  //     alert("iOS App available? " + avail);
  //   })
  // }

  }
  navigateOnAndroid() {
    const appPackageName = 'sa.saco.mobile';
    const playStoreUrl = 'https://play.google.com/store/apps/details?id=' + appPackageName;
    const intentUrl = `intent://#Intent;scheme=saco;package=${appPackageName};end`;
    window.location.href = intentUrl;
    setTimeout(() => {
      window.location.href = playStoreUrl;
    }, 2000); 
  }
  navigateOniOS() {
    const playStoreUrl = 'https://apps.apple.com/us/app/saco-ساكو/id1532690353';
    const intentUrl = 'saco://';
    window.location.href = intentUrl;
    document.addEventListener('visibilitychange', function onVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        document.removeEventListener('visibilitychange', onVisibilityChange);
      } else {
        setTimeout(() => {
          window.location.href = playStoreUrl;
        }, 2000);
      }
    });
  }
  
  // navigateOnOther() {
    // if (this.platform.ANDROID) {
    //   available('sa.saco.mobile').then(available => {
    //     if (available) {
    //       this.router.navigateByUrl('sa.saco.mobile');
    //     } else {
    //       this.router.navigateByUrl('https://play.google.com/store/apps/details?id=sa.saco.mobile');
    //     }
    //   })
    // } else if (this.platform.IOS) {
    //   available('saco://').then(available => {
    //     if (available) {
    //       this.router.navigateByUrl('saco://');
    //     } else {
    //       this.router.navigateByUrl('https://apps.apple.com/us/app/id1532690353');
    //     }
    //   })
    // } else{
    //   this.router.navigateByUrl('https://appgallery.huawei.com/app/C103556023');
    // }
 // }
  closeNotification() {
    this.localStorageService.setValue('isOpenOnApp', true);
    this.isPopupShow=true;
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }
  isNullOrEmpty(value: any): boolean {
    return value == null || value == undefined || value == "" || value == " ";
  }
  onResize(event: any) {
    event.target.innerWidth;
    if (event.target.innerWidth <= 1024) {
      this.isMobile = true;
    }    
    else {
      this.isMobile = false;
    }
  }

  onPageRefresh() {
    this.isMobile = CommonUtils.isMobile();
  }
}

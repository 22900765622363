import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CategoryService } from 'src/app/core/services/category.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ProductCategoryService } from 'src/app/core/services/product-category.service';
import { ProductDetailsService } from 'src/app/core/services/product-details.service';
import { UnboxdService } from 'src/app/core/services/unbxd.service';
import { environment } from 'src/app/environments/environment';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss']
})
export class CustomCarouselComponent implements OnInit {
  // images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  todayDeals: any = [];
  localUrl = environment.occBaseUrl;
  automotivData: any;
  browsingHistory: any;
  isAPIAlreadyCalled: boolean = false;
  productsGridTitle: string = '';
  ctaLink: string = '';
  ctaName: string = '';
  browsingHistoryUrl: string = '';
  bannerMedia: any;
  isProductGrid: boolean = false;
  isRecommendationGrid: boolean = false;
  isCategoryBlockActive: boolean = false;
  isUserLoggedIn: boolean = false;
  constructor(
    public service: ProductCategoryService,
    public cd: ChangeDetectorRef,
    private cmsComponentData: CmsComponentData<any>,
    public categoryService: CategoryService,
    private localStorageServcie: LocalStorageService,
    private unbxdService: UnboxdService,
    public productDetailsService: ProductDetailsService,
    private cmsService: CmsService) {


  } //Featured1BannerComponent
  ngOnInit(): void {

    this.isUserLoggedIn = this.localStorageServcie.getValue('isLoggedIn');
    this.cmsComponentData.data$.subscribe(data => {
      // Access custom data properties here 
      let componentName = 'SacoProductGridComponent';
      if (data) {
        if (data.name == 'CustomSection1AComponent')
          componentName = 'SacoHomeCategory1WidgetComponent'
        if (data.name == 'CustomSection1BComponent')
          componentName = 'SacoHomeCategory5WidgetComponent'
        if (data.name == 'CustomSection1CComponent')
          componentName = 'SacoHomeCategory6WidgetComponent'
        if (data.name == 'CustomSection1DComponent')
          componentName = 'SacoOfferBanner1GridComponent'
        if (data.name == 'CustomSection2DComponent')
          componentName = 'SacoHomeCategory3WidgetComponent'
        if (data.name == 'CustomSection2CComponent')
          componentName = 'SacoHomeCategory2WidgetComponent'
        // if (data.name == 'CustomSection6BComponent')
        //   componentName = 'SacoHomeCategory4WidgetComponent'
        if (data.name == 'CustomSection2AComponent')
          componentName = 'SacoOfferBanner2GridComponent'
        this.cmsService.getComponentData(componentName).subscribe((data: any) => {
          if (data && !this.isAPIAlreadyCalled) {
            this.isAPIAlreadyCalled = true;
            this.productsGridTitle = data.title;
            this.ctaLink = data.ctaLinkUrl;
            this.ctaName = data.ctaLinkName;
            this.isCategoryBlockActive = (data.isCategoryBlockActive == 'true');
            // if (!this.isUserLoggedIn) {
            //   this.getTilesCards(data.catCode)
            // } 
            // else {
            //   if (componentName == 'SacoHomeCategory2WidgetComponent'
            //     || componentName == 'SacoHomeCategory3WidgetComponent'
            //     || componentName == 'SacoHomeCategory6WidgetComponent') {

            //     this.isProductGrid = false;

            //     if (data.catCode.startsWith('MC')) {
            //       this.getCategoryData(data.catCode, data.uptoDiscount, componentName);
            //     } else {
            //       this.getPRoductsRecommendation(data.catCode, componentName);
            //     }

            //   }
            //   if (componentName == 'SacoHomeCategory5WidgetComponent') {
            //     this.isProductGrid = false;
            //     this.getCategoryData(data.catCode, data.uptoDiscount, componentName, 'newArrival');
            //   }
            if (componentName == 'SacoHomeCategory1WidgetComponent') {
              if (this.isUserLoggedIn) {
                const widgetData = {
                  "analyticsParams": [{
                    "key": "action",
                    "value": "visitor"
                  },
                  {
                    "key": "uid",
                    "value": (this.localStorageServcie.getValue('isLoggedIn')) ? this.unbxdService.getCookieValue('unbxd.userid') : this.unbxdService.getCookieValue('unbxd.userId')
                  },
                  {
                    "key": "pageType",
                    "value": 'Home'
                  }]
                }
                this.productDetailsService.pdpWidgetAPI(widgetData).subscribe((res: any) => {
                  if (res && res.widget1 && res.widget1.recommendations && res.widget1.recommendations.length > 0) {
                    this.isRecommendationGrid = true;
                    this.browsingHistoryUrl = data?.name
                    this.productsGridTitle = res.widget1?.widgetTitle;
                    this.todayDeals = res.widget1.recommendations.slice(0, 4);
                    this.todayDeals.forEach((element: any) => {
                      element.imageUrl = element?.imageUrl[0];
                    });
                    this.cd.markForCheck();
                  }
                });
              } else {
                this.getTilesCards(data.catCode);
              }
            } else {
              this.getTilesCards(data.catCode);
            }

            // if (componentName == 'SacoCategoryGrid2Component' || componentName == 'SacoCategoryGrid1Component' || componentName == 'SacoCategoryGrid3Component') {
            //   this.getCategoryData(data.catCode)
            //   this.isProductGrid = false;
            // } else {
            //   this.isProductGrid = true;
            //   this.getProductsData(data.products);
            // } this.cd.markForCheck();
          }
        })
      }
    });

  }
  getPRoductsRecommendation(catCode: any, componentName: any) {

    this.service.getHomeTilesRecommendation(catCode).subscribe(data => {
      if (data && data.products && data.products.length > 3) {
        // if (componentName == 'SacoHomeCategory2WidgetComponent' || componentName == 'SacoHomeCategory2WidgetComponent') {
        //   const categoryFacet = data?.facets.filter(((category: any) => category.name == "Category"))[0]?.values; // [] .slice(0, 4);
        //   this.todayDeals = categoryFacet?.filter((deal: any) => deal?.code?.includes(catCode)).slice(0, 4)
        //   this.cd.markForCheck();
        // } else {
        this.todayDeals = data.products.slice(0, 4);
        this.cd.markForCheck();
        // }
      } else {
        this.getTilesCards(catCode);
      }
    }, (error) => {

    })
  }
  getCategoryData(categoryCodes: string, uptoDiscount: any, componentName?: string, sort: string = 'newArrival') {
    const codes = categoryCodes?.trim().split(' '); // uptoDiscount
    for (let i = 0; i < codes?.length; i++) {
      const type = uptoDiscount ? (':percentageOff:0TO' + Number(uptoDiscount) + '&sort=' + sort) : sort;
      this.service.getHomeTiles(codes[i], type, false, null, 0, true).subscribe((data: any) => {
        if (data && data.products) {
          // if (componentName == 'SacoHomeCategory2WidgetComponent') {
          //   console.log
          //   const categoryFacet = data?.facets.filter(((category: any) => category.name == "Category"))[0]?.values; // [] .slice(0, 4);
          //   this.todayDeals = categoryFacet?.filter((deal: any) => deal?.code?.includes(codes[i])).slice(0, 4)
          //   this.cd.markForCheck();
          // } else {
          this.todayDeals = data.products.slice(0, 4);
          this.cd.markForCheck();
          // }
        }
      });
    }
  }
  getProductsData(products: any) {
    const productsData: any = products.split(' ');
    for (let i = 0; i < productsData.length && i < 4; i++) {
      this.service.getProductDetailsByCode(productsData[i]).subscribe((data: any) => {
        if (data && data != undefined) {
          this.todayDeals.push(data);
          this.cd.markForCheck();
        }
      })
    }
  }
  getUnbxData() {
    // this.getProductsRecommendation
  }

  getProductImage(data: any) {
    let imageUrl;
    imageUrl = data?.images?.filter((img: any) => img.format == "product");
    if (imageUrl)
      return imageUrl
    imageUrl = data?.images?.filter((img: any) => img.format == "thumbnail");
    return imageUrl;

  }

  getTilesCards(catCode: any) {

    this.service.getCategoryTiles(catCode).subscribe(data => {
      if (data && data.subcategories) {
        this.isRecommendationGrid = true;
        this.todayDeals = data.subcategories.slice(0, 4);
        this.todayDeals.forEach((element: any) => {
          element.imageUrl = this.localUrl + element?.categoryImageUrl;
        });
        this.cd.markForCheck();
      }
    }, (error) => {
      console.log(error);

    })
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  private permissionStatus$: BehaviorSubject<boolean>;
  private locationPermissionStatus: BehaviorSubject<string> = new BehaviorSubject('Checking location access...');
  private permissionStatus: any;
  allowLocationPermission = false;

  constructor() {
    this.permissionStatus$ = new BehaviorSubject<boolean>(false);
    //check if the device is iPhone
    // if(/iPhone/i.test(navigator.userAgent)) {
    //   this.allowLocationPermission = false;
    // }
    // else {
    //   this.allowLocationPermission = true;
    // }
    if(this.allowLocationPermission) {
      this.checkLocationPermission();
    }
    // Listen for changes in permission status
    navigator.permissions.query({ name: 'geolocation' }).then(permission => {
      permission.onchange = async () => {
        this.permissionStatus$.next(await this.hasGeolocationPermission());
      };
    });
  }

   // This method checks the location permission
   private checkLocationPermission() {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        this.permissionStatus = permissionStatus;
        this.updateLocationStatus(permissionStatus.state);

        // Listen for changes to location access
        this.permissionStatus.onchange = () => {
          this.updateLocationStatus(this.permissionStatus.state);
        };
      });
    } else {
      this.locationPermissionStatus.next('Permissions API is not supported in this browser.');
    }
  }

  // Updates the current location permission status
  private updateLocationStatus(state: PermissionState) {
    switch (state) {
      case 'granted':
        this.locationPermissionStatus.next('granted');
        break;
      case 'denied':
        this.locationPermissionStatus.next('denied');
        break;
      case 'prompt':
        this.locationPermissionStatus.next('prompt');
        break;
      default:
        this.locationPermissionStatus.next('unknown');
        break;
    }
  }

  // Public method to get the observable status of location permission
  getLocationPermissionStatus(): Observable<string> {
    return this.locationPermissionStatus.asObservable();
  }

  // Function to ask for location permission
  askForLocationPermission(): Promise<any> {
    return new Promise((resolve, reject) => {
      if ('geolocation' in navigator && this.allowLocationPermission) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // User allowed location access
            // console.log('Current User Location : ', position);
            resolve(position);
          },
          (error) => {
            // User denied location access or an error occurred
            reject(error);
          }
        );
      } else {
        // Geolocation is not supported by the browser
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  public hasGeolocationPermission(): Promise<boolean> {
    return navigator.permissions && navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
      return permissionStatus.state === 'granted';
    });
  }

  public getPermissionStatus(): Observable<boolean> {
    return this.permissionStatus$.asObservable();
  }

}

<div class="container-fluid footer-bg-color">
    <div class="back-to-top" (click)="backtoTop()"><h5 class="mb-0">{{footerLangConfig?.back_to_top}}</h5></div>
    <div class="footer-primary-bg py-3">
        <div class="container">
            <div class="row">
                <div class="col-6 col-lg-2 col-xl-2 my-2 column-dtl">
                    <cx-page-slot position="FooterSection3A" class="footer-top-section"></cx-page-slot>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 my-2 column-dtl">
                    <cx-page-slot position="FooterSection3B" class="footer-top-section"></cx-page-slot>
                </div>
                <ng-container *ngIf="!guestUserLogin">
                    <div class="col-6 col-lg-2 col-xl-2 my-2 column-dtl">
                        <cx-page-slot position="FooterSection3D" class="footer-top-section"></cx-page-slot>
                    </div>
                </ng-container>               
                <div class="col-6 col-lg-2 col-xl-2 my-2 column-dtl">
                    <cx-page-slot position="FooterSection3C" class="footer-top-section"></cx-page-slot>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 my-2 column-dtl delivery-info-footer">
                    <ul>
                        <li>
                            <cx-page-slot position="FooterSection3G" class="footer-delivery-icons align-items-center py-2"></cx-page-slot>
                        </li>
                        <li>
                            <cx-page-slot position="FooterSection3F" class="footer-delivery-icons align-items-center py-2"></cx-page-slot>
                        </li>
                        <li>
                            <cx-page-slot position="FooterSection3E" class="footer-delivery-icons align-items-center py-2"></cx-page-slot>
                        </li>
                    </ul>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 my-2 column-dtl">
                    <cx-page-slot position="FooterSection1" class="footer-top-section"></cx-page-slot>                
                </div>
            </div>            
        </div>
    </div>
    <div class="footer-secondary-bg py-3">
        <div class="container">
            <div class="row mb-2 align-items-center">
                <div class="col-12 col-md-6 col-xl-3 my-2 text-center social-icon">
                    <!-- we can get the cancel and return code when this code moved to cancel and return branch-->
                    <!-- <cx-page-slot position="FooterSection2"></cx-page-slot> -->
                    <ul class="social-icon">
                        <li>
                            <a  href="https://twitter.com/Saco_KSA" target="_blank" class="social-icons twitter-icon"></a>
                        </li>
                        <li>
                            <a  href="https://www.facebook.com/sacoksa" target="_blank" class="social-icons facebook-icon"></a>
                        </li>
                        <li>
                            <a  href="https://www.instagram.com/sacoksa" target="_blank" class="social-icons instagram-icon"></a>
                        </li>
                        <li>
                            <a  href="https://www.youtube.com/channel/UCqRfz8gr6IU9wHKYmQXOYtQ" target="_blank" class="social-icons youtube-icon"></a>
                        </li>
                        <li>
                            <a  href="https://www.snapchat.com/add/saco_saudi" target="_blank" class="social-icons snapchat-icon"></a>
                        </li>
                        <li>
                            <a  href="https://www.tiktok.com/@saco_ksa?_t=8m7qHR7UPsu&_r=1" target="_blank" class="social-icons tiktok-icon"></a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-6 col-xl-4 my-2 text-center">
                    <ng-container *ngIf="footerCopyright">
                        <h6 class="m-0 copyright-txt">{{footerCopyright}}</h6>
                    </ng-container>
                </div>
                <div class="col-12 col-md-12 col-xl-5 my-2 client-logo">
                    <cx-page-slot position="FooterSection4"></cx-page-slot>                        
                </div>
            </div>
        </div>
    </div>
</div>

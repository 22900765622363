import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomMiniCartComponent } from './custom-minicart.component';
import { SharedComponentsModule } from 'src/app/shared/components/shared-components.module';
import { MediaModule, PageSlotModule } from '@spartacus/storefront';
import { NgbDropdownModule, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    CustomMiniCartComponent
  ],
  imports: [
    CommonModule,
    SharedComponentsModule,
    MediaModule,
    NgbDropdownModule,
    NgbDropdown,
    PageSlotModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: CustomMiniCartComponent
        }
      }
    } as CmsConfig)
  ],
  exports:[
    CustomMiniCartComponent
  ]
})
export class CustomMiniCartModule { 
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { CustomSearchComponent } from './custom-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomLanguageSelectionModule } from '../custom-language-selection/custom-language-selection.module';
import { CustomAccountModule } from '../custom-account/custom-account.module';
import { CustomAccountComponent } from '../custom-account/custom-account.component';
import { CustomLanguageSelectionComponent } from '../custom-language-selection/custom-language-selection.component';
import { MediaModule, PageSlotModule } from '@spartacus/storefront';
import { CustomLocationModule } from '../custom-location/custom-location.module';



@NgModule({
  declarations: [
    CustomSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomLanguageSelectionModule,
    CustomLocationModule,
    CustomAccountModule,
    PageSlotModule,
    MediaModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CategoryDropdownComponent: {
          component: CustomSearchComponent
        }
      }
    } as CmsConfig)
  ],
  exports:[
    CustomSearchComponent
  ]
})
export class CustomSearcModule { 
}

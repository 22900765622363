<ngb-toast *ngIf="showToast" [autohide]="hasAutoHide" [delay]="3000">
    <div class="text-center toast-message">
    <ng-container *ngIf="toastMessage; else errorTemplate">
        <img src="../../../../assets/image/Invited.svg" alt="Image" /> {{ toastMessage }}
    </ng-container>
    <ng-template #errorTemplate>
        <img src="../../../../assets/image/error-icon.svg" alt="Image" /> {{ errorMessage }}
    </ng-template>
    <i class="fa fa-close" aria-label="Close" (click)="closeToast()" ></i>
</div>
</ngb-toast>